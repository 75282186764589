import React from 'react';

interface IProps {
  imageUrl: string;
  height?: number | string;
  width?: number | string;
  artistName: string;
  artworkName: string;
  alt: string;
}

const ImageFull: React.FunctionComponent<IProps> = (props: IProps) => {
  const { imageUrl, height, width, artworkName, artistName, alt } = props;
  return (
    <div>
      <div>
        <img src={imageUrl} height={height} width={width} alt={alt} />
      </div>
      {/* <p style={{ marginTop: 10 }}>
        <em>{artworkName}</em>
      </p>
      <p>{artistName}</p> */}
    </div>
  );
};

export { ImageFull };
