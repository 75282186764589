import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { firebaseInstance } from '../firebase-functions';
import { ImageStrip } from './common/ImageStrip';
import { ArtistMasthead } from './common/ArtistMasthead';
import { IArtist } from '../common-src/types/Artist';
import { IImageDataWithMetadata } from '../common-src/types/ImageData';
import { IArtworkWithArtistName } from '../common-src/types/Artwork';
import { Helmet } from 'react-helmet';
import {
  useImageStripStyles,
  useMainStyles,
  useTextStyles,
} from '../hooks/styleHooks';

const Artist: React.FunctionComponent = () => {
  const params: any = useParams();
  const artistId = params.artistId;
  const db = firebaseInstance.firestore();

  const [artist, setArtist] = useState<IArtist | null>(null);
  const [artworks, setArtworks] = useState<IArtworkWithArtistName[] | null>([]);

  const mainStyles = useMainStyles();
  const textStyles = useTextStyles();
  const imageStripStyles = useImageStripStyles();

  useEffect(() => {
    db.collection('artists')
      .doc(artistId)
      .get()
      .then(artistSnap => {
        // @ts-ignore
        setArtist(artistSnap.data());
      });
  }, []);

  useEffect(() => {
    if (artist) {
      db.collection('artworks')
        .where('associatedArtistId', '==', artistId)
        .get()
        .then(snap => {
          let tempArtworks: any[] = [];
          snap.docs.forEach(doc => {
            const artwork = Object.assign(doc.data(), {
              id: doc.id,
              artistFirstName: artist.firstName,
              artistLastName: artist.lastName,
            });
            tempArtworks.push(artwork);
          });
          setArtworks(tempArtworks);
        });
    }
  }, [artist]);

  const randomIndex = Math.floor(Math.random() * artworks?.length!);
  const randomBackgroundImage = artworks![randomIndex];

  const images: IImageDataWithMetadata[] = artworks?.map(
    (artwork: IArtworkWithArtistName) => ({
      imageUrl: artwork.previewUrl,
      previewImageSrc: artwork.previewUrl,
      previewUrlHeight400: artwork.previewUrlHeight400!,
      previewUrlWidth400: artwork.previewUrlWidth400!,
      previewUrl: artwork.previewUrl,
      destinationUrl: `/work/${artwork.id}`,
      alt: artwork.title,
      artistName: `${artwork.artistFirstName} ${artwork.artistLastName}`,
      artworkName: artwork.title,
      title: artwork.title,
      artworkPrice: artwork.price,
    })
  )!;

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Works by ${artist?.firstName} ${artist?.lastName} - The Picture House Gallery`}</title>
      </Helmet>
      {!!randomBackgroundImage && (
        <ArtistMasthead
          backgroundImage={randomBackgroundImage.previewUrl}
          overlayText={`${artist?.firstName} ${artist?.lastName}`}
        />
      )}
      {artist?.bio && (
        <div className={textStyles.galleryCardWrapper}>
          <h3>Bio</h3>
          <p className={textStyles.galleryCard}>{artist.bio}</p>
        </div>
      )}
      <div className={imageStripStyles.imageStripWrapper}>
        <ImageStrip images={images} showArtistName={false} />
      </div>
    </div>
  );
};

export { Artist };
