import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  FormGroup,
  InputGroup,
  NumericInput,
  Button,
  Intent,
} from '@blueprintjs/core';
import { ArtistSelect } from '../../common/ArtistSelect';
import { AlertsContext } from '../../../context/AlertsContext';
import { IArtist } from '../../../common-src/types/Artist';
import {
  IArtwork,
  IArtworkWithArtistName,
} from '../../../common-src/types/Artwork';
import { ArtworkSelect } from '../../common/ArtworkSelect';
import {
  findArtworkById,
  useArtworksByArtist,
} from '../../../hooks/artworkHooks';
import { ArtworkPreview } from '../../common/ArtworkPreview';
import { mediums } from '../../../common-src/constants/constants';
import {
  printfulVariants,
  infoToVariantIdMap,
} from '../../../common-src/constants/printful';
import { PRINTIFY_PRODUCTS } from '../../../common-src/constants/printify';
import { updateArtwork } from '../../../client/firestoreClient';
import {
  getSyncProduct,
  createSyncProduct,
  updateSyncProduct,
  getVariantInformation,
} from '../../../client/printfulClient';
import {
  ICreateSyncProductRequest,
  IPrintfulProduct,
  IUpdateSyncProductRequest,
} from '../../../common-src/types/Printful';
import { IImageDataWithMetadata } from '../../../common-src/types/ImageData';
import { useAdminStyles, useMainStyles } from '../../../hooks/styleHooks';
import { PrintfulIntegration } from './PrintfulIntegration';
import { PrintifyIntegration } from './PrintifyIntegration';
import {
  createProduct,
  getBlueprint,
  getProduct,
  getVariantsForBlueprintAndPrintProvider,
  updateProduct,
} from '../../../client/printifyClient';
import {
  IBlueprint,
  IBlueprintWithCheckedState,
  ICreateProductResponse,
  IPrintifyProduct,
  IPrintifyVariant,
  IUpdateProductRequest,
  IUpdateProductRequestInternal,
} from '../../../common-src/types/Printify';
import { config } from '../../../config';
import { useParams, useHistory } from 'react-router-dom';

/**
 * This is a pretty complex component (should be broken down and simplified!). Here are some things to note when testing.
 * Default (empty) state
 * 1. User selects artwork
 * 2. We retrieve the artwork details from firestore
 */

const EditArtwork: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [selectedArtist, setSelectedArtist] = useState<IArtist | null>(null);
  const [selectedArtwork, setSelectedArtwork] =
    useState<IArtworkWithArtistName | null>(null);
  const mainStyles = useMainStyles();

  const [artworkTitle, setArtworkTitle] = useState('');
  const [artworkDescription, setArtworkDescription] = useState('');
  const [artworkPrice, setArtworkPrice] = useState<number | undefined>(
    undefined
  );
  const [artworkPrintPrice, setArtworkPrintPrice] = useState<
    number | undefined
  >(undefined);
  const [artworkMedium, setArtworkMedium] = useState('');

  const artworksAssociatedWithSelectedArtist =
    useArtworksByArtist(selectedArtist);

  const [imageData, setImageData] = useState<IImageDataWithMetadata | null>(
    null
  );

  const [isSavingArtwork, setIsSavingArtwork] = useState(false);

  const params: any = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params?.id) {
      fetchArtworkById(params.id);
    }
  }, [params]);

  // printful variables
  const [syncProduct, setSyncProduct] = useState<IPrintfulProduct | null>(null);
  const [syncProductError, setSyncProductError] = useState<string | null>(null);
  const [isSavingToPrintful, setIsSavingToPrintful] = useState(false);
  const [profitMargin, setProfitMargin] = useState(15);
  const [variantIdToBasePriceMap, setVariantIdToBasePriceMap] = useState<Map<
    number,
    number
  > | null>(null);

  // printify variables
  const [existsInPrintify, setExistsInPrintify] = useState(false);
  const [isSavingToPrintify, setIsSavingToPrintify] = useState(false);
  const [genericPrintifyBlueprints, setGenericPrintifyBlueprints] = useState<
    IBlueprintWithCheckedState[]
  >([]);
  const [isFetchingPrintifyProducts, setIsFetchingPrintifyProducts] =
    useState(false);
  const [printifyProducts, setPrintifyProducts] = useState<IPrintifyProduct[]>(
    []
  );

  useEffect(() => {
    if (selectedArtwork) {
      setArtworkTitle(selectedArtwork?.title || '');
      setArtworkDescription(selectedArtwork?.description || '');
      setArtworkPrice(selectedArtwork?.price || 0);
      setArtworkPrintPrice(selectedArtwork?.printPrice || 0);
      setArtworkMedium(selectedArtwork?.medium || '');
      // printful
      fetchSyncProduct(selectedArtwork?.id!);
      // printify

      if (
        config.featureFlags.usePrintify &&
        selectedArtwork?.printify?.productIds
      ) {
        setExistsInPrintify(true);
        fetchPrintifyProducts(selectedArtwork?.printify?.productIds);
      }
    }
  }, [selectedArtwork]);

  useEffect(() => {
    if (selectedArtwork) {
      const imageData: IImageDataWithMetadata = {
        previewUrl: selectedArtwork.previewUrl,
        previewUrlHeight400: selectedArtwork.previewUrlHeight400!,
        previewUrlWidth400: selectedArtwork.previewUrlWidth400!,
        destinationUrl: `/work/${selectedArtwork.id}`,
        title: selectedArtwork.title,
        artistName: `${selectedArtwork.artistFirstName} ${selectedArtwork.artistLastName}`,
      };
      setImageData(imageData);
    }
  }, [selectedArtwork]);

  useEffect(() => {
    fetchPrintfulVariantPrices();
    const promisesToResolve: Promise<any>[] = [];
    PRINTIFY_PRODUCTS.forEach(async prod => {
      const blueprintPromise = fetchBlueprint(prod.blueprintId);
      promisesToResolve.push(blueprintPromise);
      // fetchVariantsForProduct(
      //   blerg?.blueprintId!,
      //   blerg?.associatedPrintProviderId!
      // );
    });
    Promise.all(promisesToResolve).then((blueprints: IBlueprint[]) => {
      const genericPrintifyBlueprintsUpdated = blueprints.map(blueprint =>
        Object.assign({}, blueprint, { checked: false })
      );
      setGenericPrintifyBlueprints(genericPrintifyBlueprintsUpdated);
    });
  }, []);

  const fetchArtworkById = async (artworkId: string) => {
    const artwork = await findArtworkById(artworkId);
    const artworkWithId = Object.assign({}, artwork, { id: artworkId });
    setSelectedArtwork(artworkWithId as IArtworkWithArtistName);
  };

  // printful

  const fetchSyncProduct = async (artworkId: string) => {
    try {
      const res = await getSyncProduct(`@${artworkId}`);

      setSyncProduct(null);

      if (res.code === 404) {
        setSyncProductError('This product could not be found in printful');
      } else if (res.code >= 400) {
        setSyncProductError(res.error.message);
      } else {
        const updatedSyncProduct = res.result;
        setSyncProduct(updatedSyncProduct);
      }
    } catch (err) {
      setSyncProductError('This product could not be found in printful');
    }
  };

  const fetchPrintfulVariantPrices = async () => {
    let priceMap = new Map();
    try {
      // cycle thru all canvases in printful, retrieve their prices, then populate variables
      infoToVariantIdMap.forEach(async value => {
        const priceRes = await getVariantInformation(value);
        priceMap.set(value, +priceRes.result.variant.price);
      });
      setVariantIdToBasePriceMap(priceMap);
    } catch (err) {
      alertsContext.addAlert(
        'Unable to get printful price information',
        Intent.DANGER
      );
    }
  };

  const handleVariantPriceChange = (
    price: any,
    _string: any,
    target: HTMLInputElement
  ) => {
    const variantId = target.dataset['id'];
    const updatedVariants = syncProduct?.sync_variants?.map(variant => {
      if (variant.id?.toString() === variantId?.toString()) {
        const updatedVariant = _.cloneDeep(variant);
        updatedVariant!.retail_price = price.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        });
        return updatedVariant;
      } else {
        return variant;
      }
    });

    const updatedSyncProduct = _.cloneDeep(syncProduct);
    updatedSyncProduct!.sync_variants = updatedVariants;
    setSyncProduct(updatedSyncProduct);
  };

  // printify calls

  const fetchBlueprint = async (blueprintId: number) => {
    return await getBlueprint(blueprintId);
  };

  const fetchVariantsForProduct = async (
    blueprintId: number,
    printProviderId: number
  ) => {
    const variants = await getVariantsForBlueprintAndPrintProvider(
      blueprintId,
      printProviderId
    );
  };

  const fetchPrintifyProducts = async (artworkPrintifyIds: string[]) => {
    setIsFetchingPrintifyProducts(true);
    const promisesToResolve: Promise<ICreateProductResponse>[] = [];
    artworkPrintifyIds.forEach(printifyId => {
      promisesToResolve.push(getProduct(printifyId));
    });
    Promise.all(promisesToResolve)
      .then(responses => {
        if (responses) {
          const updatedPrintifyProducts: IPrintifyProduct[] = responses.map(
            response => ({
              id: response.id,
              title: response.title,
              description: response.description,
              images: response.images,
              variants: response.variants,
              blueprint_id: response.blueprint_id,
              print_areas: response.print_areas,
              print_provider_id: response.print_provider_id,
            })
          );
          setPrintifyProducts(updatedPrintifyProducts);
        }
      })
      .catch(err => {
        alertsContext.addAlert(
          'Unable to find this artwork in printify',
          Intent.DANGER
        );
      })
      .finally(() => {
        setIsFetchingPrintifyProducts(false);
      });
  };

  const handleCheckGenericPrintifyBlueprint = (event: any) => {
    const blueprintId = +event?.currentTarget?.dataset['id'];
    const genericPrintifyBlueprintsUpdated = genericPrintifyBlueprints.map(
      blueprint => {
        if (blueprint.id === blueprintId) {
          return {
            ...blueprint,
            checked: !blueprint.checked,
          };
        } else {
          return blueprint;
        }
      }
    );
    setGenericPrintifyBlueprints(genericPrintifyBlueprintsUpdated);
  };

  const addBlueprintsToPrintify = async (
    blueprintsToAdd: IBlueprintWithCheckedState[]
  ): Promise<boolean> => {
    setIsSavingToPrintify(true);
    try {
      await createProduct({
        blueprintIds: blueprintsToAdd.map(blueprint => blueprint.id),
        artwork: selectedArtwork!,
        imageUrl: selectedArtwork?.fullSizeUrl!,
        imageFilename: `${selectedArtwork?.id}`,
      });
      return true;
      // TODO: reload relevant parts of the page on successful response
    } catch (err) {
      return false;
    } finally {
      setIsSavingToPrintify(false);
    }
  };

  const handleAddToPrintify = async () => {
    const blueprintsToAdd = genericPrintifyBlueprints.filter(
      blueprint => blueprint.checked
    );
    if (blueprintsToAdd.length) {
      const response = await addBlueprintsToPrintify(blueprintsToAdd);
      if (response) {
        alertsContext.addAlert(
          'Successfully added artwork to Printify',
          Intent.SUCCESS
        );
      } else {
        alertsContext.addAlert(
          'Unable to add artwork to Printify',
          Intent.DANGER
        );
      }
    } else {
      return null;
    }
  };

  const handleUpdatePrintify = async () => {
    const updatePromises: Promise<any>[] = [];
    // update printify products that have already been integrated
    printifyProducts.forEach(product => {
      const updateProductRequest: IUpdateProductRequestInternal = {
        artworkId: selectedArtwork?.id || '',
        title: product.title,
        description: product.description,
        blueprint_id: product.blueprint_id,
        print_provider_id: product.print_provider_id,
        variants: product.variants,
        print_areas: product.print_areas,
      };
      updatePromises.push(updateProduct(product.id, updateProductRequest));
    });

    // add new printify products if any blueprints were selected
    const blueprintsToAdd = genericPrintifyBlueprints.filter(
      blueprint => blueprint.checked
    );

    if (blueprintsToAdd.length) {
      updatePromises.push(addBlueprintsToPrintify(blueprintsToAdd));
    }

    Promise.allSettled(updatePromises)
      .then(() => {
        alertsContext.addAlert(
          'Successfully updated artwork in Printify',
          Intent.SUCCESS
        );
      })
      .catch(err =>
        alertsContext.addAlert(
          'Unable to update artwork in Printify',
          Intent.DANGER
        )
      );
  };

  const handleClickPrintifyVariant = (event: any) => {
    const { variantId, productId } = event.currentTarget.dataset;
    const updatedPrintifyProducts: IPrintifyProduct[] = printifyProducts.map(
      existingProduct => {
        if (existingProduct.id === productId) {
          const updatedVariants: IPrintifyVariant[] =
            existingProduct.variants.map(variant =>
              variant.id === +variantId
                ? Object.assign({}, variant, {
                    is_enabled: !variant.is_enabled,
                  })
                : variant
            );
          const updatedProduct: IPrintifyProduct = Object.assign(
            {},
            existingProduct,
            { variants: updatedVariants }
          );
          return updatedProduct;
        } else {
          return existingProduct;
        }
      }
    );
    setPrintifyProducts(updatedPrintifyProducts);
  };

  const handleChangeVariantRetailPrice = (event: any) => {
    const { variantId, productId } = event.currentTarget.dataset;
    const newPrice = +event.currentTarget.value * 100;

    const updatedPrintifyProducts: IPrintifyProduct[] = printifyProducts.map(
      existingProduct => {
        if (existingProduct.id === productId) {
          const updatedVariants: IPrintifyVariant[] =
            existingProduct.variants.map(variant =>
              variant.id === +variantId
                ? Object.assign({}, variant, {
                    price: newPrice,
                  })
                : variant
            );
          const updatedProduct: IPrintifyProduct = Object.assign(
            {},
            existingProduct,
            { variants: updatedVariants }
          );
          return updatedProduct;
        } else {
          return existingProduct;
        }
      }
    );
    setPrintifyProducts(updatedPrintifyProducts);
  };

  // other

  const handleSelectedArtistChange = (artist: IArtist) => {
    setSelectedArtist(artist);
    setSelectedArtwork(null);
  };

  const handleSelectedArtworkIdChange = async (
    artwork: IArtworkWithArtistName | IArtwork
  ) => {
    history.push(`/admin/edit-artwork/${artwork.id}`);
  };

  const handleTitleChange = (event: any) => {
    setArtworkTitle(event.target.value);
  };

  const handleDescriptionChange = (event: any) => {
    setArtworkDescription(event.target.value);
  };

  const handlePriceChange = (event: any) => {
    setArtworkPrice(event);
  };

  const handleMediumChange = (event: any) => {
    setArtworkMedium(event.target.value);
  };

  const handleProfitMarginChange = (profitMargin: number) => {
    setProfitMargin(profitMargin);
  };

  const handleApplyProfitMarginToAll = () => {
    const updatedVariants = syncProduct?.sync_variants?.map(variant => {
      const updatedVariant = _.cloneDeep(variant);
      updatedVariant!.retail_price = +(
        variantIdToBasePriceMap!.get(variant.variant_id)! + profitMargin
      ).toFixed(2);
      return updatedVariant;
    });

    const updatedSyncProduct = _.cloneDeep(syncProduct);
    updatedSyncProduct!.sync_variants = updatedVariants;
    setSyncProduct(updatedSyncProduct);
  };

  const handleAddToPrintful = async () => {
    setIsSavingToPrintful(true);
    const createSyncProductRequest: ICreateSyncProductRequest = {
      sync_product: {
        external_id: selectedArtwork?.id,
        name: selectedArtwork?.title!,
      },
      sync_variants: [
        {
          variant_id: printfulVariants.canvas12x12.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
        {
          variant_id: printfulVariants.canvas12x16.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
        {
          variant_id: printfulVariants.canvas16x16.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
        {
          variant_id: printfulVariants.canvas16x20.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
        {
          variant_id: printfulVariants.canvas18x24.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
        {
          variant_id: printfulVariants.canvas24x36.id,
          files: [{ url: selectedArtwork?.fullSizeUrl }],
        },
      ],
    };
    try {
      const newSyncProduct = await createSyncProduct(createSyncProductRequest);
      setSyncProduct(newSyncProduct);
      await fetchSyncProduct(selectedArtwork?.id!);
      alertsContext.addAlert(
        'Successfully added artwork to printful',
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext.addAlert('Unable to add to printful', Intent.DANGER);
    } finally {
      setIsSavingToPrintful(false);
    }
  };

  const handleUpdatePrintful = async () => {
    setIsSavingToPrintful(true);
    const updateSyncProductRequest: IUpdateSyncProductRequest = {
      sync_product: {
        external_id: selectedArtwork?.id,
        name: selectedArtwork?.title!,
        thumbnail: syncProduct?.thumbnail,
      },
      sync_variants: syncProduct!.sync_variants!.map(variant => ({
        id: variant.id,
        retail_price: variant.retail_price,
      })),
    };
    try {
      await updateSyncProduct(updateSyncProductRequest);
      await fetchSyncProduct(selectedArtwork?.id!);
      alertsContext.addAlert(
        'Successfully updated in printful',
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext.addAlert(
        'Unable to save changes in printful',
        Intent.DANGER
      );
    } finally {
      setIsSavingToPrintful(false);
    }
  };

  const handleSaveChangesToArtwork = async () => {
    if (!artworkTitle || !artworkMedium) {
      alertsContext.addAlert(
        `The artwork needs to have at least a title and a medium.`,
        Intent.DANGER
      );
      return;
    }

    setIsSavingArtwork(true);
    try {
      const res = await updateArtwork(
        selectedArtwork?.id!,
        artworkTitle,
        artworkMedium,
        artworkDescription,
        artworkPrice,
        artworkPrintPrice
      );
      if (res) {
        alertsContext.addAlert(
          `You've successfully saved changes for ${selectedArtwork?.title}`,
          Intent.SUCCESS
        );
      }
    } catch (err) {
      alertsContext.addAlert(
        'Unable to save changes to artwork',
        Intent.DANGER
      );
    } finally {
      setIsSavingArtwork(false);
    }
  };

  const adminClasses = useAdminStyles();

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Edit artwork</h2>
      <div className={adminClasses.adminFormSection}>
        <div>
          <FormGroup
            label="Artist"
            labelFor="artist-name"
            labelInfo="(required)"
          >
            <ArtistSelect handleValueChange={handleSelectedArtistChange} />
          </FormGroup>
        </div>
        {selectedArtist && (
          <div className="admin-form-box">
            <FormGroup label="Artwork to edit" labelFor="artwork-to-edit">
              <ArtworkSelect
                artworks={artworksAssociatedWithSelectedArtist}
                handleValueChange={handleSelectedArtworkIdChange}
              />
            </FormGroup>
          </div>
        )}
      </div>
      {selectedArtwork && imageData && (
        <div>
          <div
            className={adminClasses.adminFormSection}
            style={{ backgroundColor: 'lightgrey', borderRadius: 5 }}
          >
            <div style={{ paddingTop: 10 }}>
              <h3>Basic information</h3>
            </div>
            <div className="admin-form-box">
              <ArtworkPreview
                imageData={imageData!}
                distributor={'PICTURE_HOUSE'}
                inImageStrip={false}
                height={250}
                alt={artworkTitle}
                shouldShowCaption
                framed
              />
            </div>
            <div className="admin-form-box">
              <FormGroup
                label="The artwork's title"
                labelFor="artwork-name"
                labelInfo="(required)"
              >
                <InputGroup
                  id="artwork-name"
                  value={artworkTitle}
                  onChange={handleTitleChange}
                />
              </FormGroup>
            </div>
            <div className="admin-form-box">
              <FormGroup
                label="A description of the artwork"
                labelFor="artwork-description"
              >
                <InputGroup
                  id="artwork-description"
                  value={artworkDescription}
                  onChange={handleDescriptionChange}
                />
              </FormGroup>
            </div>
            <div className="admin-form-box">
              <FormGroup label="The artwork's price" labelFor="artwork-price">
                <NumericInput
                  id="artwork-price"
                  value={artworkPrice}
                  onValueChange={handlePriceChange}
                  buttonPosition="none"
                  fill
                />
              </FormGroup>
            </div>
            {/* <div className="admin-form-box">
                <FormGroup
                  label="The artwork's price per print"
                  labelFor="artwork-print-price"
                >
                  <NumericInput
                    id="artwork-print-price"
                    value={artworkPrintPrice}
                    onValueChange={handlePrintPriceChange}
                    buttonPosition="none"
                    fill
                  />
                </FormGroup>
              </div> */}
            <div className="admin-form-box">
              <FormGroup
                label="Medium"
                labelFor="artwork-medium"
                labelInfo="(required)"
              >
                <select value={artworkMedium} onChange={handleMediumChange}>
                  {mediums.map(medium => (
                    <option value={medium} key={medium}>
                      {medium}
                    </option>
                  ))}
                </select>
              </FormGroup>
            </div>

            <div className="admin-form-box">
              <Button
                intent={Intent.PRIMARY}
                onClick={handleSaveChangesToArtwork}
                loading={isSavingArtwork}
              >
                Save changes
              </Button>
            </div>
            {/* <div className="admin-form-box">
            <FormGroup label="Delete artwork" labelFor="artwork-delete">
              Delete artwork...
            </FormGroup>
          </div> */}
          </div>
          <PrintfulIntegration
            syncProductError={syncProductError}
            profitMargin={profitMargin}
            handleProfitMarginChange={handleProfitMarginChange}
            syncProduct={syncProduct}
            handleApplyProfitMarginToAll={handleApplyProfitMarginToAll}
            variantIdToBasePriceMap={variantIdToBasePriceMap}
            handleVariantPriceChange={handleVariantPriceChange}
            handleAddToPrintful={handleAddToPrintful}
            handleUpdatePrintful={handleUpdatePrintful}
            isSavingToPrintful={isSavingToPrintful}
          />
          {config.featureFlags.usePrintify && (
            <PrintifyIntegration
              existsInPrintify={existsInPrintify}
              handleAddToPrintify={handleAddToPrintify}
              handleUpdatePrintify={handleUpdatePrintify}
              isSavingToPrintify={isSavingToPrintify}
              genericPrintifyBlueprints={genericPrintifyBlueprints}
              handleCheckGenericPrintifyBlueprint={
                handleCheckGenericPrintifyBlueprint
              }
              handleClickPrintifyVariant={handleClickPrintifyVariant}
              isFetchingPrintifyProducts={isFetchingPrintifyProducts}
              printifyProducts={printifyProducts}
              handleChangeVariantRetailPrice={handleChangeVariantRetailPrice}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { EditArtwork };
