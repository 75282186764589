import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { firebaseInstance } from '../../firebase-functions';
import { ImageFull } from '../common/ImageFull';
import { Intent } from '@blueprintjs/core';
import { IArtwork } from '../../common-src/types/Artwork';
import { AlertsContext } from '../../context/AlertsContext';
import { PurchaseContext } from '../../context/PurchaseContext';
import { getSyncProduct } from '../../client/printfulClient';
import { IPrintfulProduct } from '../../common-src/types/Printful';
import _ from 'lodash';
import { PRINTIFY_PRODUCTS } from '../../common-src/constants/printify';
import { variantIdToInfoMap } from '../../common-src/constants/printful';
import { IVariantType } from '../../common-src/types/UtilTypes';
import { ArtworkDescription } from './ArtworkDescription';
import { IArtist } from '../../common-src/types/Artist';
import { IImageDataWithMetadata } from '../../common-src/types/ImageData';
import { ImageStrip } from '../common/ImageStrip';
import { Helmet } from 'react-helmet';
import {
  ICreateProductResponse,
  IPrintifyProduct,
} from '../../common-src/types/Printify';
import { getProduct } from '../../client/printifyClient';
import { config } from '../../config';
import { PurchaseSidebar } from './PurchaseSidebar';
import {
  useArtworkInfoStyles,
  useGenericGridStyles,
  useMainStyles,
  usePurchaseSidebarStyles,
} from '../../hooks/styleHooks';
import {
  IArtworkProduct,
  IArtworkProductVariant,
} from '../../common-src/types/ArtworkProduct';
import { ICartItem } from '../../common-src/types/CartItem';
import { IDistributor } from '../../common-src/constants/constants';

const Artwork: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const purchaseContext = useContext(PurchaseContext);
  const [artworkId, setArtworkId] = useState<string>('');
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();
  const artworkInfoStyles = useArtworkInfoStyles();
  const purchaseSidebarStyles = usePurchaseSidebarStyles();

  const params: any = useParams();
  const db = firebaseInstance.firestore();

  const [artwork, setArtwork] = useState<IArtwork | null>(null);
  const [associatedArtist, setAssociatedArtist] = useState<IArtist | null>(
    null
  );
  const [otherArtworks, setOtherArtworks] = useState<IArtwork[] | null>(null);

  const [printfulSyncProduct, setPrintfulSyncProduct] =
    useState<IPrintfulProduct | null>(null);
  const [printfulSyncProductError, setPrintfulSyncProductError] = useState<
    string | null
  >(null);

  const [isFetchingPrintifyProducts, setIsFetchingPrintifyProducts] =
    useState(false);
  const [printifyProducts, setPrintifyProducts] = useState<IPrintifyProduct[]>(
    []
  );
  const [existsInPrintify, setExistsInPrintify] = useState(false);

  const [selectedArtworkProduct, setSelectedArtworkProduct] =
    useState<IArtworkProduct | null>(null);
  const [selectedVariantOfArtworkProduct, setSelectedVariantOfArtworkProduct] =
    useState<IArtworkProductVariant | null>(null);
  const [allArtworkProducts, setAllArtworkProducts] =
    useState<IArtworkProduct[]>();
  const [allVariantsOfArtworkProduct, setAllVariantsOfArtworkProduct] =
    useState<IArtworkProductVariant[]>();

  const { addToCart } = purchaseContext;

  useEffect(() => {
    if (artworkId) {
      db.collection('artworks')
        .doc(artworkId)
        .get()
        .then(snap => {
          const work = Object.assign(snap.data(), { id: artworkId });
          setArtwork(work as IArtwork);
        });
    }
  }, [artworkId]);

  useEffect(() => {
    if (params) {
      setArtworkId(params.artworkId);
    }
  }, [params]);

  useEffect(() => {
    if (artwork) {
      if (config.featureFlags.usePrintify && artwork?.printify?.productIds) {
        setExistsInPrintify(true);
        fetchPrintifyProducts(artwork?.printify?.productIds);
      }
      db.collection('artists')
        .doc(artwork.associatedArtistId)
        .get()
        .then(snap => {
          const artist = Object.assign(snap.data(), { id: snap.id });
          // @ts-ignore
          setAssociatedArtist(artist);
        });
      fetchPrintfulSyncProduct(artwork.id);
    }
  }, [artwork]);

  useEffect(() => {
    if (associatedArtist) {
      db.collection('artworks')
        .where('associatedArtistId', '==', associatedArtist.id)
        .get()
        .then(snap => {
          let tempArtworks: any[] = [];
          snap.docs.forEach(doc => {
            const artwork = Object.assign(doc.data(), {
              id: doc.id,
              artistFirstName: associatedArtist.firstName,
              artistLastName: associatedArtist.lastName,
            });
            tempArtworks.push(artwork);
          });
          setOtherArtworks(tempArtworks);
        });
    }
  }, [associatedArtist]);

  useEffect(() => {
    const variants: IArtworkProduct[] = [];
    if (printfulSyncProduct) {
      const variantToAdd: IArtworkProduct = {
        artworkId: artwork?.id!,
        distributorProductId:
          printfulSyncProduct?.sync_variants![0]!.product.product_id!,
        printDistributor: 'PRINTFUL',
        printfulProduct: printfulSyncProduct,
      };
      variants.push(variantToAdd);
    }
    if (printifyProducts && printifyProducts.length > 0) {
      const variantsToAdd: IArtworkProduct[] = printifyProducts.map(product => {
        return {
          artworkId: artwork?.id!,
          distributorProductId: product.blueprint_id,
          printDistributor: 'PRINTIFY',
          printifyProduct: product,
        };
      });
      variants.push(...variantsToAdd);
    }
    setAllArtworkProducts(variants);
  }, [printfulSyncProduct, printifyProducts]);

  useEffect(() => {
    const variants: IArtworkProductVariant[] = [];
    if (selectedArtworkProduct) {
      if (selectedArtworkProduct.printDistributor === 'PRINTFUL') {
        const printfulVariants: IArtworkProductVariant[] =
          selectedArtworkProduct.printfulProduct?.sync_variants?.map(
            printfulSyncVariant => {
              return {
                printDistributor: selectedArtworkProduct.printDistributor!,
                printfulVariant: printfulSyncVariant!,
              }!;
            }
          )!;
        variants.push(...printfulVariants);
      } else if (selectedArtworkProduct.printDistributor === 'PRINTIFY') {
        const printifyVariants: IArtworkProductVariant[] =
          selectedArtworkProduct.printifyProduct?.variants.map(
            printifyVariant => {
              return {
                printDistributor: selectedArtworkProduct.printDistributor!,
                printifyVariant: printifyVariant!,
              }!;
            }
          )!;
        variants.push(...printifyVariants);
      }
      setAllVariantsOfArtworkProduct(variants);
      setSelectedVariantOfArtworkProduct(variants[0]);
    }
  }, [selectedArtworkProduct]);

  useEffect(() => {
    if (allVariantsOfArtworkProduct) {
      // ?
    }
  }, [allVariantsOfArtworkProduct]);

  const fetchPrintifyProducts = async (artworkPrintifyIds: string[]) => {
    setIsFetchingPrintifyProducts(true);
    const promisesToResolve: Promise<ICreateProductResponse>[] = [];
    artworkPrintifyIds.forEach(printifyId => {
      promisesToResolve.push(getProduct(printifyId));
    });
    Promise.all(promisesToResolve)
      .then(responses => {
        if (responses) {
          const updatedPrintifyProducts: IPrintifyProduct[] = responses.map(
            response => ({
              id: response.id,
              title: response.title,
              description: response.description,
              images: response.images,
              variants: response.variants,
              blueprint_id: response.blueprint_id,
              print_areas: response.print_areas,
              print_provider_id: response.print_provider_id,
              userFriendlyName: Array.from(PRINTIFY_PRODUCTS.values()).find(
                product => product.blueprintId === response.blueprint_id
              )?.userFriendlyName,
            })
          );
          setPrintifyProducts(updatedPrintifyProducts);
        }
      })
      .catch(err => {
        alertsContext.addAlert(
          'Unable to find this artwork in printify',
          Intent.DANGER
        );
      })
      .finally(() => {
        setIsFetchingPrintifyProducts(false);
      });
  };

  const fetchPrintfulSyncProduct = async (artworkId: string) => {
    try {
      const res = await getSyncProduct(`@${artworkId}`);

      setPrintfulSyncProduct(null);

      if (res.code === 404) {
        setPrintfulSyncProductError(
          'This product could not be found in printful'
        );
      } else if (res.code >= 400) {
        setPrintfulSyncProductError(res.error.message);
      } else {
        setPrintfulSyncProduct(res.result);
      }
    } catch (err) {
      // alertsContext.addAlert('Unable to get artwork', Intent.DANGER);
    }
  };

  const handleSelectArtworkProduct = (artworkProduct: IArtworkProduct) => {
    setSelectedArtworkProduct(artworkProduct);
  };

  const handleSelectArtworkProductVariant = (
    artworkProductVariant: IArtworkProductVariant
  ) => {
    setSelectedVariantOfArtworkProduct(artworkProductVariant);
  };

  // const handleAddOriginalToCart = () => {
  //   const variantType: IVariantType = 'original';
  //   try {
  //     addToCart(
  //       Object.assign(artwork!, {
  //         variantType,
  //         distributor: 'PICTURE_HOUSE' as IDistributor,
  //         purchasePrice: artwork?.price!,
  //         quantity: 1,
  //         imageUrl: artwork?.fullSizeUrl!,
  //       })
  //     );
  //     alertsContext.addAlert(
  //       `Added ${artwork?.title} to your cart.`,
  //       Intent.SUCCESS
  //     );
  //   } catch (err) {
  //     alertsContext.addAlert(
  //       `Could not add ${artwork?.title} to your cart. Please try again later.`,
  //       Intent.DANGER
  //     );
  //   }
  // };

  const handleAddArtworkProductVariantToCart = () => {
    if (selectedVariantOfArtworkProduct) {
      let newCartItem: ICartItem;
      if (selectedVariantOfArtworkProduct.printDistributor === 'PRINTFUL') {
        const productId =
          selectedVariantOfArtworkProduct.printfulVariant?.product.product_id!; // TODO: check this
        const variantId =
          selectedVariantOfArtworkProduct.printfulVariant?.variant_id!; // TODO: check this
        const variantPrice =
          selectedVariantOfArtworkProduct.printfulVariant?.retail_price!; // TODO: check this
        const imageUrl = getPrintImageUrl();

        newCartItem = {
          distributor: selectedVariantOfArtworkProduct.printDistributor,
          quantity: 1,
          purchasePrice: variantPrice,
          externalIdentifiers: {
            productId,
            variantId,
          },
          imageUrl: imageUrl ?? '',
          artwork: artwork!,
        };
      } else if (
        selectedVariantOfArtworkProduct.printDistributor === 'PRINTIFY'
      ) {
        const productId =
          selectedArtworkProduct?.printifyProduct?.blueprint_id!; // TODO: check
        const variantId = selectedVariantOfArtworkProduct.printifyVariant?.id!; // TODO: check
        const variantPrice =
          selectedVariantOfArtworkProduct.printifyVariant?.price!; // TODO: check
        const imageUrl = getPrintImageUrl();
        newCartItem = {
          distributor: selectedVariantOfArtworkProduct.printDistributor,
          quantity: 1,
          purchasePrice: variantPrice,
          externalIdentifiers: {
            productId,
            variantId,
          },
          imageUrl: imageUrl ?? '',
          artwork: artwork!,
        };
      } else if (
        selectedVariantOfArtworkProduct.printDistributor === 'PICTURE_HOUSE'
      ) {
        newCartItem = {
          distributor: 'PICTURE_HOUSE' as IDistributor,
          purchasePrice: artwork?.price!,
          quantity: 1,
          imageUrl: artwork?.fullSizeUrl!,
          artwork: artwork!,
        };
      }
      try {
        addToCart(newCartItem!);
        alertsContext.addAlert(
          `Added a print of ${artwork?.title} to your cart.`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext.addAlert(
          `Could not add ${artwork?.title} to your cart. Please try again later.`,
          Intent.DANGER
        );
      }
    } else {
      // TODO: throw an error. This means no print has been selected.
    }
  };

  // const handleAddPrintfulVariantToCart = (event: any) => {
  //   const variantId = +event?.currentTarget?.dataset['variantid'];
  //   const variantPrice = +event?.currentTarget?.dataset['price'];
  //   const printfulUrl =
  //     printfulSyncProduct?.sync_variants?.find(
  //       variant => variant.variant_id === variantId
  //     )?.files[1].preview_url ?? artwork?.fullSizeUrl;
  //   const printType = variantIdToInfoMap.get(+variantId) as IVariantType;
  //   if (!printType) {
  //     alertsContext.addAlert(
  //       `Could not add ${artwork?.title} to your cart. Please try again later.`,
  //       Intent.DANGER
  //     );
  //   }
  //   try {
  //     addToCart(
  //       Object.assign(_.cloneDeep(artwork)!, {
  //         variantType: printType,
  //         purchasePrice: variantPrice,
  //         quantity: 1,
  //         printfulUrl,
  //       })
  //     );
  //     alertsContext.addAlert(
  //       `Added a print of ${artwork?.title} to your cart.`,
  //       Intent.SUCCESS
  //     );
  //   } catch (err) {
  //     alertsContext.addAlert(
  //       `Could not add ${artwork?.title} to your cart. Please try again later.`,
  //       Intent.DANGER
  //     );
  //   }
  // };

  const otherArtworksImages: IImageDataWithMetadata[] =
    otherArtworks
      ?.map((artwork: IArtwork) => ({
        id: artwork.id,
        imageUrl: artwork.previewUrl,
        previewImageSrc: artwork.previewUrl,
        previewUrlHeight400: artwork.previewUrlHeight400!,
        previewUrlWidth400: artwork.previewUrlWidth400!,
        previewUrl: artwork.previewUrl,
        destinationUrl: `/work/${artwork.id}`,
        alt: artwork.title,
        artistName: `${associatedArtist?.firstName} ${associatedArtist?.lastName}`,
        artworkName: artwork.title,
        title: artwork.title,
        artworkPrice: artwork.price,
      }))
      .filter(artwork => artwork.id !== artworkId) ?? [];

  const getPrintImageUrl = (): string | null => {
    if (selectedVariantOfArtworkProduct) {
      if (selectedVariantOfArtworkProduct.printDistributor === 'PRINTFUL') {
        return selectedVariantOfArtworkProduct.printfulVariant?.files[1]
          .preview_url!;
      } else if (
        selectedVariantOfArtworkProduct.printDistributor === 'PRINTIFY'
      ) {
        const variantId = selectedVariantOfArtworkProduct.printifyVariant?.id!;
        const matchingImage =
          selectedArtworkProduct?.printifyProduct?.images.find(
            image => image.is_default && image.variant_ids.includes(variantId)
          );
        return matchingImage?.src!;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${artwork?.title} by ${associatedArtist?.firstName} ${associatedArtist?.lastName} - The Picture House Gallery`}</title>
      </Helmet>
      <div className={artworkInfoStyles.artworkTitleWrapper}>
        <h1
          className={`${artworkInfoStyles.artworkTitle} ${mainStyles.regularTextStyle}`}
        >
          {artwork?.title}{' '}
          {!!associatedArtist?.firstName && !!associatedArtist?.lastName && (
            <span className={artworkInfoStyles.artworkTitleArtistName}>
              by {associatedArtist?.firstName} {associatedArtist?.lastName}
            </span>
          )}
        </h1>
      </div>
      <div className={genericGridStyles.flexGridLarge}>
        <div className={genericGridStyles.flexGridCol2}>
          {selectedArtworkProduct ? (
            <ImageFull
              imageUrl={getPrintImageUrl()!}
              width={600}
              artistName={`${associatedArtist?.firstName} ${associatedArtist?.lastName}`}
              artworkName={artwork?.title!}
              alt={artwork?.title!}
            />
          ) : (
            <ImageFull
              imageUrl={artwork?.fullSizeUrl!}
              width={600}
              artistName={`${associatedArtist?.firstName} ${associatedArtist?.lastName}`}
              artworkName={artwork?.title!}
              alt={artwork?.title!}
            />
          )}

          {!!artwork?.description && (
            <ArtworkDescription description={artwork?.description} />
          )}
          {!!otherArtworksImages && otherArtworksImages.length > 0 && (
            <div className={artworkInfoStyles.artworkMore}>
              <h3 style={{ paddingLeft: 150, paddingBottom: 20 }}>
                More by this artist
              </h3>
              <div style={{ maxWidth: 1000 }}>
                <ImageStrip
                  images={otherArtworksImages}
                  showArtistName={false}
                />
              </div>
            </div>
          )}
        </div>
        <div className={`${genericGridStyles.flexGridCol1}`}>
          {artwork && (
            <PurchaseSidebar
              artwork={artwork}
              syncProduct={printfulSyncProduct}
              existsInPrintify={existsInPrintify}
              printifyProducts={printifyProducts}
              // handleAddPrintfulVariantToCart={handleAddPrintfulVariantToCart}
              handleSelectArtworkProduct={handleSelectArtworkProduct}
              selectedArtworkProduct={selectedArtworkProduct!}
              allArtworkProducts={allArtworkProducts!}
              allVariantsForArtworkProduct={allVariantsOfArtworkProduct}
              handleSelectArtworkProductVariant={
                handleSelectArtworkProductVariant
              }
              selectedArtworkProductVariant={selectedVariantOfArtworkProduct!}
              handleAddArtworkProductVariantToCart={
                handleAddArtworkProductVariantToCart
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { Artwork };
