import React from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useImagePreviewStyles } from '../../hooks/styleHooks';

interface IProps {
  imageSrc: string;
  destinationUrl?: string;
  height: number;
  width?: number;
  containerHeight?: number;
  containerWidth?: number;
  artworkName: string;
  artistName: string;
  shouldShowCaption?: boolean;
  overlayText?: string;
  overlayImage?: string;
  alt: string;
  framed?: boolean;
}

/**
 * Creates a carousel image.
 * @param props
 */
const CarouselImage: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    imageSrc,
    destinationUrl,
    height,
    width,
    containerHeight,
    containerWidth,
    shouldShowCaption,
    overlayText,
    overlayImage,
    artworkName,
    artistName,
    alt,
    framed,
  } = props;

  const imagePreviewStyles = useImagePreviewStyles();

  const className = framed ? imagePreviewStyles.imagePreviewFrame : '';
  const theImageItself = (
    <div
      className={className}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        height: containerHeight,
        backgroundImage: `url(${imageSrc})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {overlayText && (
        <div
          className={`${imagePreviewStyles.imagePreviewOverlay} ${imagePreviewStyles.imagePreviewOverlayText}`}
        >
          {overlayText}
        </div>
      )}
      {overlayImage && (
        <div className={imagePreviewStyles.imagePreviewOverlayText}>
          <img src={overlayImage} height={600} width={800} />
        </div>
      )}
    </div>
  );

  return (
    <div style={{ display: 'block', height: '100%' }}>
      {destinationUrl ? (
        <Link to={destinationUrl}>{theImageItself}</Link>
      ) : (
        <>{theImageItself}</>
      )}
      {shouldShowCaption && (
        <>
          <p style={{ marginTop: 10 }}>
            <em>{artworkName}</em>
          </p>
          <p>{artistName}</p>
        </>
      )}
    </div>
  );
};

export { CarouselImage };
