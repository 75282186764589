import { IPrintfulProduct } from '../types/Printful';

export const shippingTypes = {
  standard: 'STANDARD',
};

export const printfulProducts = {
  canvas: 3,
};

export const PRINTFUL_PRODUCTS = new Map<String, IPrintfulProduct>([
  [
    'CANVAS',
    {
      name: 'Canvas',
      id: 3,
      sync_variants: [
        // TODO: fix below
        { variant_id: 823, files: [], product: { product_id: 1 } },
        { variant_id: 5, files: [], product: { product_id: 1 } },
        { variant_id: 824, files: [], product: { product_id: 1 } },
        { variant_id: 6, files: [], product: { product_id: 1 } },
        { variant_id: 7, files: [], product: { product_id: 1 } },
        { variant_id: 825, files: [], product: { product_id: 1 } },
      ],
    },
  ],
]);

export const printfulVariants = {
  canvas12x12: {
    id: 823,
  },
  canvas12x16: {
    id: 5,
  },
  canvas16x16: {
    id: 824,
  },
  canvas16x20: {
    id: 6,
  },
  canvas18x24: {
    id: 7,
  },
  canvas24x36: {
    id: 825,
  },
};

export const variantIdToInfoMap = new Map<number, string>([
  [823, 'print12x12'],
  [5, 'print12x16'],
  [824, 'print16x16'],
  [6, 'print16x20'],
  [7, 'print18x24'],
  [825, 'print24x36'],
]);

export const infoToVariantIdMap = new Map<string, number>([
  ['print12x12', 823],
  ['print12x16', 5],
  ['print16x16', 824],
  ['print16x20', 6],
  ['print18x24', 7],
  ['print24x36', 825],
]);
