import { config } from '../config';

/**
 * Creates a resized image with either a fixed width or fixed height, depending on the arguments passed.
 * @param fullSizeImage
 * @param fullSizeImageFile
 * @param controlDimension
 * @param controlDimensionPixels
 */
export const createResizedImage = async (
  fullSizeImage: HTMLImageElement,
  fullSizeImageFile: File,
  controlDimension: 'height' | 'width',
  controlDimensionPixels: number
): Promise<File> => {
  let width: number;
  let height: number;
  let scaleFactor: number;

  if (controlDimension === 'height') {
    height = controlDimensionPixels;
    scaleFactor = height / fullSizeImage.height;
    width = fullSizeImage.width * scaleFactor;
  } else {
    width = controlDimensionPixels;
    scaleFactor = width / fullSizeImage.width;
    height = fullSizeImage.height * scaleFactor;
  }

  const canvas = document.createElement('canvas');

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx?.drawImage(fullSizeImage, 0, 0, width, height);

  try {
    return await convertToFile(ctx, fullSizeImageFile);
  } catch (err) {
    throw new Error('Unable to resize images');
  }
};

/**
 * Creates a file from the passed context.
 * @param ctx
 * @param fullSizeImageFile
 */
const convertToFile = async (
  ctx: any,
  fullSizeImageFile: File
): Promise<File> => {
  return new Promise((resolve, reject) => {
    ctx?.canvas.toBlob(
      (blob: Blob) => {
        const resizedImageFile = new File([blob!], fullSizeImageFile.name, {
          type: fullSizeImageFile.type,
          lastModified: Date.now(),
        });
        resolve(resizedImageFile);
      },
      fullSizeImageFile.type,
      1
    );
  });
};

interface IImageDataToUpload {
  largeImage?: File;
  fixedHeightImage?: File;
  fixedWidthImage?: File;
}

/**
 * Note: this function is only to be called after the user has supplied ALL the relevant information, i.e. when actually
 * uploading to backend.
 * @param fullSizeImageFile
 * @param artistId
 * @param artworkTitle
 * @param artworkPrice
 * @param artworkPrintPrice
 * @param artworkDescription
 * @param artworkMedium
 */
export const uploadImageToBackEnd = async (
  fullSizeImageFile: File,
  artistId: string,
  artworkTitle: string,
  artworkPrice: string,
  artworkPrintPrice: string,
  artworkDescription: string,
  artworkMedium: string
): Promise<any> => {
  const imgDataPromise = new Promise((resolve, reject) => {
    // preview the image
    let reader = new FileReader();
    reader.readAsDataURL(fullSizeImageFile);

    reader.onloadend = () => {
      const fullSizeImage: HTMLImageElement = new Image();
      // @ts-ignore
      fullSizeImage.src = reader.result!;

      fullSizeImage.onload = async () => {
        try {
          // create separate resized images, one with a height of 400 pixels, the other with a width of 400 pixels
          const fixedHeightFile = await createResizedImage(
            fullSizeImage,
            fullSizeImageFile,
            'height',
            400
          );

          const fixedWidthFile = await createResizedImage(
            fullSizeImage,
            fullSizeImageFile,
            'width',
            400
          );

          const formData = new FormData();

          formData?.append('largeImage', fullSizeImageFile);
          formData?.append('fixedHeightImage', fixedHeightFile);
          formData?.append('fixedWidthImage', fixedWidthFile);
          formData?.append('artistId', artistId);
          formData?.append('title', artworkTitle);
          formData?.append('price', artworkPrice || '');
          formData?.append('printPrice', artworkPrintPrice || '');
          formData?.append('description', artworkDescription);
          formData?.append('medium', artworkMedium);

          fetch(`${config.apiUrl}uploadImage`, {
            method: 'POST',
            body: formData,
          })
            .then(res => {
              return res.text();
            })
            .then(response => {
              resolve(response);
            });
        } catch (err) {
          throw new Error('There was an error uploading this artwork');
        }
      };
    };
  });

  return imgDataPromise;
};

/**
 * Uploads an image to the featured images collection
 * @param fullSizeImageFile
 * @param filename
 */
export const uploadFeaturedImage = async (
  fullSizeImageFile: File,
  filename: string
): Promise<any> => {
  const imgDataPromise = new Promise((resolve, reject) => {
    // preview the image
    let reader = new FileReader();
    reader.readAsDataURL(fullSizeImageFile);

    reader.onloadend = () => {
      const fullSizeImage: HTMLImageElement = new Image();
      // @ts-ignore
      fullSizeImage.src = reader.result!;

      fullSizeImage.onload = async () => {
        try {
          // create separate resized images, one with a height of 400 pixels, the other with a width of 400 pixels
          const fixedHeightFile = await createResizedImage(
            fullSizeImage,
            fullSizeImageFile,
            'height',
            400
          );

          const fixedWidthFile = await createResizedImage(
            fullSizeImage,
            fullSizeImageFile,
            'width',
            400
          );

          const formData = new FormData();

          formData?.append('largeImage', fullSizeImageFile);
          formData?.append('fixedHeightImage', fixedHeightFile);
          formData?.append('fixedWidthImage', fixedWidthFile);

          fetch(`${config.apiUrl}uploadFeaturedImage`, {
            method: 'POST',
            body: formData,
          })
            .then(res => {
              return res.text();
            })
            .then(response => {
              resolve(response);
            });
        } catch (err) {
          throw new Error('There was an error uploading this featured image');
        }
      };
    };
  });

  return imgDataPromise;
};

export const preloadImages = (images: string[]) => {};
