import React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps {
  to: string;
  text: string;
}

const AdminNavBarItem: React.FunctionComponent<IProps> = (props: IProps) => {
  const { to, text } = props;
  return (
    <div
      style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 5,
        paddingRight: 5
      }}
    >
      <NavLink
        to={to}
        // onClick={this.props.isMobile ? this.context.toggleMobileMenu : () => {}}
        className="bp3-menu-item bp3-popover-dismiss"
        activeClassName="admin-navbar-item-active"
        exact={true}
      >
        {text}{' '}
      </NavLink>
    </div>
  );
};

export default AdminNavBarItem;
