import { ITheme } from '../common-src/types/Theme';

export const defaultTheme: ITheme = {
  accentColour: 'crimson',
  adminNavbarBackground: 'rgb(200, 207, 207)',
  adminNavbarItemActiveBackground: 'rgb(68, 163, 163)',
  adminNavbarItemHoverBackground: 'rgb(0, 1, 36)',
  admin: {},
  menuItemHoverBackground: 'rgb(176, 58, 46)',
  artistNameText: 'rgb(156, 142, 138)',
  text: {
    lightTextColour: 'rgb(128, 126, 126)',
  },
  main: {
    backgroundColour: 'rgb(243, 243, 243)',
  },
  navbar: {
    backgroundColour: '#16001E',
  },
  logo: {
    colour: '#F1DAC4',
  },
  imagePreviewOverlay: {
    backgroundColour: 'rgba(255, 255, 255, 0.8)',
    colour: 'black',
  },
  purchaseSidebar: {
    background:
      'linear-gradient(180deg, rgba(235, 235, 235, 1) 0%, rgba(243, 243, 243, 1) 100%)',
    artworkPriceColour: '#80CED7',
  },
  description: {
    backgroundColour: 'white',
    boxShadow: '0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  },
  artistLink: {
    colour: 'darkslategrey',
  },
  forms: {
    warningColour: '#c23030',
  },
  tabs: {
    tabBackgroundColour: '#CCDBDC',
    activeTabBackgroundColour: '#F3F6F7',
  },
};
