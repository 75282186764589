import React, { useState } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { firebaseInstance } from '../firebase-functions';
import { Helmet } from 'react-helmet';
import { logGAEvent } from '../client/gaClient';
import { GA_EVENT_LOGIN, GA_PARAM_LOGIN_TYPE_EMAIL } from '../ga';
import { useMainStyles } from '../hooks/styleHooks';

const SignIn: React.FunctionComponent = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [authenticationError, setAuthenticationError] = useState<any | null>(
    null
  );
  const mainStyles = useMainStyles();

  const firebaseSignIn = (email: string, password: string) => {
    logGAEvent(GA_EVENT_LOGIN, { method: GA_PARAM_LOGIN_TYPE_EMAIL });
    firebaseInstance
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(res => {
        window.location.href = '/';
      })
      .catch(error => {
        setAuthenticationError(error);
      });
  };

  const handleInputChange = (event: any) => {
    let target = event.target;

    if (target.name === 'email') {
      setEmail(target.value);
    } else if (target.name === 'password') {
      setPassword(target.value);
    }
  };

  const handleSignIn = (event: any) => {
    event.preventDefault();
    if (email && password) {
      firebaseSignIn(email, password);
    } else {
      window.alert('You need to fill in all fields');
    }
  };

  return (
    <div className={mainStyles.genericPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign in to the Picture House Gallery</title>
      </Helmet>
      <div>
        {authenticationError && (
          <div className="bp3-callout bp3-intent-danger .modifier">
            <h4 className="bp3-heading">Sign-in error</h4>
            <>{authenticationError.message}</>
          </div>
        )}
        <div>
          <p>Enter your details below.</p>
        </div>

        <div>
          <label className="bp3-label">
            Email:
            <input
              className="bp3-input bp3-round bp3-fill"
              type="text"
              onChange={handleInputChange}
              name="email"
            />
          </label>
          <label className="bp3-label">
            Password:
            <input
              className="bp3-input bp3-round bp3-fill"
              type="password"
              name="password"
              onChange={handleInputChange}
            />
          </label>
          <Button onClick={handleSignIn} intent={Intent.PRIMARY} large={true}>
            Sign in
          </Button>
        </div>
      </div>
    </div>
  );
};

export { SignIn };
