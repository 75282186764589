import React, { useContext } from 'react';
import { Slideshow } from './common/Slideshow';
import {
  useFeaturedArtworks,
  useFilteredArtworks,
} from '../hooks/artworkHooks';
import { ArtworkPreview } from './common/ArtworkPreview';
import { IArtworkWithArtistName } from '../common-src/types/Artwork';
import { FilterBox } from './common/FilterBox';
import { FilterContext } from '../context/FilterContext';
import { ArtworkGrid } from './common/ArtworkGrid';
import { Spinner } from '@blueprintjs/core';
import { WindowContext } from '../context/WindowContext';
import { IImageDataWithMetadata } from '../common-src/types/ImageData';
import { Helmet } from 'react-helmet';
import { useGenericGridStyles, useMainStyles } from '../hooks/styleHooks';

const Works: React.FunctionComponent = () => {
  const filterContext = useContext(FilterContext);
  const windowContext = useContext(WindowContext);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  // const { featuredArtworks } = useFeaturedArtworks();
  const { filteredArtworksWithArtistName } = useFilteredArtworks({
    mediums: filterContext.mediums,
    priceRange: filterContext.priceRange,
  });

  const artworksAsElements = filteredArtworksWithArtistName?.map(
    (artwork: IArtworkWithArtistName, index) => {
      const imageData: IImageDataWithMetadata = {
        previewUrl: artwork.previewUrl,
        previewUrlHeight400: artwork.previewUrlHeight400 ?? artwork.previewUrl,
        previewUrlWidth400: artwork.previewUrlWidth400 ?? artwork.previewUrl,
        destinationUrl: `/work/${artwork.id}`,
        title: artwork.title,
        artistName: `${artwork.artistFirstName} ${artwork.artistLastName}`,
      };
      return (
        <ArtworkPreview
          imageData={imageData}
          price={artwork.price}
          distributor={'PICTURE_HOUSE'}
          inImageStrip={false}
          artworkTitle={artwork.title}
          showArtistName={true}
          destinationUrl={`/work/${artwork.id}`}
          artistName={`${artwork.artistFirstName} ${artwork.artistLastName}`}
          alt={artwork.title}
          shouldShowCaption
          framed
          width="100%"
          key={index}
        />
      );
    }
  );

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Work by our artists - The Picture House Gallery</title>
      </Helmet>
      <h1 className={mainStyles.pageHeading}>Works</h1>
      {/* {featuredArtworks && (
        <Slideshow
          // @ts-ignore
          images={featuredArtworks}
        />
      )} */}
      <div
        style={{ paddingTop: 30, width: '100%' }}
        className={genericGridStyles.flexGrid}
      >
        {artworksAsElements ? (
          <>
            <div className={genericGridStyles.flexGridCol1}>
              <FilterBox shouldShowMediumFilter />
            </div>
            <div className={genericGridStyles.flexGridCol3}>
              <ArtworkGrid
                elements={artworksAsElements}
                breakpointCols={windowContext?.isSmall ? 2 : 3}
              />
            </div>
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export { Works };
