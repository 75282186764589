import { Button, Callout, Intent, Spinner } from '@blueprintjs/core';
import React from 'react';
import {
  IBlueprintWithCheckedState,
  IPrintifyProduct,
} from '../../../common-src/types/Printify';
import { useAdminStyles } from '../../../hooks/styleHooks';
import { PrintifyProductList } from './PrintifyProductList';

interface IProps {
  existsInPrintify: boolean;
  handleAddToPrintify: () => void;
  handleUpdatePrintify: () => void;
  isSavingToPrintify: boolean;
  genericPrintifyBlueprints: IBlueprintWithCheckedState[];
  handleCheckGenericPrintifyBlueprint: (event: any) => void;
  handleClickPrintifyVariant: (event: any) => void;
  handleChangeVariantRetailPrice: (event: any) => void;
  isFetchingPrintifyProducts: boolean;
  printifyProducts: IPrintifyProduct[];
}

const PrintifyIntegration: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    existsInPrintify,
    handleAddToPrintify,
    handleUpdatePrintify,
    isSavingToPrintify,
    genericPrintifyBlueprints,
    handleCheckGenericPrintifyBlueprint,
    handleClickPrintifyVariant,
    handleChangeVariantRetailPrice,
    isFetchingPrintifyProducts,
    printifyProducts,
  } = props;
  const adminClasses = useAdminStyles();

  return (
    <div
      className={adminClasses.adminSection}
      style={{ backgroundColor: 'lightblue', borderRadius: 5 }}
    >
      <h3 className="heading-3">Printify integration</h3>
      {isFetchingPrintifyProducts && <Spinner />}
      {existsInPrintify ? (
        <div>
          <PrintifyProductList
            isIntegrated={true}
            genericPrintifyBlueprints={genericPrintifyBlueprints}
            handleCheckGenericPrintifyBlueprint={
              handleCheckGenericPrintifyBlueprint
            }
            integratedProducts={printifyProducts}
            handleClickPrintifyVariant={handleClickPrintifyVariant}
            handleChangeVariantRetailPrice={handleChangeVariantRetailPrice}
          />
          <Button
            intent={Intent.PRIMARY}
            onClick={handleUpdatePrintify}
            loading={isSavingToPrintify}
          >
            Update printify
          </Button>
        </div>
      ) : (
        <div>
          <div>
            <Callout title="No printify data" intent={Intent.WARNING}>
              This artwork is not yet in printify. Select the print products
              you'd like to use for this artwork, then click "add to printify"
              below.
            </Callout>
          </div>
          <PrintifyProductList
            isIntegrated={false}
            genericPrintifyBlueprints={genericPrintifyBlueprints}
            handleCheckGenericPrintifyBlueprint={
              handleCheckGenericPrintifyBlueprint
            }
            handleClickPrintifyVariant={handleClickPrintifyVariant}
            handleChangeVariantRetailPrice={handleChangeVariantRetailPrice}
          />
          <Button
            intent={Intent.PRIMARY}
            onClick={handleAddToPrintify}
            loading={isSavingToPrintify}
          >
            Add to printify
          </Button>
        </div>
      )}
    </div>
  );
};

export { PrintifyIntegration };
