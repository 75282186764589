const dev = {
  env: 'dev',
  siteUrl: 'https://artist-collective.firebaseapp.com',
  siteTitle: 'The Picture House [DEV]',
  firebase: {
    apiKey: 'AIzaSyAdytTSkSUzNs3TixUabWKh2nWS9HlOmcc',
    authDomain: 'artist-collective.firebaseapp.com',
    databaseURL: 'https://artist-collective.firebaseio.com',
    projectId: 'artist-collective',
    storageBucket: 'artist-collective.appspot.com',
    messagingSenderId: '1021200715346',
    appId: '1:1021200715346:web:4a24776bbcbe8d0375198b',
    measurementId: 'G-MNEX68DM6X',
  },
  apiUrl: 'http://localhost:5001/artist-collective/us-central1/',
  stripe: {
    publishableKey: 'pk_test_4Me0pcEEYHCL1dBqjWqfY3Sd00HGKZlOo7',
  },
  featureFlags: {
    usePrintify: true,
  },
};

const test = {
  env: 'test',
  siteUrl: 'https://artist-collective.firebaseapp.com',
  siteTitle: 'The Picture House [TEST]',
  firebase: {
    apiKey: 'AIzaSyAdytTSkSUzNs3TixUabWKh2nWS9HlOmcc',
    authDomain: 'artist-collective.firebaseapp.com',
    databaseURL: 'https://artist-collective.firebaseio.com',
    projectId: 'artist-collective',
    storageBucket: 'artist-collective.appspot.com',
    messagingSenderId: '1021200715346',
    appId: '1:1021200715346:web:4a24776bbcbe8d0375198b',
    measurementId: 'G-MNEX68DM6X',
  },
  apiUrl: 'https://us-central1-artist-collective.cloudfunctions.net/',
  stripe: {
    publishableKey: 'pk_test_4Me0pcEEYHCL1dBqjWqfY3Sd00HGKZlOo7',
  },
  featureFlags: {
    usePrintify: true,
  },
};

const prod = {
  env: 'prod',
  siteUrl: 'https://thepicturehousegallery.com',
  siteTitle: 'The Picture House',
  firebase: {
    apiKey: 'AIzaSyADE3zbh4dx_qOYJubj5ues5QsU6LkvF9I',
    authDomain: 'the-picture-house.firebaseapp.com',
    databaseURL: 'https://the-picture-house.firebaseio.com',
    projectId: 'the-picture-house',
    storageBucket: 'the-picture-house.appspot.com',
    messagingSenderId: '13703707839',
    appId: '1:13703707839:web:e2a2b2c0275baf79619a0a',
    measurementId: 'G-S1Q73W8LSY',
  },
  apiUrl: 'https://us-central1-the-picture-house.cloudfunctions.net/',
  stripe: {
    publishableKey: 'pk_live_ls2zmCtN1rwU2QbT1iXUGF5400zQzIgQ9v',
  },
  featureFlags: {
    usePrintify: false,
  },
};

let config: any;

if (process.env.REACT_APP_STAGE === 'prod') {
  config = prod;
} else if (process.env.REACT_APP_STAGE === 'test') {
  config = test;
} else {
  config = dev;
}

export { config };
