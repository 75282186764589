import React from 'react';
import { ArtistLink } from './common/ArtistLink';
import { useArtists } from '../hooks/artistHooks';
import { Spinner } from '@blueprintjs/core';
import { Helmet } from 'react-helmet';
import { useArtworkGridStyles, useMainStyles } from '../hooks/styleHooks';

const Artists: React.FunctionComponent = () => {
  const { artists, artistsWithImages } = useArtists();
  const mainStyles = useMainStyles();
  const artworkGridStyles = useArtworkGridStyles();

  // TODO_LATER: update the below logic so that it doesn't require representative images for each artist. If there is no image,
  // we can show a generic picture instead.

  const artistsAsElements = artistsWithImages?.map(
    (artiste: any, index: number) => (
      <div key={index} style={{ marginRight: 10, marginTop: 10 }}>
        <ArtistLink
          artist={artiste}
          representativeImageSrc={artiste.previewImageSrc}
        />
      </div>
    )
  );

  if (!artistsWithImages || artistsWithImages.length === 0) {
    return (
      <div
        style={{
          height: 700,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Our Artists - The Picture House Gallery</title>
      </Helmet>
      <h1 className={mainStyles.pageHeading}>Artists</h1>
      {/* <Slideshow /> */}
      <div className={artworkGridStyles.artworkGrid}>{artistsAsElements}</div>
    </div>
  );
};

export { Artists };
