import React, { useContext, useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Formik } from 'formik';
import {
  Button,
  Intent,
  InputGroup,
  FormGroup,
  HTMLSelect,
} from '@blueprintjs/core';
import { AlertsContext } from '../../context/AlertsContext';
import { PurchaseContext } from '../../context/PurchaseContext';
import { countryAndStateCodes } from '../../common-src/constants/constants';
import { IPurchaseInfoItem } from '../../common-src/types/PurchaseInfo';
import { createPaymentIntent } from '../../client/paymentClient';
import { Helmet } from 'react-helmet';
import {
  useCartStyles,
  useFormStyles,
  useMainStyles,
} from '../../hooks/styleHooks';
const logger = require('pino')();

interface IProps {
  isVisible: boolean;
}

const BillingAndShipping: React.FunctionComponent<IProps> = (props: IProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const alertContext = useContext(AlertsContext);
  const purchaseContext = useContext(PurchaseContext);
  const [isCardEntered, setIsCardEntered] = useState(false);
  const [shouldShowCardError, setShouldShowCardError] = useState(false);
  const mainStyles = useMainStyles();
  const cartStyles = useCartStyles();
  const formStyles = useFormStyles();

  const {
    setClientSecret,
    setStage,
    setBillingAndShippingInfo,
    cartItems,
    setTransactionId,
  } = purchaseContext;

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  useEffect(() => {
    if (elements && elements.getElement(CardElement)) {
      elements.getElement(CardElement)!.on('change', result => {
        if (result.complete) {
          setIsCardEntered(true);
        } else {
          setIsCardEntered(false);
        }
      });
    }
  }, [elements]);

  // TODO: test this!!!
  const handleFormSubmit = async (values: any, setSubmitting: any) => {
    if (!stripe || !elements) {
      return;
    }

    setBillingAndShippingInfo({
      paymentMethod: {
        card: elements.getElement(CardElement)!,
        billingDetails: {
          name: values.billingName,
        },
      },
      receiptEmail: values.billingEmail,
      shipping: {
        address: {
          line1: values.addressLine1,
          line2: values.addressLine2,
          country: values.country,
          city: values.city,
          state: values.state,
          postalCode: values.postcode,
        },
        name: values.shippingName,
      },
    });

    const purchaseInfoData: IPurchaseInfoItem[] = cartItems.map(cartItem => {
      return {
        artworkId: cartItem.artwork.id,
        quantity: cartItem.quantity,
        url: cartItem.artwork.fullSizeUrl,
        distributor: cartItem.distributor,
        externalIdentifiers: cartItem.externalIdentifiers!,
      };
    });

    try {
      const res = (await createPaymentIntent(
        purchaseInfoData,
        values.billingName,
        {
          address1: values.addressLine1,
          address2: values.addressLine2,
          country_code: values.country,
          city: values.city,
          state_code: values.state,
          zip: values.postcode,
          recipientFirstName: values.recipientFirstName,
          recipientLastName: values.recipientLastName,
          email: values.billingEmail,
        }
      )) as { paymentIntent: any; transactionId: string };
      if (!res) {
        alertContext.addAlert(
          'There was an unexpected error. Please try again later.',
          Intent.DANGER
        );
      } else {
        setClientSecret(res.paymentIntent.client_secret);
        setTransactionId(res.transactionId);
        setStage(1);
        window.scrollTo(0, 0);
      }
    } catch (err) {
      alertContext.addAlert(
        'There was an unexpected error. Please try again later.',
        Intent.DANGER
      );
    }

    setSubmitting(false);
    setStage(2);
  };

  if (!stripe || !elements) {
    return null;
  }

  // use these to initialise formik below when testing
  const testValues = {
    billingName: 'big spender',
    billingEmail: 'davidmichael4d@gmail.com',
    shippingName: 'bob',
    recipientFirstName: 'mr',
    recipientLastName: 'bob',
    addressLine1: '85 Riverside Gardens',
    addressLine2: '',
    city: 'London',
    state: 'London',
    country: 'GB',
    postcode: 'W6 9LF',
  };

  const realInitialValues = {
    billingName: '',
    billingEmail: '',
    shippingName: '',
    recipientFirstName: '',
    recipientLastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: 'GB',
    postcode: '',
  };

  return (
    <div
      style={{ display: props.isVisible ? 'block' : 'none' }}
      className={mainStyles.regularTextStyle}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Billing and shipping - The Picture House Gallery</title>
      </Helmet>
      <div>
        <Formik
          initialValues={realInitialValues}
          validate={values => {
            const errors: any = {};

            setShouldShowCardError(true);

            // TODO_LATER: see if there's a more "formik-ey" way of doing the below if statement (i.e. using values)
            if (!isCardEntered) {
              errors.isCardEntered = 'Credit card is required';
            }
            if (!values.billingName) {
              errors.billingName = 'Billing name is required';
            }
            if (!values.billingEmail) {
              errors.billingEmail = 'Email is required';
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                values.billingEmail
              )
            ) {
              errors.billingEmail = 'Email is invalid';
            }
            if (!values.shippingName) {
              errors.shippingName = 'Recipient name is required';
            }
            if (!values.addressLine1) {
              errors.addressLine1 = 'Address line 1 is required';
            }
            if (!values.city) {
              errors.city = 'City is required';
            }
            if (!values.state) {
              errors.state = 'State is required';
            }
            if (!values.country) {
              errors.country = 'Country is required';
            }
            if (!values.postcode) {
              errors.postcode = 'Postcode is required';
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            submitForm,
          }) => (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <div style={{ padding: '20px 0 20px 0', width: 500 }}>
                  <h3 className={cartStyles.cartHeading}>Payment details</h3>
                  <div style={{ padding: '20px 0 20px 0' }}>
                    <label style={{ width: '100%' }}>
                      <div style={{ textAlign: 'left', paddingBottom: 10 }}>
                        <span>Card details</span>
                      </div>
                      <CardElement
                        options={CARD_ELEMENT_OPTIONS}
                        onBlur={() => setShouldShowCardError(true)}
                      />
                      {shouldShowCardError && !isCardEntered && (
                        <div className={formStyles.warningText}>
                          Credit card is required
                        </div>
                      )}
                    </label>
                    <FormGroup
                      label="Billing name"
                      labelInfo="(required)"
                      helperText={
                        errors.billingName &&
                        touched.billingName &&
                        errors.billingName
                      }
                      intent={
                        errors.billingName && touched.billingName
                          ? Intent.DANGER
                          : undefined
                      }
                    >
                      <InputGroup
                        name="billingName"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.billingName}
                        intent={
                          errors.billingName && touched.billingName
                            ? Intent.DANGER
                            : undefined
                        }
                      />
                    </FormGroup>
                    <FormGroup
                      label="Billing email"
                      labelInfo="(required)"
                      helperText={
                        errors.billingEmail &&
                        touched.billingEmail &&
                        errors.billingEmail
                      }
                      intent={
                        errors.billingEmail && touched.billingEmail
                          ? Intent.DANGER
                          : undefined
                      }
                    >
                      <InputGroup
                        name="billingEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.billingEmail}
                        intent={
                          errors.billingEmail && touched.billingEmail
                            ? Intent.DANGER
                            : undefined
                        }
                      />
                    </FormGroup>
                  </div>
                  <h3 className={cartStyles.cartHeading}>Shipping details</h3>
                  <FormGroup
                    label="Recipient name"
                    labelInfo="(required)"
                    helperText={
                      errors.shippingName &&
                      touched.shippingName &&
                      errors.shippingName
                    }
                    intent={
                      errors.shippingName && touched.shippingName
                        ? Intent.DANGER
                        : undefined
                    }
                  >
                    <InputGroup
                      name="shippingName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shippingName}
                      intent={
                        errors.shippingName && touched.shippingName
                          ? Intent.DANGER
                          : undefined
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    label="Address line 1"
                    labelInfo="(required)"
                    helperText={
                      errors.addressLine1 &&
                      touched.addressLine1 &&
                      errors.addressLine1
                    }
                    intent={
                      errors.addressLine1 && touched.addressLine1
                        ? Intent.DANGER
                        : undefined
                    }
                  >
                    <InputGroup
                      name="addressLine1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addressLine1}
                      intent={
                        errors.addressLine1 && touched.addressLine1
                          ? Intent.DANGER
                          : undefined
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    label="Address line 2"
                    helperText={
                      errors.addressLine2 &&
                      touched.addressLine2 &&
                      errors.addressLine2
                    }
                    intent={
                      errors.addressLine2 && touched.addressLine2
                        ? Intent.DANGER
                        : undefined
                    }
                  >
                    <InputGroup
                      name="addressLine2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.addressLine2}
                      intent={
                        errors.addressLine2 && touched.addressLine2
                          ? Intent.DANGER
                          : undefined
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    label="City"
                    labelInfo="(required)"
                    helperText={errors.city && touched.city && errors.city}
                    intent={
                      errors.city && touched.city ? Intent.DANGER : undefined
                    }
                  >
                    <InputGroup
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      intent={
                        errors.city && touched.city ? Intent.DANGER : undefined
                      }
                    />
                  </FormGroup>
                  <FormGroup
                    label="State"
                    labelInfo="(required)"
                    helperText={errors.state && touched.state && errors.state}
                    intent={
                      errors.state && touched.state ? Intent.DANGER : undefined
                    }
                  >
                    {values.country === 'AU' ||
                    values.country === 'CA' ||
                    values.country === 'JP' ||
                    values.country === 'US' ? (
                      <HTMLSelect
                        options={countryAndStateCodes
                          .find(code => code.code === values.country)
                          ?.states?.map(country => ({
                            label: country.name,
                            value: country.code,
                          }))}
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        fill
                      />
                    ) : (
                      <InputGroup
                        name="state"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.state}
                        intent={
                          errors.state && touched.state
                            ? Intent.DANGER
                            : undefined
                        }
                      />
                    )}
                  </FormGroup>
                  <FormGroup
                    label="Country"
                    labelInfo="(required)"
                    helperText={
                      errors.country && touched.country && errors.country
                    }
                    intent={
                      errors.country && touched.country
                        ? Intent.DANGER
                        : undefined
                    }
                  >
                    <HTMLSelect
                      options={countryAndStateCodes.map(country => ({
                        label: country.name,
                        value: country.code,
                      }))}
                      name="country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      fill
                    />
                  </FormGroup>
                  <FormGroup
                    label="Post code"
                    labelInfo="(required)"
                    helperText={
                      errors.postcode && touched.postcode && errors.postcode
                    }
                    intent={
                      errors.postcode && touched.postcode
                        ? Intent.DANGER
                        : undefined
                    }
                  >
                    <InputGroup
                      name="postcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postcode}
                      intent={
                        errors.postcode && touched.postcode
                          ? Intent.DANGER
                          : undefined
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <Button
                disabled={!stripe || isSubmitting}
                loading={isSubmitting}
                intent={Intent.SUCCESS}
                type="submit"
                onClick={submitForm}
              >
                Review
              </Button>
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export { BillingAndShipping };
