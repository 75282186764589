import React from 'react';
import Masonry from 'react-masonry-css';
import { useMasonryGridStyles } from '../../hooks/styleHooks';

interface IProps {
  elements: JSX.Element[];
  breakpointCols?: number;
}

const ArtworkGrid: React.FunctionComponent<IProps> = (props: IProps) => {
  const { elements, breakpointCols } = props;
  const masonryGridStyles = useMasonryGridStyles();

  return (
    <div style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Masonry
        breakpointCols={breakpointCols || 3}
        className={masonryGridStyles.masonryGrid}
        columnClassName={masonryGridStyles.masonryGrid_column}
      >
        {elements}
      </Masonry>
    </div>
  );
};

export { ArtworkGrid };
