import React, { useState, useContext } from 'react';
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  TextArea,
} from '@blueprintjs/core';
import { addNewArtist } from '../../client/firestoreClient';
import { AlertsContext } from '../../context/AlertsContext';
import { useMainStyles } from '../../hooks/styleHooks';

const AddNewArtist: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [newArtistFirstName, setNewArtistFirstName] = useState('');
  const [newArtistLastName, setNewArtistLastName] = useState('');
  const [newArtistBio, setNewArtistBio] = useState('');
  const [isAddingArtist, setIsAddingArtist] = useState(false);
  const mainStyles = useMainStyles();

  const handleChangeNewArtistFirstName = (event: any) => {
    setNewArtistFirstName(event.target.value);
  };

  const handleChangeNewArtistLastName = (event: any) => {
    setNewArtistLastName(event.target.value);
  };

  const handleChangeNewArtistBio = (event: any) => {
    setNewArtistBio(event.target.value);
  };

  const handleClickAddArtist = async () => {
    try {
      if (!newArtistFirstName || !newArtistLastName) {
        alertsContext.addAlert(
          `You need to fill in at least the first name and last name.`,
          Intent.DANGER
        );
        return;
      }

      setIsAddingArtist(true);
      const res = await addNewArtist(
        newArtistFirstName,
        newArtistLastName,
        newArtistBio
      );
      setIsAddingArtist(false);
      if (res) {
        alertsContext.addAlert(
          `You've successfully added the artist ${newArtistFirstName} ${newArtistLastName}. It's now possible to add artworks for this artist.`,
          Intent.SUCCESS
        );
      }
    } catch (err) {
      alertsContext.addAlert(
        `Unable to add artist ${newArtistFirstName} ${newArtistLastName}. Please try again later.`,
        Intent.DANGER
      );
    }
  };

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Add artist</h2>
      <div className="admin-form-container">
        <div className="admin-form-box">
          <FormGroup
            label="The artist's first name"
            labelFor="artist-first-name"
            labelInfo="(required)"
          >
            <InputGroup
              id="artist-first-name"
              value={newArtistFirstName}
              onChange={handleChangeNewArtistFirstName}
            />
          </FormGroup>
        </div>
        <div className="admin-form-box">
          <FormGroup
            label="The artist's last name"
            labelFor="artist-last-name"
            labelInfo="(required)"
          >
            <InputGroup
              id="artist-last-name"
              value={newArtistLastName}
              onChange={handleChangeNewArtistLastName}
            />
          </FormGroup>
        </div>
        <div className="admin-form-box">
          <FormGroup label="The artist's bio" labelFor="artist-bio">
            <TextArea
              id="artist-bio"
              value={newArtistBio}
              onChange={handleChangeNewArtistBio}
              growVertically={true}
              large={true}
              style={{ width: '100%' }}
              rows={7}
            />
          </FormGroup>
        </div>
        <div className="admin-form-box">
          <Button
            onClick={handleClickAddArtist}
            intent={Intent.PRIMARY}
            loading={isAddingArtist}
          >
            Add artist
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AddNewArtist };
