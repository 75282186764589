import React, { useRef } from 'react';
import Cropper from 'react-cropper';

interface EditableImageProps {
  imageSrc: string;
}

/**
 * Some notes on requirements: (TODO)
 */

const EditableImage: React.FunctionComponent<EditableImageProps> = (
  props: EditableImageProps
) => {
  const { imageSrc } = props;
  const cropperRef = useRef<HTMLImageElement>(null);
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    console.log(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <>
      <img src={imageSrc} style={{ maxWidth: 600 }} />
      {/* <Cropper
        src={imageSrc}
        style={{ height: 400, width: '100%' }}
        // Cropper.js options
        initialAspectRatio={16 / 9}
        guides={false}
        crop={onCrop}
        ref={cropperRef}
      /> */}
    </>
  );
};

export { EditableImage };
