import React from 'react';
import { Helmet } from 'react-helmet';
import { useMainStyles } from '../hooks/styleHooks';

// NOTE: we probably won't need this page. We can use firebase's signInOrSignUp functionality to include both things in one page

const SignUp: React.FunctionComponent = () => {
  const mainStyles = useMainStyles();
  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sign up to the Picture House Gallery</title>
      </Helmet>
      <h1>Sign up</h1>
    </div>
  );
};

export { SignUp };
