import React, { useContext, useState, useEffect } from 'react';
import { config } from '../../config';
import { CartItem } from '../../components/common/CartItem';
import { Button, Intent, Icon } from '@blueprintjs/core';
import { AlertsContext } from '../../context/AlertsContext';
import { PurchaseContext } from '../../context/PurchaseContext';
import { Helmet } from 'react-helmet';
import { useGenericGridStyles, useMainStyles } from '../../hooks/styleHooks';
import { IDistributor } from '../../common-src/constants/constants';

interface IProps {
  isVisible: boolean;
}

const Cart: React.FunctionComponent<IProps> = (props: IProps) => {
  const alertContext = useContext(AlertsContext);
  const purchaseContext = useContext(PurchaseContext);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  const { cartItems, subtotal, removeFromCart, setStage } = purchaseContext;

  const handleRemoveFromCart = (
    artworkId: string,
    distributor: IDistributor,
    productId: string | number,
    variantId: string | number
  ) => {
    const removedArtwork = removeFromCart(
      artworkId,
      distributor,
      productId,
      variantId
    );
    alertContext.addAlert(
      `Removed ${removedArtwork!.artwork.title} from your cart`,
      Intent.SUCCESS
    );
  };

  const handleGoToCardDetails = async () => {
    setStage(1);
    window.scrollTo(0, 0);
  };

  return (
    <div
      style={{ display: props.isVisible ? 'block' : 'none' }}
      className={mainStyles.regularTextStyle}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cart - The Picture House Gallery</title>
      </Helmet>
      <>
        <div className={genericGridStyles.flexGrid} style={{ paddingTop: 30 }}>
          <div className={genericGridStyles.flexGridCol1}>
            {cartItems && cartItems.length ? (
              <>
                <div>
                  {cartItems.map((cartItem, index) => {
                    const key = `${index}-${cartItem.artwork.id}`;
                    return (
                      <CartItem
                        cartItem={cartItem}
                        key={key}
                        onRemove={handleRemoveFromCart}
                      />
                    );
                  })}
                </div>
                <div>
                  <div>
                    <h3>Subtotal</h3>
                  </div>
                  <div>
                    <span
                      style={{
                        fontSize: 18,
                        color: 'darkgrey',
                        textDecoration: 'underline',
                      }}
                    >
                      £
                      {subtotal.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                  {config.env !== 'prod' && (
                    <div style={{ paddingTop: 20 }}>
                      <Button
                        intent={Intent.SUCCESS}
                        onClick={handleGoToCardDetails}
                      >
                        Go to billing and shipping
                      </Button>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <div>There are currently no items in your cart.</div>
            )}
          </div>
          <div className={genericGridStyles.flexGridCol1}>
            <div
              style={{
                minHeight: 500,
                padding: 20,
                marginBottom: 20,
                marginLeft: 300,
                marginRight: 100,
              }}
            >
              <div style={{ textAlign: 'left' }}>
                <div>
                  <Icon icon="tick-circle" intent={Intent.SUCCESS} />
                  <span style={{ marginLeft: 20 }}>
                    Shipping included on original art
                  </span>
                </div>
                <div>
                  <Icon icon="tick-circle" intent={Intent.SUCCESS} />
                  <span style={{ marginLeft: 20 }}>
                    7-day money back guarantee
                  </span>
                </div>
              </div>
              <div style={{ textAlign: 'left' }}>
                <h4 style={{ marginTop: 20 }}>Payment methods</h4>
                <div>
                  <img
                    width="40"
                    height="40"
                    style={{ marginRight: 7 }}
                    src="/visa.svg"
                  />
                  <img
                    width="40"
                    height="40"
                    style={{ marginRight: 7 }}
                    src="/discover.svg"
                  />
                  <img
                    width="40"
                    height="40"
                    style={{ marginRight: 7 }}
                    src="/amex.svg"
                  />
                  <img
                    width="40"
                    height="40"
                    style={{ marginRight: 7 }}
                    src="/mastercard.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export { Cart };
