import React from 'react';
import { Button, Intent, MenuItem } from '@blueprintjs/core';
import { IItemRendererProps, Select } from '@blueprintjs/select';
import { IArtwork } from '../../common-src/types/Artwork';
import { IPrintfulProduct } from '../../common-src/types/Printful';
import { IPrintifyProduct } from '../../common-src/types/Printify';
import {
  useArtworkInfoStyles,
  useFormStyles,
  useMainStyles,
  usePurchaseSidebarStyles,
} from '../../hooks/styleHooks';
import { displayPrice } from '../../utils';
import {
  IArtworkProduct,
  IArtworkProductVariant,
} from '../../common-src/types/ArtworkProduct';
import { PRINTFUL_PRODUCTS } from '../../common-src/constants/printful';
import { Tabs, Tab } from '../common/Tabs';

interface IProps {
  artwork: IArtwork;
  syncProduct: IPrintfulProduct | null;
  existsInPrintify: boolean;
  printifyProducts: IPrintifyProduct[];
  handleAddPrintfulVariantToCart?: (event: any) => void;
  handleSelectArtworkProduct: (artworkProduct: IArtworkProduct) => void;
  selectedArtworkProduct: IArtworkProduct;
  allArtworkProducts: IArtworkProduct[];
  selectedArtworkProductVariant?: IArtworkProductVariant;
  allVariantsForArtworkProduct?: IArtworkProductVariant[];
  handleSelectArtworkProductVariant: (
    artworkProductVariant: IArtworkProductVariant
  ) => void;
  handleAddArtworkProductVariantToCart: () => void;
}

const PurchaseSidebar: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    artwork,
    syncProduct,
    existsInPrintify,
    printifyProducts,
    handleAddPrintfulVariantToCart,
    handleSelectArtworkProduct,
    selectedArtworkProduct,
    allArtworkProducts,
    selectedArtworkProductVariant,
    allVariantsForArtworkProduct,
    handleSelectArtworkProductVariant,
    handleAddArtworkProductVariantToCart,
  } = props;

  const mainStyles = useMainStyles();
  const purchaseSidebarStyles = usePurchaseSidebarStyles();
  const artworkInfoStyles = useArtworkInfoStyles();
  const formStyles = useFormStyles();

  const ArtworkProductSelect = Select.ofType<IArtworkProduct>();
  const ArtworkProductVariantSelect = Select.ofType<IArtworkProductVariant>();

  const artworkProductListItemRenderer = (
    artworkProduct: IArtworkProduct,
    itemProps: IItemRendererProps
  ): JSX.Element | null => {
    if (artworkProduct.printDistributor === 'PRINTFUL') {
      return (
        <MenuItem
          key={`PRINTFUL-${artworkProduct.printfulProduct?.sync_product?.id}`}
          text="Canvas"
          onClick={itemProps.handleClick}
          active={itemProps.modifiers.active}
        />
      );
    } else if (artworkProduct.printDistributor === 'PRINTIFY') {
      return (
        <MenuItem
          key={`PRINTIFY-${artworkProduct.printifyProduct?.id}`}
          text={artworkProduct.printifyProduct?.userFriendlyName}
          onClick={itemProps.handleClick}
          active={itemProps.modifiers.active}
        />
      );
    } else {
      return null;
    }
  };

  const artworkProductVariantListItemRenderer = (
    artworkProductVariant: IArtworkProductVariant,
    itemProps: IItemRendererProps
  ): JSX.Element | null => {
    if (artworkProductVariant.printDistributor === 'PRINTFUL') {
      return (
        <MenuItem
          key={`PRINTFUL-${artworkProductVariant.printfulVariant?.id}`}
          text={artworkProductVariant.printfulVariant?.name}
          onClick={itemProps.handleClick}
          active={itemProps.modifiers.active}
        />
      );
    } else if (artworkProductVariant.printDistributor === 'PRINTIFY') {
      return (
        <MenuItem
          key={`PRINTIFY-${artworkProductVariant.printifyVariant?.id}`}
          text={artworkProductVariant.printifyVariant?.title}
          onClick={itemProps.handleClick}
          active={itemProps.modifiers.active}
          disabled={!artworkProductVariant.printifyVariant?.is_enabled}
        />
      );
    } else {
      return null;
    }
  };

  const getSelectedArtworkProductName = (): string | null => {
    if (selectedArtworkProduct?.printDistributor === 'PRINTFUL') {
      const matchingPrintfulProduct = Array.from(
        PRINTFUL_PRODUCTS.values()
      ).find(
        product => product.id === selectedArtworkProduct.distributorProductId
      );
      return matchingPrintfulProduct?.name!;
    } else if (selectedArtworkProduct?.printDistributor === 'PRINTIFY') {
      return selectedArtworkProduct?.printifyProduct?.userFriendlyName!;
    } else {
      return null;
    }
  };

  const getSelectedArtworkProductVariantName = (): string | null => {
    if (selectedArtworkProductVariant?.printDistributor === 'PRINTFUL') {
      return selectedArtworkProductVariant.printfulVariant?.name!;
    } else if (selectedArtworkProductVariant?.printDistributor === 'PRINTIFY') {
      return selectedArtworkProductVariant.printifyVariant?.title!;
    } else {
      return null;
    }
  };

  const getSelectedArtworkProductVariantPrice = (): number => {
    if (selectedArtworkProductVariant?.printDistributor === 'PRINTFUL') {
      return selectedArtworkProductVariant.printfulVariant?.retail_price!;
    } else if (selectedArtworkProductVariant?.printDistributor === 'PRINTIFY') {
      return selectedArtworkProductVariant.printifyVariant?.price!;
    } else {
      return 0;
    }
  };

  const selectedArtworkProductVariantPrice = getSelectedArtworkProductVariantPrice();

  const originalPanel = (
    <div key="original-panel">
      {artwork?.price ? (
        <>
          <div className={artworkInfoStyles.artworkPrice}>{`${displayPrice(
            artwork?.price,
            'gbp',
            'en-US'
          )}`}</div>
          {/* <Button
          intent={Intent.PRIMARY}
          onClick={handleAddOriginalToCart}
      >
          Add original to cart
      </Button> */}
          <p>
            We're currently not offering originals for sale. Please check back
            soon!
          </p>
        </>
      ) : (
        <div>
          It's currently not possible to buy the original of this artwork.
        </div>
      )}
    </div>
  );

  const printPanel = (
    <div key="print-panel">
      <div className={formStyles.componentWrapper}>
        <div className={formStyles.labelWrapper}>
          <label>Select a print type</label>
        </div>
        <ArtworkProductSelect
          items={allArtworkProducts}
          itemRenderer={artworkProductListItemRenderer}
          noResults={<MenuItem disabled={true} text="Loading print types..." />}
          onItemSelect={handleSelectArtworkProduct}
          activeItem={selectedArtworkProduct}
          filterable={false}
        >
          <Button
            text={getSelectedArtworkProductName() ?? 'Select...'}
            rightIcon="double-caret-vertical"
          />
        </ArtworkProductSelect>
      </div>
      <div>
        {allVariantsForArtworkProduct && (
          <div className={formStyles.componentWrapper}>
            <div className={formStyles.labelWrapper}>
              <label>Select a size</label>
            </div>
            <ArtworkProductVariantSelect
              items={allVariantsForArtworkProduct}
              itemRenderer={artworkProductVariantListItemRenderer}
              noResults={<MenuItem disabled={true} text="Loading sizes..." />}
              onItemSelect={handleSelectArtworkProductVariant}
              activeItem={selectedArtworkProductVariant}
              filterable={false}
            >
              <Button
                text={getSelectedArtworkProductVariantName() ?? 'Select...'}
                rightIcon="double-caret-vertical"
              />
            </ArtworkProductVariantSelect>
          </div>
        )}
      </div>
      {selectedArtworkProductVariantPrice ? (
        <div className={artworkInfoStyles.artworkPrice}>{`${displayPrice(
          getSelectedArtworkProductVariantPrice(),
          'gbp',
          'en-US'
        )}`}</div>
      ) : null}
      <div className={formStyles.componentWrapper}>
        <Button
          intent={Intent.PRIMARY}
          onClick={handleAddArtworkProductVariantToCart}
          disabled={!selectedArtworkProductVariant}
        >
          Add print to cart
        </Button>
      </div>
    </div>
  );

  return (
    <div className={purchaseSidebarStyles['artwork-sidebar']}>
      <Tabs>
        <Tab title="Original" content={originalPanel}></Tab>
        <Tab title="Prints" content={printPanel}></Tab>
      </Tabs>
    </div>
  );
};

export { PurchaseSidebar };
