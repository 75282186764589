import React, { useContext, useEffect, useState } from 'react';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { firebaseInstance } from '../../firebase-functions';
import { useMainStyles } from '../../hooks/styleHooks';

const ArtistAdmin: React.FunctionComponent = () => {
  const db = firebaseInstance.firestore();
  const authenticationContext = useContext(AuthenticationContext);
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const mainStyles = useMainStyles();

  useEffect(() => {
    db.collection('users')
      .where('userUID', '==', authenticationContext.currentUid)
      .limit(1)
      .get()
      .then(snap => {
        // @TODO_LATER: find a better way to do it than the below
        snap.docs.forEach(doc => {
          setLoggedInUser(doc.data());
        });
      });
  }, [authenticationContext.currentUid]);

  const isArtist = () => {
    if (loggedInUser.roleId === 2) {
      return true;
    } else {
      return false;
    }
  };

  const isSignedInAsArtist = loggedInUser && isArtist();
  const isSignedInAsNonArtist = loggedInUser && !isArtist();

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      {isSignedInAsNonArtist && (
        <div>You need to be signed in as an artist to view this page.</div>
      )}
      {isSignedInAsArtist && (
        <div>
          <h1>Welcome, artist admin user {loggedInUser.firstName}</h1>
        </div>
      )}
    </div>
  );
};

export { ArtistAdmin };
