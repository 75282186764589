import React, { useState, useEffect, useContext } from 'react';
import uuid from 'uuid/v4';
import {
  Button,
  FormGroup,
  InputGroup,
  NumericInput,
  FileInput,
  Intent,
  Spinner,
} from '@blueprintjs/core';
import { IArtist } from '../../../common-src/types/Artist';
import { uploadImageToBackEnd } from '../../../func/images';
import { AlertsContext } from '../../../context/AlertsContext';
import { mediums } from '../../../common-src/constants/constants';
import { ArtistSelect } from '../../common/ArtistSelect';
import { useGenericGridStyles, useMainStyles } from '../../../hooks/styleHooks';
import { EditableImage } from './EditableImage';

/**
 * @TODO_LATER: ensure that (for now) you can only select an artist from the dropdown menu (not type). Later when there are too many
 * artists to load in the menu, find a different approach.
 * Also find some way of distinguishing between different artists with the same name...
 */

const UploadArtwork: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  const [
    selectedArtistForUploadingImages,
    setSelectedArtistForUploadingImages,
  ] = useState<IArtist | null>(null);

  const [artworkTitle, setArtworkTitle] = useState('');
  const [artworkPrice, setArtworkPrice] = useState<number | undefined>(
    undefined
  );
  const [artworkPrintPrice, setArtworkPrintPrice] = useState<
    number | undefined
  >(undefined);
  const [artworkDescription, setArtworkDescription] = useState<string>('');
  const [artworkMedium, setArtworkMedium] = useState('painting');

  const [fullSizeImageToUpload, setFullSizeImageToUpload] =
    useState<HTMLImageElement | null>(null);
  const [unnamedFullSizeImageFile, setUnnamedFullSizeImageFile] =
    useState<File | null>(null);
  const [isUploadingImageFE, setIsUploadingImageFE] = useState(false);
  const [isUploadingImageBE, setIsUploadingImageBE] = useState(false);

  useEffect(() => {
    setArtworkMedium('painting');
  }, []);

  const handleValueChange = (artist: IArtist) => {
    setSelectedArtistForUploadingImages(artist);
  };

  const handleTitleChange = (event: any) => {
    setArtworkTitle(event.target.value);
  };

  const handlePriceChange = (event: any) => {
    setArtworkPrice(event);
  };

  const handlePrintPriceChange = (event: any) => {
    setArtworkPrintPrice(event);
  };

  const handleDescriptionChange = (event: any) => {
    setArtworkDescription(event.target.value);
  };

  const handleMediumChange = (event: any) => {
    setArtworkMedium(event.target.value);
  };

  const handleImageSelect = (event: React.FormEvent<HTMLInputElement>) => {
    setIsUploadingImageFE(true);
    // @ts-ignore
    const file = event.target.files[0];
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      setUnnamedFullSizeImageFile(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (reader.result) {
          const fullSizeImage: HTMLImageElement = new Image();
          // @ts-ignore
          fullSizeImage.src = reader.result!;

          fullSizeImage.onload = async () => {
            setFullSizeImageToUpload(fullSizeImage);
            setIsUploadingImageFE(false);
          };
        } else {
          setFullSizeImageToUpload(null);
          setIsUploadingImageFE(false);
        }
      };
    } else {
      alertsContext.addAlert(
        `Couldn't upload the file because it has an unrecognised type.`,
        Intent.DANGER
      );
    }
  };

  const uploadNewArtwork = async () => {
    setIsUploadingImageBE(true);
    const fileId = uuid();
    const filename = `${selectedArtistForUploadingImages?.id}_${fileId}`;
    const fullSizeImageFile = new File(
      [unnamedFullSizeImageFile!],
      `${filename}.jpg`,
      {
        type: unnamedFullSizeImageFile!.type,
      }
    );
    if (!fullSizeImageFile) {
      return;
    }

    try {
      await uploadImageToBackEnd(
        fullSizeImageFile,
        selectedArtistForUploadingImages?.id!,
        artworkTitle,
        artworkPrice?.toString()!,
        artworkPrintPrice?.toString()!,
        artworkDescription,
        artworkMedium
      );
      setIsUploadingImageBE(false);
      alertsContext.addAlert(
        `You've successfully uploaded the image and it should now be
available to view.`,
        Intent.SUCCESS
      );
    } catch (error) {
      setFullSizeImageToUpload(null);
      alertsContext.addAlert(
        'There was an error uploading the image. Please try again later.',
        Intent.DANGER
      );
    }
  };

  const isUploadDisabled =
    !fullSizeImageToUpload ||
    !selectedArtistForUploadingImages ||
    !artworkTitle ||
    isUploadingImageBE;

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Upload artwork</h2>
      <div className="admin-form-box">
        <FormGroup label="Artist" labelFor="artist-name" labelInfo="(required)">
          <ArtistSelect handleValueChange={handleValueChange} />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup
          label="The artwork's title"
          labelFor="artwork-name"
          labelInfo="(required)"
        >
          <InputGroup
            id="artwork-name"
            value={artworkTitle}
            onChange={handleTitleChange}
          />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup label="The artwork's price" labelFor="artwork-price">
          <NumericInput
            id="artwork-price"
            value={artworkPrice}
            onValueChange={handlePriceChange}
            buttonPosition="none"
            fill
          />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup
          label="The artwork's price per print"
          labelFor="artwork-print-price"
        >
          <NumericInput
            id="artwork-print-price"
            value={artworkPrintPrice}
            onValueChange={handlePrintPriceChange}
            buttonPosition="none"
            fill
          />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup
          label="Medium"
          labelFor="artwork-medium"
          labelInfo="(required)"
        >
          <select value={artworkMedium} onChange={handleMediumChange}>
            {mediums.map(medium => (
              <option value={medium} key={medium}>
                {medium}
              </option>
            ))}
          </select>
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup
          label="A description of the artwork"
          labelFor="artwork-description"
        >
          <InputGroup
            id="artwork-description"
            value={artworkDescription}
            onChange={handleDescriptionChange}
          />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <FormGroup
          label="Select image file"
          labelFor="artwork-file"
          labelInfo="(required)"
        >
          <FileInput onInputChange={handleImageSelect} fill id="artwork-file" />
        </FormGroup>
      </div>

      <div className="admin-form-box">
        <Button
          intent={Intent.PRIMARY}
          disabled={isUploadDisabled}
          onClick={uploadNewArtwork}
          loading={isUploadingImageBE}
        >
          Upload
        </Button>
      </div>
      <div className="admin-box">
        {isUploadingImageFE && <Spinner />}
        {fullSizeImageToUpload && (
          <EditableImage imageSrc={fullSizeImageToUpload.src} />
        )}
      </div>
    </div>
  );
};

export { UploadArtwork };
