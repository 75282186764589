import React, { useContext, useEffect } from 'react';
import { BillingAndShipping } from './BillingAndShipping';
import { Review } from './Review';
import { Cart } from './Cart';
import { PurchaseContext } from '../../context/PurchaseContext';
import { Confirmation } from './Confirmation';
import {
  IShippingRatesItem,
  IShippingRatesRequest,
  IShippingRatesResponse,
} from '../../common-src/types/ShippingRates';
import { shippingTypes } from '../../common-src/constants/printful';
import { getShippingRatesForOrder } from '../../client/printfulClient';
import { Steps } from 'antd';
import { WindowContext } from '../../context/WindowContext';
import { useMainStyles, usePurchaseStyles } from '../../hooks/styleHooks';
import { IAddress } from '../../common-src/types/BillingAndShippingInfo';

const Purchase: React.FunctionComponent = () => {
  const purchaseContext = useContext(PurchaseContext);
  const windowContext = useContext(WindowContext);
  const { stage } = purchaseContext;
  const mainStyles = useMainStyles();
  const purchaseStyles = usePurchaseStyles();

  useEffect(() => {
    retrieveShippingRates();
  }, [purchaseContext.billingAndShippingInfo]);

  const retrieveShippingRates = async () => {
    const { billingAndShippingInfo, cartItems } = purchaseContext;
    if (billingAndShippingInfo?.shipping.address && cartItems) {
      const items: IShippingRatesItem[] = cartItems.map(item => ({
        variant_id: +item.externalIdentifiers?.variantId!, // TODO: check this!!!
        quantity: item.quantity,
      }));
      const shippingRatesRequest: IShippingRatesRequest = {
        recipient: {
          address1: (billingAndShippingInfo?.shipping.address as IAddress)
            .line1,
          city: (billingAndShippingInfo?.shipping.address as IAddress).city,
          country_code: (billingAndShippingInfo?.shipping.address as IAddress)
            .country,
          zip: (billingAndShippingInfo?.shipping.address as IAddress)
            .postalCode,
        },
        items,
      };
      try {
        const res = await getShippingRatesForOrder(shippingRatesRequest);
        const shippingRate = (res as IShippingRatesResponse).result.find(
          rateItem => rateItem.id === shippingTypes.standard
        );
        if (shippingRate) {
          purchaseContext.setShippingRate(shippingRate);
        } else {
          throw new Error('Unable to retrieve shipping rate');
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div className={mainStyles.genericPage}>
      <div className={mainStyles.centreChildren}>
        <div className={purchaseStyles.purchaseSteps}>
          <Steps
            current={stage}
            onChange={newStage => {
              if (newStage < stage) {
                purchaseContext.setStage(newStage);
              }
            }}
          >
            <Steps.Step
              title={windowContext?.screenWidth! > 800 ? 'Cart' : ''}
            />
            <Steps.Step
              title={
                windowContext?.screenWidth! > 800 ? 'Billing and shipping' : ''
              }
            />
            <Steps.Step
              title={windowContext?.screenWidth! > 800 ? 'Review' : ''}
            />
            <Steps.Step
              title={windowContext?.screenWidth! > 800 ? 'Confirmation' : ''}
            />
          </Steps>
        </div>
      </div>
      <Cart isVisible={stage === 0} />
      <BillingAndShipping isVisible={stage === 1} />
      <Review isVisible={stage === 2} />
      <Confirmation isVisible={stage === 3} />
    </div>
  );
};

export { Purchase };
