import React from 'react';
import { Menu } from '@blueprintjs/core';
import AdminNavBarItem from './AdminNavBarItem';
import { useAdminStyles, useMainStyles } from '../../hooks/styleHooks';

const AdminNavBarSide: React.FunctionComponent = () => {
  const adminClasses = useAdminStyles();
  const mainStyles = useMainStyles();

  return (
    <div
      className={`${adminClasses['admin-navbar-wrapper']} ${mainStyles.regularTextStyle}`}
    >
      <div className={adminClasses['admin-navbar']}>
        <Menu style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
          <AdminNavBarItem to="/admin" text="Admin home" />
          <AdminNavBarItem to="/admin/add-artist" text="Add artist" />
          <AdminNavBarItem to="/admin/edit-artist" text="Edit artist" />
          <AdminNavBarItem to="/admin/upload-artwork" text="Upload artwork" />
          <AdminNavBarItem to="/admin/edit-artwork" text="Edit artwork" />
          <AdminNavBarItem to="/admin/featured-images" text="Featured images" />
          <AdminNavBarItem to="/admin/transactions" text="Transactions" />
        </Menu>
      </div>
    </div>
  );
};

export { AdminNavBarSide };
