import React from 'react';
import { useMainStyles } from '../../hooks/styleHooks';

interface IProps {
  userFirstName: string;
  userLastName: string;
}

const AdminHome: React.FunctionComponent<IProps> = (props: IProps) => {
  const { userFirstName, userLastName } = props;
  const mainStyles = useMainStyles();
  return (
    <div className={mainStyles.regularTextStyle}>
      <h1 className={mainStyles.pageHeading}>Admin page</h1>
      <p>Welcome, {`${userFirstName} ${userLastName}`}</p>
      <p>
        Click any of the links to the side for your desired admin functionality.
      </p>
    </div>
  );
};

export { AdminHome };
