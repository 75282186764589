import React, { useContext, useState, useEffect } from 'react';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { AddNewArtist } from './AddNewArtist';
import { UploadArtwork } from './UploadArtwork/UploadArtwork';
import { firebaseInstance } from '../../firebase-functions';
import { AdminNavBarSide } from './AdminNavBarSide';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { AdminHome } from './AdminHome';
import { EditArtist } from './EditArtist';
import { EditArtwork } from './EditArtwork/EditArtwork';
import { FeaturedImages } from './FeaturedImages';
import { Transactions } from './Transactions';
import { Helmet } from 'react-helmet';
import { useGenericGridStyles, useMainStyles } from '../../hooks/styleHooks';

const Admin: React.FunctionComponent = () => {
  const db = firebaseInstance.firestore();
  const authenticationContext = useContext(AuthenticationContext);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  useEffect(() => {
    db.collection('users')
      .where('userUID', '==', authenticationContext.currentUid)
      .limit(1)
      .get()
      .then(snap => {
        // @TODO_LATER: find a better way to do it than the below
        snap.docs.forEach(doc => {
          setLoggedInUser(doc.data());
        });
      });
  }, [authenticationContext.currentUid]);

  const isAdmin = () => {
    if (loggedInUser.roleId === 1) {
      return true;
    } else {
      return false;
    }
  };

  const isSignedInAsAdmin = loggedInUser && isAdmin();
  const isSignedInAsNonAdmin = loggedInUser && !isAdmin();

  return (
    <BrowserRouter>
      <div className={mainStyles.regularTextStyle}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Admin page - The Picture House Gallery</title>
        </Helmet>
        {isSignedInAsNonAdmin && (
          <div>You need to be signed in as an admin to view this page.</div>
        )}
        {isSignedInAsAdmin && (
          <div className={genericGridStyles.flexGrid}>
            <div className={genericGridStyles.flexGridCol1}>
              <AdminNavBarSide />
            </div>
            <div
              className={`${mainStyles.genericPage} ${genericGridStyles.flexGridCol4}`}
              style={{ overflowX: 'auto' }}
            >
              <Switch>
                <Route exact path="/admin">
                  <AdminHome
                    userFirstName={loggedInUser.firstName}
                    userLastName={loggedInUser.lastName}
                  />
                </Route>
                <Route
                  exact
                  path="/admin/add-artist"
                  component={AddNewArtist}
                />
                <Route exact path="/admin/edit-artist" component={EditArtist} />
                <Route
                  exact
                  path="/admin/upload-artwork"
                  component={UploadArtwork}
                />
                <Route
                  exact
                  path="/admin/edit-artwork"
                  component={EditArtwork}
                />
                <Route
                  exact
                  path="/admin/edit-artwork/:id"
                  component={EditArtwork}
                />
                <Route
                  exact
                  path="/admin/featured-images"
                  component={FeaturedImages}
                />
                <Route
                  exact
                  path="/admin/transactions"
                  component={Transactions}
                />
              </Switch>
            </div>
          </div>
        )}
      </div>
    </BrowserRouter>
  );
};

export { Admin };
