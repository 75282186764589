import React, { useState, useEffect, useContext } from 'react';
import uuid from 'uuid/v4';
import {
  getFeaturedImages,
  updateFeaturedImages,
} from '../../client/firestoreClient';
import {
  Button,
  Intent,
  FormGroup,
  FileInput,
  Spinner,
} from '@blueprintjs/core';
import { IArtist } from '../../common-src/types/Artist';
import { IArtwork } from '../../common-src/types/Artwork';
import { useArtworksByArtist } from '../../hooks/artworkHooks';
import { AlertsContext } from '../../context/AlertsContext';
import { IImageDataWithMetadata } from '../../common-src/types/ImageData';
import { IFeaturedImage } from '../../common-src/types/FeaturedImage';
import { uploadFeaturedImage } from '../../func/images';
import { useGenericGridStyles, useMainStyles } from '../../hooks/styleHooks';

const FeaturedImages: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [featuredImages, setFeaturedImages] = useState<IFeaturedImage[] | null>(
    null
  );
  const [selectedArtist, setSelectedArtist] = useState<IArtist | null>(null);
  const [selectedArtwork, setSelectedArtwork] = useState<IArtwork | null>(null);
  const [isSavingFeaturedImages, setIsSavingFeaturedImages] = useState(false);

  const [imageData, setImageData] = useState<IImageDataWithMetadata | null>(
    null
  );
  const [isUploadingImageFE, setIsUploadingImageFE] = useState(false);
  const [isUploadingImageBE, setIsUploadingImageBE] = useState(false);
  const [
    unnamedFullSizeImageFile,
    setUnnamedFullSizeImageFile,
  ] = useState<File | null>(null);
  const [
    fullSizeImageToUpload,
    setFullSizeImageToUpload,
  ] = useState<HTMLImageElement | null>(null);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  const artworksAssociatedWithSelectedArtist = useArtworksByArtist(
    selectedArtist
  );

  useEffect(() => {
    retrieveFeaturedImages();
  }, []);

  useEffect(() => {
    if (selectedArtwork) {
      const imageData: IImageDataWithMetadata = {
        previewUrl: selectedArtwork.previewUrl,
        previewUrlHeight400: selectedArtwork.previewUrlHeight400!,
        previewUrlWidth400: selectedArtwork.previewUrlWidth400!,
        destinationUrl: `/work/${selectedArtwork.id}`,
        title: selectedArtwork.title,
        // artistName: `${selectedArtwork.artistFirstName} ${selectedArtwork.artistLastName}`,
      };
      setImageData(imageData);
    }
  }, [selectedArtwork]);

  const retrieveFeaturedImages = async () => {
    try {
      const featured = await getFeaturedImages();
      setFeaturedImages(featured);
    } catch (err) {
      alertsContext.addAlert(
        'Unable to retrieve featured images',
        Intent.DANGER
      );
    }
  };

  const handleSelectedArtistChange = (artist: IArtist) => {
    setSelectedArtist(artist);
    setSelectedArtwork(null);
  };

  const handleSelectedArtworkIdChange = async (artwork: IArtwork) => {
    setSelectedArtwork(artwork);
  };

  const handleToggleFeatureActive = async (id: string) => {
    try {
      const matchingIndex = featuredImages?.findIndex(image => image.id === id);
      const updatedFeaturedImages = Array.from(featuredImages!);
      updatedFeaturedImages[matchingIndex!].isEnabled = !Boolean(
        updatedFeaturedImages[matchingIndex!].isEnabled
      );
      setFeaturedImages(updatedFeaturedImages || null);
    } catch (err) {
      alertsContext.addAlert(
        'Unable to remove this image from featured images',
        Intent.DANGER
      );
    }
  };

  const handleAddArtworkToFeaturedImages = () => {
    const updatedFeaturedImages = featuredImages;
    const id = uuid();
    updatedFeaturedImages?.push({
      id,
      fullSizeUrl: selectedArtwork?.fullSizeUrl!,
      previewUrl: selectedArtwork?.previewUrl!,
      previewUrlWidth400: selectedArtwork?.previewUrlWidth400!,
      previewUrlHeight400: selectedArtwork?.previewUrlHeight400!,
      artworkTitle: selectedArtwork?.title,
      artworkId: selectedArtwork?.id,
      // TODO_LATER: overlay text
    });

    setFeaturedImages(Array.from(updatedFeaturedImages!) || null);
  };

  const handleSaveFeaturedImages = async () => {
    if (!featuredImages?.length) {
      alertsContext.addAlert('No featured images to add!', 'danger');
    }
    setIsSavingFeaturedImages(true);

    try {
      const res = await updateFeaturedImages(featuredImages!);
      if (res) {
        alertsContext.addAlert(
          `You've successfully updated the featured images`,
          Intent.SUCCESS
        );
      } else {
        alertsContext.addAlert(
          `There was an error updating the featured images`,
          Intent.DANGER
        );
      }
    } catch (err) {
      alertsContext.addAlert('Unable to save featured images', Intent.DANGER);
    } finally {
      setIsSavingFeaturedImages(false);
    }
  };

  const handleImageSelect = (event: React.FormEvent<HTMLInputElement>) => {
    setIsUploadingImageFE(true);
    // @ts-ignore
    const file = event.target.files[0];
    if (
      file.type === 'image/jpeg' ||
      file.type === 'image/jpg' ||
      file.type === 'image/png'
    ) {
      setUnnamedFullSizeImageFile(file);

      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onloadend = () => {
        if (reader.result) {
          const fullSizeImage: HTMLImageElement = new Image();
          // @ts-ignore
          fullSizeImage.src = reader.result!;

          fullSizeImage.onload = async () => {
            setFullSizeImageToUpload(fullSizeImage);
            setIsUploadingImageFE(false);
          };
        } else {
          setFullSizeImageToUpload(null);
          setIsUploadingImageFE(false);
        }
      };
    } else {
      alertsContext.addAlert(
        `Couldn't upload the file because it has an unrecognised type.`,
        Intent.DANGER
      );
    }
  };

  const handleUploadFeaturedImage = async () => {
    setIsUploadingImageBE(true);
    const fileId = uuid();
    const filename = `featured_${fileId}`;
    const fullSizeImageFile = new File(
      [unnamedFullSizeImageFile!],
      `${filename}.jpg`,
      {
        type: unnamedFullSizeImageFile!.type,
      }
    );
    if (!fullSizeImageFile) {
      return;
    }

    try {
      await uploadFeaturedImage(fullSizeImageFile, filename);
      setIsUploadingImageBE(false);
      alertsContext.addAlert(
        `You've successfully uploaded the image and it should now be
available to view.`,
        Intent.SUCCESS
      );
    } catch (error) {
      setFullSizeImageToUpload(null);
      alertsContext.addAlert(
        'There was an error uploading the image. Please try again later.',
        Intent.DANGER
      );
    }
  };

  const isUploadDisabled = !fullSizeImageToUpload || isUploadingImageBE;

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Featured images</h2>
      <p>
        The images you select here will appear in the slideshow on the home
        page.
      </p>
      {featuredImages?.length ? (
        <div className="admin-form-box">
          <div>
            Click image in below list to either enable or disable.
            {featuredImages.map(image => (
              <div key={image.id} style={{ margin: 10 }}>
                <div>
                  <img
                    src={image.previewUrl}
                    onClick={() => handleToggleFeatureActive(image.id)}
                    alt={image.previewUrl}
                    height={300}
                    style={image.isEnabled ? {} : { filter: 'grayscale(100%)' }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="admin-form-box">
          There are currently no featured images. Add below.
        </div>
      )}
      <div className="admin-form-box">
        <FormGroup
          label="Select image file"
          labelFor="artwork-file"
          labelInfo="(required)"
        >
          <FileInput onInputChange={handleImageSelect} fill id="artwork-file" />
        </FormGroup>
      </div>
      <div className="admin-form-box">
        <Button
          intent={Intent.PRIMARY}
          onClick={handleUploadFeaturedImage}
          loading={isUploadingImageBE}
          disabled={isUploadDisabled}
        >
          Upload new featured image
        </Button>
      </div>
      <div className={`${genericGridStyles.flexGridCol1} admin-box`}>
        {isUploadingImageFE && <Spinner />}
        {fullSizeImageToUpload && (
          <img src={fullSizeImageToUpload.src} style={{ maxWidth: 600 }} />
        )}
      </div>
      <div className="admin-form-box">
        <Button
          intent={Intent.PRIMARY}
          onClick={handleSaveFeaturedImages}
          loading={isSavingFeaturedImages}
        >
          Save all changes
        </Button>
      </div>
    </div>
  );
};

export { FeaturedImages };
