import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Elevation } from '@blueprintjs/core';
import { useArtistLinkStyles } from '../../hooks/styleHooks';

interface IProps {
  artist: any;
  representativeImageSrc: string;
}

// @TODO_LATER: show a pic of the artist?

const ArtistLink: React.FunctionComponent<IProps> = (props: IProps) => {
  const { artist, representativeImageSrc } = props;
  const artistLinkStyles = useArtistLinkStyles();
  return (
    <div>
      <Card elevation={Elevation.FOUR}>
        <div style={{ marginBottom: 10 }}>
          <Link
            to={`/artist/${artist.id}`}
            className={artistLinkStyles.artistLink}
          >
            <span>{`${artist.firstName} ${artist.lastName}`}</span>
          </Link>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              padding: 4,
            }}
          >
            <Link to={`/artist/${artist.id}`}>
              <div
                style={{
                  borderRadius: 10,
                  backgroundImage: `url("${representativeImageSrc}")`,
                  backgroundSize: 'cover',
                  width: 200,
                  height: 200,
                }}
              />
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
};

export { ArtistLink };
