import React, { useContext, useState, useEffect } from 'react';
import {
  FormGroup,
  NumericInput,
  Button,
  Checkbox,
  Intent,
} from '@blueprintjs/core';
import { FilterContext } from '../../context/FilterContext';
import { useFilterBoxStyles, useMainStyles } from '../../hooks/styleHooks';

interface Props {
  shouldShowMediumFilter?: boolean;
}

const FilterBox: React.FunctionComponent<Props> = (props: Props) => {
  const filterContext = useContext(FilterContext);
  const mainStyles = useMainStyles();
  const filterBoxStyles = useFilterBoxStyles();
  const [paintingSelected, setPaintingSelected] = useState(
    filterContext.mediums.includes('painting')
  );
  const [sculptureSelected, setSculptureSelected] = useState(
    filterContext.mediums.includes('sculpture')
  );
  const [lightboxSelected, setLightboxSelected] = useState(
    filterContext.mediums.includes('lightbox')
  );
  const [photographySelected, setPhotographySelected] = useState(
    filterContext.mediums.includes('photograph')
  );

  const [minPrice, setMinPrice] = useState(filterContext?.priceRange.minPrice);
  const [maxPrice, setMaxPrice] = useState(filterContext?.priceRange.maxPrice);

  const handleCheckPainting = (event: any) => {
    setPaintingSelected(event.target.checked);
  };
  const handleCheckSculpture = (event: any) => {
    setSculptureSelected(event.target.checked);
  };
  const handleCheckLightbox = (event: any) => {
    setLightboxSelected(event.target.checked);
  };
  const handleCheckPhotography = (event: any) => {
    setPhotographySelected(event.target.checked);
  };

  const handleMinPriceChange = (event: any) => {
    setMinPrice(+event);
  };

  const handleMaxPriceChange = (event: any) => {
    setMaxPrice(+event);
  };

  useEffect(() => {
    handleFilter();
  }, [
    paintingSelected,
    sculptureSelected,
    lightboxSelected,
    photographySelected,
    minPrice,
    maxPrice,
  ]);

  const handleFilter = () => {
    if (props.shouldShowMediumFilter) {
      const listOfMediums: string[] = [];
      if (paintingSelected) {
        listOfMediums.push('painting');
      }
      if (sculptureSelected) {
        listOfMediums.push('sculpture');
      }
      if (photographySelected) {
        listOfMediums.push('photograph');
      }
      if (lightboxSelected) {
        listOfMediums.push('lightbox');
      }
      filterContext.setMediums(listOfMediums);
    }
    filterContext.setPriceRange({ minPrice, maxPrice });
  };

  // @TODO_LATER: add validation (esp for numbers, number ranges, etc.)

  return (
    <div style={{ padding: 50 }}>
      {props.shouldShowMediumFilter && (
        <div
          style={{ textAlign: 'left' }}
          className={filterBoxStyles.filterSection}
        >
          <h3 className={filterBoxStyles.heading}>Medium</h3>
          <Checkbox
            checked={paintingSelected}
            label="Painting"
            onChange={handleCheckPainting}
          />
          <Checkbox
            checked={sculptureSelected}
            label="Sculpture"
            onChange={handleCheckSculpture}
          />
          <Checkbox
            checked={lightboxSelected}
            label="Lightbox"
            onChange={handleCheckLightbox}
          />
          <Checkbox
            checked={photographySelected}
            label="Photography"
            onChange={handleCheckPhotography}
          />
        </div>
      )}
      <div className={filterBoxStyles.filterSection}>
        <h3 className={`${mainStyles.leftAligned} ${filterBoxStyles.heading}`}>
          Price
        </h3>
        <div style={{ maxWidth: 150 }}>
          <FormGroup
            label="Minimum"
            labelFor="artwork-min-price"
            className={mainStyles.leftAligned}
          >
            <NumericInput
              id="artwork-min-price"
              value={minPrice}
              onValueChange={handleMinPriceChange}
              buttonPosition="none"
              fill
            />
          </FormGroup>
          <FormGroup
            label="Maximum"
            labelFor="artwork-max-price"
            className={mainStyles.leftAligned}
          >
            <NumericInput
              id="artwork-max-price"
              value={maxPrice}
              onValueChange={handleMaxPriceChange}
              buttonPosition="none"
              fill
            />
          </FormGroup>
        </div>
      </div>
      {/* <div>
        <h2>Size</h2>
      </div>
      <div>
        <h2>Orientation</h2>
      </div> */}
    </div>
  );
};

export { FilterBox };
