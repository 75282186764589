import { useEffect, useState } from 'react';
import { firebaseInstance } from '../firebase-functions';
import _ from 'lodash';
import { IArtwork, IArtworkWithArtistName } from '../common-src/types/Artwork';
import { IPriceRange } from '../common-src/types/UtilTypes';
import { IArtist } from '../common-src/types/Artist';

const db = firebaseInstance.firestore();

interface IFilterOptions {
  mediums?: string[];
  priceRange: IPriceRange;
}

const getArtistsAndAdd = async (artworks: IArtwork[], callback: any) => {
  let tempArtworksWithArtistName: IArtworkWithArtistName[] = [];
  for (const artwork of artworks) {
    // TODO_LATER: this is the source of the slowness. We should not make a separate query for each artwork [does this still apply?]
    if (artwork.associatedArtistId) {
      await db
        .collection('artists')
        .doc(artwork.associatedArtistId)
        .get()
        .then(artist => {
          const tempArtist = artist.data();
          if (tempArtist) {
            tempArtworksWithArtistName.push(
              Object.assign(artwork, {
                artistFirstName: tempArtist.firstName,
                artistLastName: tempArtist.lastName,
              })
            );
          }
        })
        .catch(err => {
          throw err;
        });
    }
  }
  // @ts-ignore
  callback(Array.from(tempArtworksWithArtistName));
};

function useAssortedArtworks() {
  const [artworks, setArtworks] = useState<IArtwork[] | null>(null);
  const [artworksWithArtistName, setArtworksWithArtistName] = useState<
    IArtworkWithArtistName[] | null
  >(null);

  useEffect(() => {
    db.collection('artworks')
      .get()
      .then(snap => {
        let tempArtworks: IArtwork[] = [];
        snap.docs.forEach(doc => {
          const artwork = Object.assign(doc.data(), { id: doc.id });
          // @ts-ignore
          tempArtworks.push(artwork);
        });
        const shuffledArtworks = _.shuffle(tempArtworks);
        // @ts-ignore
        setArtworks(shuffledArtworks);
        getArtistsAndAdd(shuffledArtworks, setArtworksWithArtistName);
      });
  }, []);

  return { artworks, artworksWithArtistName };
}

function useFeaturedArtworks() {
  const [featuredArtworks, setFeaturedArtworks] = useState<IArtwork[] | null>(
    null
  );
  let tempFeaturedArtworks: any[] = [];

  useEffect(() => {
    db.collection('featuredArtworks')
      .get()
      .then(snap => {
        snap.docs.forEach(docSnap => {
          const artworkIds = docSnap.data().artworkIds;
          getCorrespondingArtworks(artworkIds);
        });
      });
  }, []);

  const getCorrespondingArtworks = async (artworkIds: string[]) => {
    for (const id of artworkIds) {
      await db
        .collection('artworks')
        .doc(id)
        .get()
        .then(artwork => {
          const tempWork = artwork.data();
          if (tempWork) {
            tempFeaturedArtworks.push(
              Object.assign({
                previewImageSrc: tempWork.previewUrl,
                destinationUrl: `/work/${id}`,
                artworkName: tempWork.title,
                alt: tempWork.title,
              })
            );
          }
        })
        .catch(err => {
          throw err;
        });
    }
    setFeaturedArtworks(Array.from(tempFeaturedArtworks));
  };

  return { featuredArtworks };
}

function useArtworksOfType(
  type: 'painting' | 'photograph',
  limit?: number
): {
  artworks: IArtwork[];
  artworksWithArtistName: IArtworkWithArtistName[] | null;
} {
  const [artworks, setArtworks] = useState<IArtwork[]>([]);
  const [artworksWithArtistName, setArtworksWithArtistName] = useState<
    IArtworkWithArtistName[] | null
  >(null);
  useEffect(() => {
    db.collection('artworks')
      .where('medium', '==', type)
      .limit(limit ?? 100)
      .get()
      .then(snap => {
        let tempArtworks: any[] = [];
        snap.docs.forEach(doc => {
          const artwork = Object.assign(doc.data(), { id: doc.id });
          tempArtworks.push(artwork);
        });
        const shuffledArtworks = _.shuffle(tempArtworks);
        setArtworks(shuffledArtworks);
        getArtistsAndAdd(shuffledArtworks, setArtworksWithArtistName);
      });
  }, []);

  return { artworks, artworksWithArtistName };
}

function useFilteredArtworks(filterOptions: IFilterOptions) {
  const [filteredArtworks, setFilteredArtworks] = useState<IArtwork[]>([]);
  const [
    filteredArtworksWithArtistName,
    setFilteredArtworksWithArtistName,
  ] = useState<IArtworkWithArtistName[] | null>(null);

  const collection = db.collection('artworks');
  // @TODO_LATER: fix below type
  let query: any = collection;

  // @ts-ignore
  if (filterOptions?.mediums?.length > 0) {
    query = query.where('medium', 'in', filterOptions.mediums);
  }
  if (filterOptions.priceRange.minPrice) {
    query = query.where('price', '>=', filterOptions.priceRange.minPrice);
  }
  if (filterOptions.priceRange.maxPrice) {
    query = query.where('price', '<=', filterOptions.priceRange.maxPrice);
  }

  useEffect(() => {
    query
      // .limit(30) // re-add once we have pagination
      .get()
      .then((snap: any) => {
        let tempArtworks: any[] = [];
        snap.forEach((artworkSnap: any) => {
          db.collection('artists')
            .doc(artworkSnap.data().associatedArtistId)
            .get()
            .then(artist => {
              const tempArtist = artist.data();
              if (tempArtist) {
                tempArtworks.push(
                  Object.assign(artworkSnap.data(), {
                    artistFirstName: tempArtist.firstName,
                    artistLastName: tempArtist.lastName,
                    id: artworkSnap.id,
                  })
                );
              }
              tempArtworks = _.shuffle(tempArtworks);
              setFilteredArtworksWithArtistName(tempArtworks);
            })
            .catch(error => console.log(error));
        });

        const shuffledArtworks = _.shuffle(tempArtworks);
        setFilteredArtworks(shuffledArtworks);
        setFilteredArtworksWithArtistName(shuffledArtworks);
      });
  }, [filterOptions.priceRange]);

  return { filteredArtworks, filteredArtworksWithArtistName };
}

function useArtworksByArtist(artist: IArtist | null): IArtwork[] {
  const [artworks, setArtworks] = useState<IArtwork[]>([]);

  useEffect(() => {
    if (!artist || !artist.id) {
      setArtworks([]);
    } else {
      const artistId = artist?.id;
      db.collection('artworks')
        .where('associatedArtistId', '==', artistId)
        .get()
        .then(snap => {
          let tempArtworks: any[] = [];
          snap.docs.forEach(doc => {
            const artwork = Object.assign(doc.data(), { id: doc.id });
            tempArtworks.push(artwork);
          });
          setArtworks(Array.from(tempArtworks));
        });
    }
  }, [artist]);

  return artworks;
}

function useArtworkWithId(artworkId: string): IArtwork | null {
  const [artwork, setArtwork] = useState<IArtwork | null>(null);

  useEffect(() => {
    if (!artworkId) {
      setArtwork(null);
    } else {
      db.collection('artworks')
        .doc(artworkId)
        .get()
        .then(work => {
          const optionalArtwork = work.data();
          if (optionalArtwork) {
            // @ts-ignore
            setArtwork(optionalArtwork);
          }
        })
        .catch(error => console.log(error));
    }
  }, [artworkId]);
  // @ts-ignore
  return { artwork };
}

async function findArtworkById(artworkId: string): Promise<IArtwork | null> {
  if (!artworkId) {
    return null;
  } else {
    try {
      const optionalArtwork = await db
        .collection('artworks')
        .doc(artworkId)
        .get();

      if (optionalArtwork) {
        // @ts-ignore
        return optionalArtwork.data();
      } else {
        return null;
      }
    } catch (err) {
      throw err;
    }
  }
}

export {
  useAssortedArtworks,
  useFeaturedArtworks,
  useArtworksOfType,
  useFilteredArtworks,
  useArtworksByArtist,
  useArtworkWithId,
  findArtworkById,
};
