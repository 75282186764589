import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { search } from '../client/firestoreClient';
import { IArtworkWithArtistName, IArtwork } from '../common-src/types/Artwork';
import { IArtist } from '../common-src/types/Artist';
import { firebaseInstance } from '../firebase-functions';
import { ArtistLink } from './common/ArtistLink';
import { ArtworkPreview } from './common/ArtworkPreview';
import { IImageDataWithMetadata } from '../common-src/types/ImageData';
import { AlertsContext } from '../context/AlertsContext';
import { Intent, Spinner } from '@blueprintjs/core';
import { Helmet } from 'react-helmet';
import { useArtworkGridStyles, useMainStyles } from '../hooks/styleHooks';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchResults: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [artworkResults, setArtworkResults] = useState<IArtwork[]>([]);
  const [artistResults, setArtistResults] = useState<IArtist[]>([]);
  const [
    artworkResultsWithAdditionalData,
    setArtworkResultsWithAdditionalData,
  ] = useState<IArtworkWithArtistName[]>([]);
  const [
    artistResultsWithAdditionalData,
    setArtistResultsWithAdditionalData,
  ] = useState<any[]>([]);
  const query = useQuery();
  const [isSearching, setIsSearching] = useState(false);
  const [dynamicPageTitle, setDynamicPageTitle] = useState(
    'Search Results - The Picture House Gallery'
  );
  const mainStyles = useMainStyles();
  const artworkGridStyles = useArtworkGridStyles();

  const db = firebaseInstance.firestore();

  useEffect(() => {
    const searchTerm = query.get('q');
    if (searchTerm) {
      getSearchResults(searchTerm);
      setDynamicPageTitle(
        `Search Results for ${searchTerm} - The Picture House Gallery`
      );
    }
  }, []);

  const getAdditionalDataForArtists = async (artists: IArtist[]) => {
    let tempArtistsWithImages: any[] = [];
    for (const artist of artists) {
      if (artist.representativeArtworkId) {
        await db
          .collection('artworks')
          .doc(artist.representativeArtworkId)
          .get()
          .then((artworkSnap: any) => {
            const tempWork = artworkSnap.data();
            if (tempWork) {
              tempArtistsWithImages.push(
                Object.assign({}, artist, {
                  previewImageSrc: tempWork.previewUrl,
                  // destinationUrl: `/work/${artworkSnap.id}`,
                  // artworkName: tempWork.title,
                  alt: tempWork.title,
                })
              );
            }
          })
          .catch(error => {
            // TODO: do something
          });
      }
    }
    setArtistResultsWithAdditionalData(Array.from(tempArtistsWithImages));
  };

  const getAdditionalDataForArtworks = async (artworks: IArtwork[]) => {
    let tempArtworksWithAdditionalData: any[] = [];
    for (const artwork of artworks) {
      if (artwork.associatedArtistId) {
        await db
          .collection('artists')
          .doc(artwork.associatedArtistId)
          .get()
          .then((artistSnap: any) => {
            const tempArtist = artistSnap.data() as IArtist;
            if (tempArtist) {
              tempArtworksWithAdditionalData.push(
                Object.assign({}, artwork, {
                  artistFirstName: tempArtist.firstName,
                  artistLastName: tempArtist.lastName,
                })
              );
            }
          })
          .catch(error => {
            // TODO: do something
          });
      }
    }
    setArtworkResultsWithAdditionalData(
      Array.from(tempArtworksWithAdditionalData)
    );
  };

  const getSearchResults = async (searchTerm: string) => {
    setIsSearching(true);
    try {
      const { artworkResults, artistResults } = await search(searchTerm);
      setArtworkResults(artworkResults);
      setArtistResults(artistResults);
      getAdditionalDataForArtists(artistResults);
      getAdditionalDataForArtworks(artworkResults);
    } catch (err) {
      alertsContext.addAlert(
        `There was an unexpected error searching for ${searchTerm}`,
        Intent.DANGER
      );
    } finally {
      setIsSearching(false);
    }
  };

  // TODO_LATER: for artwork and artist results show the first few results, then a link to see all

  const artworkResultsAsElements = artworkResultsWithAdditionalData?.map(
    (artwork: IArtworkWithArtistName, index) => {
      const imageData: IImageDataWithMetadata = {
        previewUrl: artwork.previewUrl,
        previewUrlHeight400: artwork.previewUrlHeight400!,
        previewUrlWidth400: artwork.previewUrlWidth400!,
        destinationUrl: `/work/${artwork.id}`,
        title: artwork.title,
        artistName: `${artwork.artistFirstName} ${artwork.artistLastName}`,
      };
      return (
        <ArtworkPreview
          imageData={imageData}
          distributor={'PICTURE_HOUSE'}
          inImageStrip={false}
          artistName={imageData.artistName}
          showArtistName={true}
          price={artwork.price}
          destinationUrl={`/work/${artwork.id}`}
          priceAlign="left"
          alt={artwork.title}
          shouldShowCaption
          framed
          width="100%"
          key={index}
        />
      );
    }
  );

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{dynamicPageTitle}</title>
      </Helmet>
      <h1 className={mainStyles.pageHeading}>Search</h1>
      {isSearching && <Spinner />}
      {!isSearching && (
        <>
          {' '}
          <h2>Artworks</h2>
          <div className={artworkGridStyles.artworkGrid}>
            {artworkResultsAsElements?.length > 0 ? (
              artworkResultsAsElements
            ) : (
              <div>No artworks matching "{query.get('q')}"</div>
            )}
          </div>
          <h2>Artists</h2>
          <div className={artworkGridStyles.artworkGrid}>
            {artistResultsWithAdditionalData?.length > 0 ? (
              artistResultsWithAdditionalData.map(
                (artiste: any, index: number) => (
                  <div key={index} style={{ marginRight: 10, marginTop: 10 }}>
                    <ArtistLink
                      artist={artiste}
                      representativeImageSrc={artiste.previewImageSrc}
                    />
                  </div>
                )
              )
            ) : (
              <div>No artists matching "{query.get('q')}"</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export { SearchResults };
