import React, { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { IImageDataWithMetadata } from '../../common-src/types/ImageData';
import { CarouselImage } from './CarouselImage';

interface IProps {
  images: IImageDataWithMetadata[];
  slideDuration?: number;
  showOverlayFrame: boolean;
  overlayText?: string;
  useTitleAsOverlayText: boolean;
  imgHeight: number;
  containerHeight: number;
}

const Slideshow: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    images,
    slideDuration,
    showOverlayFrame,
    overlayText,
    useTitleAsOverlayText,
    imgHeight,
    containerHeight,
  } = props;
  const interval = slideDuration || 5000;

  useEffect(() => {
    const { images } = props;
    images.forEach(image => {
      const img = new Image();
      img.src = image.previewUrl!;
    });
  }, [props]);

  return (
    <Carousel fade={true} interval={interval} pause="hover">
      {images.map((img, index) => (
        <Carousel.Item key={`${img.previewUrl}-${index}`}>
          <CarouselImage
            imageSrc={img.fullSizeUrl!}
            destinationUrl={img.destinationUrl}
            artistName={img.artistName!}
            artworkName={img.title!}
            shouldShowCaption={false}
            overlayImage={showOverlayFrame ? '/white-frame.png' : undefined}
            overlayText={useTitleAsOverlayText ? img.title : overlayText}
            alt={img.title!}
            height={imgHeight}
            containerHeight={containerHeight}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export { Slideshow };
