import { Main } from './components/Main';
import { Artists } from './components/Artists';
import { Works } from './components/Works';
import { WorksOfMedium } from './components/WorksOfMedium';
import { Help } from './components/Help';
import { Artwork } from './components/Artwork/Artwork';
import { Artist } from './components/Artist';
import { User } from './components/User';
import { Purchase } from './components/Purchase/Purchase';
import { Admin } from './components/Admin/Admin';
import { ArtistAdmin } from './components/Admin/ArtistAdmin';
import { SignIn } from './components/SignIn';
import { SignUp } from './components/SignUp';
import { SearchResults } from './components/SearchResults';
import { Contact } from './components/Contact';
import { About } from './components/About';

import { routes } from './routes';

interface IRoute {
  path: string;
  component: any;
  isExact: boolean;
  useRender?: boolean;
  data?: any;
}

export const RouteNames = {
  MAIN: 'MAIN',
  ARTISTS: 'ARTISTS',
  WORKS: 'WORKS',
  PAINTINGS: 'PAINTINGS',
  PRINTS: 'PRINTS',
  SCULPTURES: 'SCULPTURES',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  LIGHTBOX: 'LIGHTBOX',
  HELP: 'HELP',
  ARTWORK: 'ARTWORK',
  ARTIST: 'ARTIST',
  USER: 'USER',
  CART: 'CART',
  ADMIN: 'ADMIN',
  ARTISTADMIN: 'ARTISTADMIN',
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  SEARCH: 'SEARCH',
  CONTACT: 'CONTACT',
  ABOUT: 'ABOUT',
};

const RoutesToComponentMap = new Map<any, any>();

RoutesToComponentMap.set(RouteNames.MAIN, { component: Main });
RoutesToComponentMap.set(RouteNames.ARTISTS, { component: Artists });
RoutesToComponentMap.set(RouteNames.WORKS, { component: Works });
RoutesToComponentMap.set(RouteNames.PAINTINGS, undefined);
RoutesToComponentMap.set(RouteNames.PRINTS, undefined);
RoutesToComponentMap.set(RouteNames.SCULPTURES, undefined);
RoutesToComponentMap.set(RouteNames.PHOTOGRAPHY, undefined);
RoutesToComponentMap.set(RouteNames.LIGHTBOX, undefined);
RoutesToComponentMap.set(RouteNames.HELP, { component: Help });
RoutesToComponentMap.set(RouteNames.ARTWORK, { component: Artwork });
RoutesToComponentMap.set(RouteNames.ARTIST, { component: Artist });
RoutesToComponentMap.set(RouteNames.USER, { component: User });
RoutesToComponentMap.set(RouteNames.CART, { component: Purchase });
RoutesToComponentMap.set(RouteNames.ADMIN, { component: Admin });
RoutesToComponentMap.set(RouteNames.ARTISTADMIN, { component: ArtistAdmin });
RoutesToComponentMap.set(RouteNames.SIGNIN, { component: SignIn });
RoutesToComponentMap.set(RouteNames.SIGNUP, { component: SignUp });
RoutesToComponentMap.set(RouteNames.SEARCH, { component: SearchResults });
RoutesToComponentMap.set(RouteNames.CONTACT, { component: Contact });
RoutesToComponentMap.set(RouteNames.ABOUT, { component: About });

export const RouteComponents: IRoute[] = routes.map(route => {
  return {
    ...route,
    component: RoutesToComponentMap.get(route.name),
    isExact: route.name === RouteNames.ADMIN ? false : true,
  };
});
