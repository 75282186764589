import React from 'react';
import { Helmet } from 'react-helmet';
import { useMainStyles } from '../hooks/styleHooks';

const Contact: React.FunctionComponent = () => {
  const mainStyles = useMainStyles();
  return (
    <div className={mainStyles.genericPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - The Picture House Gallery</title>
      </Helmet>
      <h1 className={mainStyles.regularTextStyle}>Contact us</h1>
      <div
        className={`${mainStyles.leftAligned} ${mainStyles.regularTextStyle}`}
        style={{ justifyContent: 'center', display: 'flex' }}
      >
        <div>
          We'd love to hear from you. You can get in touch either by email or by
          phone.
          <ul>
            <li>
              <a href="mailto:admin@thepicturehousegallery.com">
                admin@thepicturehousegallery.com
              </a>
            </li>
            <li>+44 (0) 7985 544 344</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { Contact };
