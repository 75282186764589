import { useEffect, useState } from 'react';
import _ from 'lodash';
import { firebaseInstance } from '../firebase-functions';
import { IArtist } from '../common-src/types/Artist';

function useArtists() {
  const db = firebaseInstance.firestore();
  const [artists, setArtists] = useState<any>(null);
  const [artistsWithImages, setArtistsWithImages] = useState<any>(null);

  useEffect(() => {
    db.collection('artists')
      .get()
      .then(snap => {
        let tempArtists: any[] = [];
        snap.docs.forEach(doc => {
          const artist = Object.assign(doc.data(), { id: doc.id });
          tempArtists.push(artist);
        });
        const artistSortedByLastName = _.sortBy(tempArtists, ['lastName']);
        setArtists(artistSortedByLastName.slice());
        getRepresentativeImagesAndAdd(artistSortedByLastName);
      });
  }, []);

  const getRepresentativeImagesAndAdd = async (artists: IArtist[]) => {
    let tempArtistsWithImages: any[] = [];
    for (const artist of artists) {
      if (artist.representativeArtworkId) {
        await db
          .collection('artworks')
          .doc(artist.representativeArtworkId)
          .get()
          .then(artwork => {
            const tempWork = artwork.data();
            if (tempWork) {
              tempArtistsWithImages.push(
                Object.assign(artist, {
                  previewImageSrc: tempWork.previewUrl,
                  // destinationUrl: `/work/${artwork.id}`,
                  // artworkName: tempWork.title,
                  alt: tempWork.title,
                })
              );
            }
          })
          .catch(err => {
            throw err;
          });
      }
    }
    setArtistsWithImages(Array.from(tempArtistsWithImages));
  };

  return { artists, artistsWithImages };
}

export { useArtists };
