import React from 'react';
import {
  Suggest,
  IItemRendererProps,
  ItemPredicate,
} from '@blueprintjs/select';
import { useArtists } from '../../hooks/artistHooks';
import { IArtist } from '../../common-src/types/Artist';
import { MenuItem } from '@blueprintjs/core';

interface IProps {
  handleValueChange: (artist: IArtist) => void;
}

const ArtistSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { handleValueChange } = props;
  const { artists } = useArtists();

  const renderArtistListItem = (
    item: IArtist,
    itemProps: IItemRendererProps
  ) => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item.id}
        text={`${item.firstName} ${item.lastName}`}
        active={itemProps.modifiers.active}
        disabled={itemProps.modifiers.disabled}
        onClick={itemProps.handleClick}
      />
    );
  };

  const inputValueRenderer = (artist: IArtist) =>
    `${artist.firstName} ${artist.lastName}`;

  const filterArtist: ItemPredicate<IArtist> = (
    query,
    artist,
    _index,
    exactMatch
  ) => {
    const normalisedArtist = `${artist.firstName} ${artist.lastName}`.toLowerCase();
    const normalisedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalisedArtist === normalisedQuery;
    } else {
      return normalisedArtist.indexOf(normalisedQuery) >= 0;
    }
  };

  if (!artists) {
    return null;
  }

  return (
    <Suggest
      // @ts-ignore
      id="artist-name"
      items={artists}
      itemRenderer={renderArtistListItem}
      inputValueRenderer={inputValueRenderer}
      // itemsEqual={areArtistsEqual}
      itemPredicate={filterArtist}
      onItemSelect={handleValueChange}
      fill
    />
  );
};

export { ArtistSelect };
