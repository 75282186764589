import React from 'react';
import { Helmet } from 'react-helmet';
import { useMainStyles } from '../hooks/styleHooks';

const Help: React.FunctionComponent = () => {
  const mainStyles = useMainStyles();
  return (
    <div className={mainStyles.genericPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Help - The Picture House Gallery</title>
      </Helmet>
      <h1 className={mainStyles.regularTextStyle}>Help</h1>
    </div>
  );
};

export { Help };
