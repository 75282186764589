import React, { useState, useEffect, useContext } from 'react';
import { ArtistSelect } from '../common/ArtistSelect';
import { IArtist } from '../../common-src/types/Artist';
import {
  FormGroup,
  InputGroup,
  Button,
  Intent,
  Callout,
  TextArea,
} from '@blueprintjs/core';
import { AlertsContext } from '../../context/AlertsContext';
import { updateArtist } from '../../client/firestoreClient';
import { useArtworksByArtist, findArtworkById } from '../../hooks/artworkHooks';
import { ArtworkSelect } from '../common/ArtworkSelect';
import {
  IArtwork,
  IArtworkWithArtistName,
} from '../../common-src/types/Artwork';
import { ArtworkPreview } from '../common/ArtworkPreview';
import { IImageDataWithMetadata } from '../../common-src/types/ImageData';
import { useMainStyles } from '../../hooks/styleHooks';

const EditArtist: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [selectedArtist, setSelectedArtist] = useState<IArtist | null>(null);
  const [artistFirstName, setArtistFirstName] = useState('');
  const [artistLastName, setArtistLastName] = useState('');
  const [artistBio, setArtistBio] = useState('');
  const [representativeArtworkId, setRepresentativeArtworkId] = useState('');
  const [
    representativeArtwork,
    setRepresentativeArtwork,
  ] = useState<IArtworkWithArtistName | null>(null);
  const [imageData, setImageData] = useState<IImageDataWithMetadata | null>(
    null
  );
  const [isSavingArtist, setIsSavingArtist] = useState(false);
  const mainStyles = useMainStyles();

  useEffect(() => {
    setArtistFirstName(selectedArtist?.firstName || '');
    setArtistLastName(selectedArtist?.lastName || '');
    setArtistBio(selectedArtist?.bio || '');
    setRepresentativeArtworkId(selectedArtist?.representativeArtworkId || '');
    if (selectedArtist?.representativeArtworkId) {
      updateRepresentativeArtwork(selectedArtist?.representativeArtworkId);
    } else {
      setRepresentativeArtwork(null);
    }
  }, [selectedArtist]);

  useEffect(() => {
    if (representativeArtwork) {
      const imageData: IImageDataWithMetadata = {
        previewUrl: representativeArtwork?.previewUrl,
        previewUrlHeight400: representativeArtwork?.previewUrlHeight400!,
        previewUrlWidth400: representativeArtwork.previewUrlWidth400!,
        destinationUrl: `/work/${representativeArtwork.id}`,
        artistName: `${representativeArtwork.artistFirstName} ${representativeArtwork.artistLastName}`,
        title: representativeArtwork.title,
        artworkPrice: representativeArtwork.price,
      };
      setImageData(imageData);
    }
  }, [representativeArtwork]);

  const updateRepresentativeArtwork = async (artworkId: string) => {
    try {
      const work = await findArtworkById(artworkId);
      setRepresentativeArtwork(
        Object.assign(work, {
          artistFirstName: selectedArtist?.firstName!,
          artistLastName: selectedArtist?.lastName!,
        })
      );
    } catch (err) {
      alertsContext.addAlert(
        'Unable to add representative artwork. Please try again later.',
        Intent.DANGER
      );
    }
  };

  const artworksAssociatedWithSelectedArtist = useArtworksByArtist(
    selectedArtist
  );

  const handleSelectedArtistChange = (artist: IArtist) => {
    setSelectedArtist(artist);
  };

  const handleRepresentativeArtworkIdChange = async (
    artwork: IArtworkWithArtistName | IArtwork
  ) => {
    setRepresentativeArtworkId(artwork.id);
    setRepresentativeArtwork(artwork as IArtworkWithArtistName);
  };

  const handleChangeArtistFirstName = (event: any) => {
    setArtistFirstName(event.target.value);
  };

  const handleChangeArtistLastName = (event: any) => {
    setArtistLastName(event.target.value);
  };

  const handleChangeArtistBio = (event: any) => {
    setArtistBio(event.target.value);
  };

  const handleClickUpdateArtist = async () => {
    if (!selectedArtist) {
      return;
    }

    if (!artistFirstName || !artistLastName) {
      alertsContext.addAlert(
        `You need to fill in at least the first name and last name.`,
        Intent.DANGER
      );
      return;
    }

    setIsSavingArtist(true);

    try {
      const res = await updateArtist(
        selectedArtist?.id,
        artistFirstName,
        artistLastName,
        artistBio,
        representativeArtworkId
      );
      setIsSavingArtist(false);
      if (res) {
        alertsContext.addAlert(
          `You've successfully saved changes for artist ${artistFirstName} ${artistLastName}.`,
          Intent.SUCCESS
        );
      }
    } catch (err) {
      alertsContext.addAlert(
        `Unable to save changes for artist ${artistFirstName} ${artistLastName}.`,
        Intent.DANGER
      );
    }
  };

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Edit artist</h2>
      <div className="admin-form-container">
        <div className="admin-form-box">
          <FormGroup
            label="Artist to edit"
            labelFor="artist-name"
            labelInfo="(required)"
          >
            <ArtistSelect handleValueChange={handleSelectedArtistChange} />
          </FormGroup>
        </div>
        {selectedArtist && (
          <div>
            <div className="admin-form-box">
              <FormGroup
                label="The artist's first name"
                labelFor="artist-first-name"
                labelInfo="(required)"
              >
                <InputGroup
                  id="artist-first-name"
                  value={artistFirstName}
                  onChange={handleChangeArtistFirstName}
                />
              </FormGroup>
            </div>
            <div className="admin-form-box">
              <FormGroup
                label="The artist's last name"
                labelFor="artist-last-name"
                labelInfo="(required)"
              >
                <InputGroup
                  id="artist-last-name"
                  value={artistLastName}
                  onChange={handleChangeArtistLastName}
                />
              </FormGroup>
            </div>
            <div className="admin-form-box">
              <FormGroup label="The artist's bio" labelFor="artist-bio">
                <TextArea
                  id="artist-bio"
                  value={artistBio}
                  onChange={handleChangeArtistBio}
                  growVertically={true}
                  large={true}
                  style={{ width: '100%' }}
                  rows={7}
                />
              </FormGroup>
            </div>
            <div className="admin-form-box">
              {artworksAssociatedWithSelectedArtist && (
                <FormGroup
                  label="Representative artwork"
                  labelFor="representative-artwork"
                >
                  <ArtworkSelect
                    artworks={artworksAssociatedWithSelectedArtist}
                    handleValueChange={handleRepresentativeArtworkIdChange}
                  />
                </FormGroup>
              )}
              {representativeArtwork && imageData ? (
                <>
                  <ArtworkPreview
                    imageData={imageData!}
                    distributor={'PICTURE_HOUSE'}
                    inImageStrip={false}
                    height={250}
                    alt={representativeArtwork.title}
                    shouldShowCaption
                    framed
                  />
                </>
              ) : (
                <Callout
                  title="No representative artwork"
                  intent={Intent.WARNING}
                >
                  <span>
                    This artist does not have a representative artwork
                    associated with them. This means that nothing will be shown
                    in artist previews. If you have already uploaded an image
                    you would like to use as the representative image, select it
                    from the list below. Otherwise, upload it then return to
                    this page.
                  </span>
                </Callout>
              )}
            </div>
            <div className="admin-form-box">
              <Button
                onClick={handleClickUpdateArtist}
                intent={Intent.PRIMARY}
                loading={isSavingArtist}
              >
                Update artist
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { EditArtist };
