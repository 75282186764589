import React, { ReactNode, useState, createContext } from 'react';

interface IAuthenticationContext {
  signedIn: boolean;
  setSignedIn: () => void;
  currentUid?: string;
  setCurrentUid: () => void;
  authenticationError?: string;
  setAuthenticationError: () => void;
}

interface IProps {
  children: ReactNode;
}

// @TODO_LATER: remove 'any' in below type
const AuthenticationContext = createContext<IAuthenticationContext | any>({
  signedIn: false,
  setSignedIn: () => {},
  // currentUid?: string;
  setCurrentUid: () => {},
  // authenticationError?: string;
  setAuthenticationError: () => {},
});

const AuthenticationContextProvider: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const [signedIn, setSignedIn] = useState(false);
  const [currentUid, setCurrentUid] = useState<string | null>(null);
  const [authenticationError, setAuthenticationError] = useState<string | null>(
    null
  );

  return (
    <AuthenticationContext.Provider
      value={{
        signedIn,
        setSignedIn,
        currentUid,
        setCurrentUid,
        authenticationError,
        setAuthenticationError,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContext, AuthenticationContextProvider };
