import React, { ReactNode, useState } from 'react';
import { IPriceRange } from '../common-src/types/UtilTypes';

interface IFilterContext {
  priceRange: IPriceRange;
  setPriceRange: () => void;
  mediums: string[];
  setMediums: () => void;
}

// @TODO_LATER: remove 'any' in below type
export const FilterContext = React.createContext<IFilterContext | any>({
  priceRange: { minPrice: undefined, maxPrice: undefined },
  setPriceRange: () => {},
  mediums: [],
  setMediums: () => {},
});

interface Props {
  children?: ReactNode;
}

const FilterContextProvider: React.FunctionComponent = (props: Props) => {
  const [mediums, setMediums] = useState([]);
  const [priceRange, setPriceRange] = useState({
    minPrice: undefined,
    maxPrice: undefined,
  });

  return (
    <FilterContext.Provider
      value={{
        mediums,
        setMediums,
        priceRange,
        setPriceRange,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export { FilterContextProvider };
