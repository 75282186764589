import {
  Callout,
  Intent,
  FormGroup,
  NumericInput,
  Button,
} from '@blueprintjs/core';
import React from 'react';
import { useAdminStyles } from '../../../hooks/styleHooks';
import { IPrintfulProduct } from '../../../common-src/types/Printful';

interface IProps {
  syncProductError: string | null;
  profitMargin: number;
  handleProfitMarginChange: (profitMargin: number) => void;
  syncProduct: IPrintfulProduct | null;
  handleApplyProfitMarginToAll: () => void;
  variantIdToBasePriceMap: Map<number, number> | null;
  handleVariantPriceChange: (
    price: any,
    _string: any,
    target: HTMLInputElement
  ) => void;
  handleAddToPrintful: () => void;
  handleUpdatePrintful: () => void;
  isSavingToPrintful: boolean;
}

const PrintfulIntegration: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    syncProductError,
    profitMargin,
    handleProfitMarginChange,
    syncProduct,
    handleApplyProfitMarginToAll,
    variantIdToBasePriceMap,
    handleVariantPriceChange,
    handleAddToPrintful,
    handleUpdatePrintful,
    isSavingToPrintful,
  } = props;
  const adminClasses = useAdminStyles();
  return (
    <div
      className={adminClasses.adminSection}
      style={{ backgroundColor: '#D5F5E3', borderRadius: 5 }}
    >
      <h3 className="heading-3">Printful integration</h3>
      {syncProductError && (
        <Callout title="No printful data" intent={Intent.WARNING}>
          {syncProductError}
        </Callout>
      )}
      {syncProduct?.sync_variants ? (
        <>
          <div className="admin-form-box">
            <FormGroup label="Common profit margin" labelFor="profit-margin">
              <NumericInput
                id="profit-margin"
                value={profitMargin}
                onValueChange={handleProfitMarginChange}
                buttonPosition="none"
                fill
              />
            </FormGroup>
          </div>
          <div className="admin-form-box">
            <Button onClick={handleApplyProfitMarginToAll}>Apply to all</Button>
          </div>
          {syncProduct!.sync_variants.map(variant => {
            const profit: number = +(
              (+variant.retail_price! ?? 0) -
              variantIdToBasePriceMap!.get(variant.variant_id)!
            ).toFixed(2);

            const profitFormatted = profit.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            });

            const profitTooLow = profit < 10;
            const profitStyle = profitTooLow
              ? { color: 'red' }
              : { color: 'black' };

            return (
              <div
                key={variant.id}
                style={{
                  borderColor: 'darkgrey',
                  borderWidth: 1,
                  borderStyle: 'solid',
                  margin: 10,
                  padding: 10,
                }}
              >
                <div>{variant.name}</div>
                <div>
                  <img
                    width={300}
                    src={
                      variant.files[1]
                        ? variant.files[1].preview_url
                        : variant.files[0].preview_url
                    }
                    alt={variant.name}
                  />
                  <FormGroup
                    label="Print price"
                    labelFor={`artwork-print-price-${variant.id}`}
                  >
                    <NumericInput
                      id={`artwork-print-price-${variant.id}`}
                      data-id={variant.id}
                      value={+variant.retail_price! ?? 0}
                      onValueChange={handleVariantPriceChange}
                      buttonPosition="none"
                    />
                  </FormGroup>
                  <p>{`Price of plain canvas on printful: £${variantIdToBasePriceMap!.get(
                    variant.variant_id
                  )}`}</p>
                  <p style={profitStyle}>{`Profit: £${profitFormatted}${
                    profitTooLow ? ' --- Profit should be at least £10.00' : ''
                  }`}</p>
                  {/* <FormGroup>
                <Checkbox
                  data-id={variant.id}
                  checked={variant.isActive}
                  onChange={handleVariantStatusChange}
                  label="Use on printful"
                />
              </FormGroup> */}
                </div>
              </div>
            );
          })}
          <Button
            intent={Intent.PRIMARY}
            onClick={handleUpdatePrintful}
            loading={isSavingToPrintful}
          >
            Update in printful
          </Button>
        </>
      ) : (
        <Button
          intent={Intent.PRIMARY}
          onClick={handleAddToPrintful}
          loading={isSavingToPrintful}
        >
          Add to printful
        </Button>
      )}
    </div>
  );
};

export { PrintfulIntegration };
