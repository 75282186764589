/**
 * Event types
 */

export const GA_EVENT_LOGIN = 'login';
export const GA_EVENT_SIGNUP = 'sign_up';
export const GA_EVENT_VIEW_PAGE = 'view_page';
export const GA_EVENT_VIEW_ABOUT_PAGE = 'view_about_page';

// other

export const GA_PARAM_LOGIN_TYPE_EMAIL = 'email';
