import _ from 'lodash';
import { firebaseInstance } from '../firebase-functions';
import { IArtwork } from '../common-src/types/Artwork';
import * as JsSearch from 'js-search';
import { IFeaturedImage } from '../common-src/types/FeaturedImage';
import { ITransaction } from '../common-src/types/Transaction';
import { makePostRequest } from '../utils';

const db = firebaseInstance.firestore();

async function addNewArtist(
  firstName: string,
  lastName: string,
  bio: string
): Promise<string> {
  return makePostRequest(
    'addArtist',
    `Could not add ${firstName} ${lastName}`,
    { firstName, lastName, bio },
    'application/json',
    'text'
  );
}

async function updateArtist(
  artistId: string,
  firstName: string,
  lastName: string,
  bio: string,
  representativeArtworkId: string
): Promise<string> {
  return makePostRequest(
    'updateArtist',
    `Could not save changes to ${firstName} ${lastName}`,
    {
      artistId,
      firstName,
      lastName,
      bio,
      representativeArtworkId,
    },
    'application/json',
    'json'
  );
}

async function updateArtwork(
  artworkId: string,
  title: string,
  medium: string,
  description?: string,
  price?: number,
  printPrice?: number
): Promise<string> {
  return makePostRequest(
    'updateArtwork',
    'Could not save changes to this artwork',
    {
      artworkId,
      title,
      description,
      price,
      printPrice,
      medium,
    },
    'application/json',
    'text'
  );
}

/**
 * Retrieves all the featured images.
 */
async function getFeaturedImages(): Promise<IFeaturedImage[]> {
  let featuredImages: IFeaturedImage[] = [];
  const featuredImagesSnap = await db.collection('featuredImages').get();

  if (featuredImagesSnap) {
    featuredImagesSnap.forEach(doc => {
      const image = doc.data();
      featuredImages.push(image as IFeaturedImage);
    });
  }

  return featuredImages;
}

async function updateFeaturedImages(
  newFeaturedImages: IFeaturedImage[]
): Promise<boolean> {
  try {
    return db
      .collection('featuredImages')
      .get()
      .then(async featuredImagesSnap => {
        if (featuredImagesSnap) {
          featuredImagesSnap.docs.forEach(async doc => {
            await doc.ref.delete();
          });
          for (const image of newFeaturedImages) {
            await db.collection('featuredImages').add(image);
          }
          return true;
        } else {
          return false;
        }
      });
  } catch (ex) {
    // console.log(ex);
    throw ex;
  }
}

/**
 * Accepts an array of artwork ids and retrieves the corresponding artworks.
 * @param artworkIds
 */
export const getCorrespondingArtworks = async (
  artworkIds: string[]
): Promise<IArtwork[]> => {
  let featuredArtworks: IArtwork[] = [];
  for (const id of artworkIds) {
    const artworkSnap = await db.collection('artworks').doc(id).get();

    if (artworkSnap) {
      const artworkData = artworkSnap.data() as IArtwork;
      if (artworkData) {
        const artwork = Object.assign({
          previewUrl: artworkData.previewUrl,
          previewUrlWidth400: artworkData.previewUrlWidth400,
          previewUrlHeight400: artworkData.previewUrlHeight400,
          fullSizeUrl: artworkData.fullSizeUrl,
          destinationUrl: `/work/${id}`,
          id,
          price: artworkData.price,
          printPrice: artworkData.printPrice,
          title: artworkData.title,
        });
        featuredArtworks.push(artwork);
      }
    }
  }
  return featuredArtworks;
};

async function updateFeaturedArtworks(
  newFeaturedArtworksIds: string[]
): Promise<boolean> {
  try {
    const featuredArtworkIdsSnap = await db
      .collection('featuredArtworks')
      .get();
    if (featuredArtworkIdsSnap) {
      const docId = featuredArtworkIdsSnap.docs[0].id;
      await db
        .collection('featuredArtworks')
        .doc(docId)
        .set({ artworkIds: newFeaturedArtworksIds });
      return true;
    } else {
      return false;
    }
  } catch (ex) {
    return false;
  }
}

async function search(
  searchTerm: string
): Promise<{ artworkResults: any[]; artistResults: any[] }> {
  let artworkResults: any[] = [];
  let artistResults: any[] = [];

  const allArtworks: any[] = [];
  await db
    .collection('artworks')
    .get()
    .then(allArtworksSnap => {
      allArtworksSnap.docs.forEach(artworkSnap => {
        const artwork = Object.assign(artworkSnap.data(), {
          id: artworkSnap.id,
        });
        allArtworks.push(artwork);
        // TODO_LATER: assign artist name to each artwork. This would be better done with hooks, because we can asynchronously
        // update the state and the UI will keep updating itself as appropriate
      });
    });

  const search = new JsSearch.Search('id');
  search.addIndex('title');
  search.addDocuments(allArtworks);
  artworkResults = search.search(searchTerm);

  const allArtists: any[] = [];
  await db
    .collection('artists')
    .get()
    .then(allArtistsSnap => {
      allArtistsSnap.docs.forEach(artistSnap => {
        const artwork = Object.assign(artistSnap.data(), {
          id: artistSnap.id,
        });
        allArtists.push(artwork);
      });
      const search = new JsSearch.Search('id');
      search.addIndex('firstName');
      search.addIndex('lastName');
      search.addDocuments(allArtists);
      artistResults = search.search(searchTerm);
    });

  return { artworkResults, artistResults };
}

async function getAllTransactions() {
  let transactions: ITransaction[] = [];
  // add .limit(20) to below, but only once we figure out ordering and pagination
  const transactionsSnap = await db.collection('transactions').get();

  if (transactionsSnap) {
    transactionsSnap.forEach(doc => {
      const transaction = doc.data();
      transactions.push(transaction as ITransaction);
    });
  }

  const sortedTransactions = _.reverse(
    _.sortBy(transactions, transaction => transaction.creationDate)
  );

  return sortedTransactions;
}

export {
  addNewArtist,
  updateArtist,
  updateArtwork,
  getFeaturedImages,
  updateFeaturedArtworks,
  updateFeaturedImages,
  search,
  getAllTransactions,
};
