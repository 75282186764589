import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { ITransaction } from '../../common-src/types/Transaction';
import { getAllTransactions } from '../../client/firestoreClient';
import { IAddressAlt } from '../../common-src/types/BillingAndShippingInfo';
import { AlertsContext } from '../../context/AlertsContext';
import { Intent } from '@blueprintjs/core';
import { displayPrice } from '../../utils';
import { useMainStyles } from '../../hooks/styleHooks';

// TODO_LATER: add pagination!

const Transactions: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const mainStyles = useMainStyles();
  useEffect(() => {
    retrieveTransactions();
  }, []);

  const retrieveTransactions = async () => {
    try {
      const transactions = await getAllTransactions();
      setTransactions(transactions);
    } catch (err) {
      alertsContext.addAlert('Unable to retrieve transactions.', Intent.DANGER);
    }
  };

  return (
    <div className={mainStyles.regularTextStyle}>
      <h2 className={mainStyles.pageHeading}>Transactions</h2>
      <div style={{ overflowX: 'scroll' }}>
        {transactions && (
          <table className="bp3-html-table bp3-html-table-striped bp3-interactive bp3-html-table-bordered">
            <thead>
              <tr>
                <th colSpan={3}></th>
                <th colSpan={3}>Neutral costs</th>
                <th>Costs</th>
                <th>Revenue</th>
                <th colSpan={1}>Profit</th>
                <th colSpan={3}>Status</th>
                <th colSpan={2}>Purchase items</th>
                <th colSpan={6}>Shipping address</th>
              </tr>
              <tr>
                <th>Creation date</th>
                <th>Transaction id</th>
                <th>User</th>
                <th>Shipping cost</th>
                <th>Tax</th>
                <th>VAT</th>
                <th>Printful cost</th>
                <th>Subtotal</th>
                <th>Total profit (to be split)</th>
                <th>Status</th>
                <th>Stripe status</th>
                <th>Printful status</th>
                <th>Artist</th>
                <th style={{ minWidth: 400 }}>Items</th>
                <th>Shipping address line 1</th>
                <th>Shipping address line 2</th>
                <th>Shipping address city</th>
                <th>Shipping address state</th>
                <th>Shipping address country</th>
                <th>Shipping address postal code</th>
              </tr>
            </thead>
            <tbody>
              {transactions
                .filter(transaction => transaction.purchaseItems.length > 0)
                .map(transaction => (
                  <tr key={transaction.transactionId}>
                    <td>
                      {moment(transaction.creationDate * 1000).format(
                        'MMM DD, YYYY hh:mm a'
                      ) || null}
                    </td>
                    <td>{transaction.transactionId}</td>
                    <td>---</td>
                    <td>
                      {!!transaction.shippingRate &&
                        displayPrice(transaction.shippingRate, 'gbp', 'en-US')}
                    </td>
                    <td>
                      {!!transaction.tax &&
                        displayPrice(transaction.tax, 'gbp', 'en-US')}
                    </td>
                    <td>
                      {!!transaction.vat &&
                        displayPrice(transaction.vat, 'gbp', 'en-US')}
                    </td>
                    <td>
                      {!!transaction.printfulCost &&
                        displayPrice(transaction.printfulCost, 'gbp', 'en-US')}
                    </td>
                    <td>
                      {!!transaction.subtotal &&
                        displayPrice(transaction.subtotal, 'gbp', 'en-US')}
                    </td>
                    <td style={{ color: 'green', fontWeight: 'bold' }}>
                      {!!transaction.subtotal &&
                        !!transaction.printfulCost &&
                        displayPrice(
                          transaction.subtotal - transaction.printfulCost,
                          'gbp',
                          'en-US'
                        )}
                    </td>
                    <td>{transaction.status}</td>
                    <td>{transaction.stripeStatus || '---'}</td>
                    <td>{transaction.printfulStatus || '---'}</td>
                    <td></td>
                    <td>
                      {transaction.purchaseItems.map((item, index) => {
                        const showBottomBorder =
                          index < transaction.purchaseItems.length - 1;
                        return (
                          <div
                            key={`${transaction.transactionId}-${item.artworkId}-${item.externalIdentifiers.productId}-${item.externalIdentifiers.variantId}`}
                            style={{
                              paddingBottom: 20,
                              borderBottomWidth: showBottomBorder ? 1 : 0,
                              borderBottomStyle: showBottomBorder
                                ? 'dashed'
                                : 'none',
                              marginBottom: showBottomBorder ? 30 : 0,
                            }}
                          >
                            <p>
                              <b>
                                {!!item.artworkName
                                  ? `${item.artworkName}`
                                  : `${item.artworkId}`}
                              </b>
                            </p>

                            <p>Quantity: {item.quantity}</p>
                            <p>Distributor: {item.distributor}</p>
                            {!!item.associatedArtistName && (
                              <p>{`Artist: ${item.associatedArtistName}`}</p>
                            )}
                            {!!item.totalProfitToBeSplit && (
                              <p>
                                {`Profit: ${displayPrice(
                                  item.totalProfitToBeSplit!,
                                  'gbp',
                                  'en-US'
                                )}`}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {(transaction.shippingAddress as IAddressAlt)?.address1}
                    </td>
                    <td>
                      {(transaction.shippingAddress as IAddressAlt)?.address2}
                    </td>
                    <td>
                      {(transaction.shippingAddress as IAddressAlt)?.city}
                    </td>
                    <td>
                      {(transaction.shippingAddress as IAddressAlt)?.state_code}
                    </td>
                    <td>
                      {
                        (transaction.shippingAddress as IAddressAlt)
                          ?.country_code
                      }
                    </td>
                    <td>{(transaction.shippingAddress as IAddressAlt)?.zip}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export { Transactions };
