import React, { useContext, useState, useEffect } from 'react';
import { AuthenticationContext } from '../context/AuthenticationContext';
import { firebaseInstance } from '../firebase-functions';
import { Helmet } from 'react-helmet';
import { useMainStyles } from '../hooks/styleHooks';

const User: React.FunctionComponent = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const db = firebaseInstance.firestore();
  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const mainStyles = useMainStyles();

  useEffect(() => {
    db.collection('users')
      .where('userUID', '==', authenticationContext.currentUid)
      .limit(1)
      .get()
      .then(snap => {
        // @TODO_LATER: find a better way to do it than the below
        snap.docs.forEach(doc => {
          setLoggedInUser(doc.data());
        });
      });
  }, [authenticationContext.currentUid]);

  const isGeneralUser = () => {
    if (loggedInUser.roleId === 3) {
      return true;
    } else {
      return false;
    }
  };

  // @TODO_LATER: improve below check, so it never "wavers"
  if (!authenticationContext) {
    return null;
  }

  const isSignedInAsGeneralUser = loggedInUser && isGeneralUser();

  return (
    <div className={mainStyles.regularTextStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>User home - The Picture House Gallery</title>
      </Helmet>
      <h1>User page</h1>
      <div className={mainStyles.genericPage}>
        {isSignedInAsGeneralUser ? (
          <p>Welcome, user</p>
        ) : (
          <p>You're not signed in as a general user, sorry :-(</p>
        )}
      </div>
    </div>
  );
};

export { User };
