import React, { useState } from 'react';
import { usePurchaseSidebarStyles } from '../../hooks/styleHooks';

interface IProps {
  children: JSX.Element[];
}

const Tabs: React.FunctionComponent<IProps> = (props: IProps) => {
  const { children } = props;
  const [activeTab, setActiveTab] = useState<string>(children[0].props.title);

  const purchaseSidebarStyles = usePurchaseSidebarStyles();

  const onClickTabItem = (tab: any) => {
    setActiveTab(tab);
  };

  interface TabInfo {
    tabName: string;
  }

  const tabInfo: TabInfo[] = children.map(child => {
    return {
      tabName: child.props.title,
    };
  });

  return (
    <div className={purchaseSidebarStyles.tabs}>
      <ol className={purchaseSidebarStyles.tabList}>
        {tabInfo.map(info => {
          const { tabName } = info;

          let className = purchaseSidebarStyles.tabListItem;

          if (activeTab === tabName) {
            className += ` ${purchaseSidebarStyles.tabListActive}`;
          }

          return (
            <li
              className={className}
              onClick={() => onClickTabItem(info.tabName)} // TODO: fix
              key={info.tabName}
            >
              {tabName}
            </li>
          );
        })}
      </ol>
      <div className={purchaseSidebarStyles.tabContent}>
        {children.map(child => {
          if (child.props.title !== activeTab) return undefined;
          return child.props.content;
        })}
      </div>
    </div>
  );
};

export interface ITabProps {
  title: string;
  content: JSX.Element;
}

const Tab: React.FunctionComponent<ITabProps> = (props: ITabProps) => {
  return <>{props.content}</>;
};

export { Tabs, Tab };
