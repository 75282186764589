import { config } from './config';
import { stringify } from 'qs';

export async function makeGetRequest(
  path: string,
  genericErrorMsg: string,
  responseType: 'text' | 'json',
  options?: { query?: object }
) {
  try {
    const queryString = options?.query
      ? stringify(options?.query, { addQueryPrefix: true })
      : '';
    const res = await fetch(`${config.apiUrl}${path}${queryString}`);
    if (res) {
      if (responseType === 'json') {
        const json = await res.json();
        if (json.error) {
          // or use error.message
          throw new Error(genericErrorMsg);
        }
        return json;
      } else if (responseType === 'text') {
        return res.text();
      }
    } else {
      throw new Error(genericErrorMsg);
    }
  } catch (err) {
    throw new Error(genericErrorMsg);
  }
}

export async function makePostRequest(
  path: string,
  genericErrorMsg: string,
  body: object,
  contentType: 'text/plain' | 'application/json',
  responseType: 'text' | 'json'
) {
  try {
    const res = await fetch(`${config.apiUrl}${path}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: new Headers({ 'Content-Type': contentType }),
    });
    if (res.status >= 400) {
      throw new Error(res.status.toString());
    }
    if (res) {
      if (responseType === 'json') {
        return res.json();
      } else if (responseType === 'text') {
        return res.text();
      }
    } else {
      throw new Error(genericErrorMsg);
    }
  } catch (err) {
    throw new Error(genericErrorMsg);
  }
}

export async function makePutRequest(
  path: string,
  genericErrorMsg: string,
  body: object,
  contentType: 'application/json',
  responseType: 'text' | 'json'
) {
  try {
    const res = await fetch(`${config.apiUrl}${path}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: new Headers({ 'Content-Type': contentType }),
    });
    if (res.status >= 400) {
      throw new Error(res.status.toString());
    }
    if (res) {
      if (responseType === 'json') {
        return res.json();
      } else if (responseType === 'text') {
        return res.text();
      }
    } else {
      throw new Error(genericErrorMsg);
    }
  } catch (err) {
    throw new Error(genericErrorMsg);
  }
}

export function handleStripeError() {}

export function handlePrintfulError() {}

export function displayPrice(
  price: number,
  currency: string,
  locale: string
): string {
  // examples of locale: 'de-DE', 'en-US'
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    price
  );
}

export function displayPriceWithoutCurrency(
  price: number,
  locale: string
): string {
  return new Intl.NumberFormat(locale).format(price);
}

export function logHighlighted(message: string) {
  console.log(
    `%c${message}`,
    'background-color: green; border-radius: 3px; padding: 3px;'
  );
}

// TODO_LATER: (possibly) create a decorator function that "wraps" calls to APIs so that they automatically handle all errors without repeating code
