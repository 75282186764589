import React from 'react';
import { useArtworkInfoStyles, useTextStyles } from '../../hooks/styleHooks';

interface IProps {
  description: string;
}

const ArtworkDescription: React.FunctionComponent<IProps> = (props: IProps) => {
  const { description } = props;
  const textStyles = useTextStyles();
  const artworkInfoStyles = useArtworkInfoStyles();
  return (
    <div className={`${textStyles.info} ${textStyles.regularText}`}>
      <h3>Artwork description</h3>
      <div className={artworkInfoStyles.artworkDescription}>{description}</div>
    </div>
  );
};

export { ArtworkDescription };
