import {
  ICreateProductRequestInternal,
  ICreateProductResponse,
  IUpdateProductRequest,
  IUploadImageRequest,
} from '../common-src/types/Printify';
import { makeGetRequest, makePostRequest, makePutRequest } from '../utils';

async function uploadImage(
  uploadImageRequest: IUploadImageRequest
): Promise<any> {
  return makePostRequest(
    'uploadImageToPrintify',
    'Could not upload image to printify',
    uploadImageRequest,
    'application/json',
    'json'
  );
}

async function getProduct(productId: string): Promise<ICreateProductResponse> {
  return makeGetRequest(
    'getProduct',
    'Could not get product from printify',
    'json',
    { query: { productId } }
  );
}

async function createProduct(
  createProductRequest: ICreateProductRequestInternal
): Promise<ICreateProductResponse> {
  return makePostRequest(
    'createProduct',
    'Could not create product in printify',
    createProductRequest,
    'application/json',
    'json'
  );
}

async function updateProduct(
  productId: string,
  updateProductRequest: IUpdateProductRequest
): Promise<any> {
  return makePutRequest(
    'updateProduct',
    'Could not update product in printify',
    { productId, updateProductRequest },
    'application/json',
    'json'
  );
}

async function getVariantsForBlueprintAndPrintProvider(
  blueprintId: number,
  printProviderId: number
) {
  return makeGetRequest(
    'getVariantsForBlueprintAndPrintProvider',
    'Could not get variants from printify',
    'json',
    { query: { blueprint_id: blueprintId, print_provider_id: printProviderId } }
  );
}

async function getBlueprint(blueprintId: number) {
  return makeGetRequest(
    'getBlueprint',
    'Could not get blueprint from printify',
    'json',
    { query: { blueprint_id: blueprintId } }
  );
}

export {
  uploadImage,
  getProduct,
  createProduct,
  updateProduct,
  getVariantsForBlueprintAndPrintProvider,
  getBlueprint,
};
