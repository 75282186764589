import React from 'react';
import { ArtworkPreview } from './ArtworkPreview';
import { ICartItem } from '../../common-src/types/CartItem';
import { IImageData } from '../../common-src/types/ImageData';
import { IDistributor } from '../../common-src/constants/constants';

interface IProps {
  cartItem: ICartItem;
  onRemove?: (
    artworkId: string,
    distributor: IDistributor,
    productId: string | number,
    variantId: string | number
  ) => void;
}

const CartItem: React.FunctionComponent<IProps> = (props: IProps) => {
  const { cartItem, onRemove } = props;

  const handleRemoveFromCart = () => {
    if (onRemove) {
      onRemove(
        cartItem.artwork.id,
        cartItem.distributor,
        cartItem.externalIdentifiers?.productId!,
        cartItem.externalIdentifiers?.variantId!
      );
    }
  };

  const imageData: IImageData = {
    previewUrl: cartItem.artwork.previewUrl,
    previewUrlWidth400: cartItem.artwork.previewUrlWidth400!,
    previewUrlHeight400: cartItem.artwork.previewUrlHeight400!,
    printfulUrl: cartItem.imageUrl,
  };

  return (
    <div
      style={{
        display: 'flex',
        backgroundColor: 'white',
        padding: '20px 20px 15px 20px',
        marginBottom: 20,
      }}
    >
      <div style={{ flex: 1 }}>
        <ArtworkPreview
          imageData={imageData}
          distributor={cartItem.distributor}
          inImageStrip={false}
          height={200}
          alt={cartItem.artwork.title}
          showArtistName={true}
          destinationUrl={`/work/${cartItem.artwork.id}`}
        />
      </div>
      <div style={{ flex: 3, textAlign: 'left', paddingLeft: 20 }}>
        <div>
          <span style={{ fontSize: 14, color: 'darkgrey' }}>
            {cartItem.artwork.title}
          </span>
        </div>
        <div>
          <span style={{ fontSize: 14, color: 'darkgrey' }}>
            £
            {cartItem.purchasePrice.toLocaleString('en-US', {
              minimumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>
      {onRemove && (
        <div style={{ flex: 1, textAlign: 'right' }}>
          <a
            onClick={handleRemoveFromCart}
            style={{ fontSize: 14, color: 'lightsalmon' }}
          >
            Remove from cart
          </a>
        </div>
      )}
    </div>
  );
};

export { CartItem };
