import { Checkbox, InputGroup, Spinner } from '@blueprintjs/core';
import React from 'react';
import {
  IBlueprintWithCheckedState,
  IPrintifyProduct,
} from '../../../common-src/types/Printify';
import { displayPrice, displayPriceWithoutCurrency } from '../../../utils';

interface IProps {
  isIntegrated: boolean;
  genericPrintifyBlueprints: IBlueprintWithCheckedState[];
  handleCheckGenericPrintifyBlueprint: (event: any) => void;
  handleClickPrintifyVariant: (event: any) => void;
  integratedProducts?: IPrintifyProduct[];
  handleChangeVariantRetailPrice: (event: any) => void;
}

/**
 * If this is used in a context where the artwork is not integrated with printify, this will show all supported
 * printify products along with their variants. Each variant will have a preview image (blank), some details (including
 * base price), and a checkbox indicating that the user intends to attach this variant to the artwork.
 *
 * If the artwork IS integrated with printify, this will show all supported printify products and variants as above,
 * but with pre-checked checkboxes representing already integrated variants. Plus, each variant will have our retail price,
 * along with the profit margin.
 */
const PrintifyProductList: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const {
    isIntegrated,
    genericPrintifyBlueprints,
    handleCheckGenericPrintifyBlueprint,
    handleClickPrintifyVariant,
    integratedProducts,
    handleChangeVariantRetailPrice,
  } = props;

  if (isIntegrated) {
    if (integratedProducts?.length) {
      const integratedBlueprintIds = integratedProducts.map(
        prod => prod.blueprint_id
      );
      const unintegratedBlueprints = genericPrintifyBlueprints.filter(
        blueprint => !integratedBlueprintIds.includes(blueprint.id)
      );

      return (
        <>
          <div>
            {integratedProducts.map((product, index) => (
              <div style={{ display: 'flex' }} key={product.id}>
                <div style={{ flex: 1 }}>
                  <h4>{`Product #${index + 1}`}</h4>
                  {product.images?.length ? (
                    <img src={product.images[0].src} width={150} />
                  ) : (
                    <div>
                      Something went wrong with the upload. It's likely that the
                      image resolution is too low. You'll have to go to printify
                      to fix this.
                    </div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <h5>Variants</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Retail price</th>
                        <th>Cost</th>
                        <th>Profit</th>
                        <th>Enabled</th>
                      </tr>
                    </thead>
                    <tbody>
                      {product.variants?.map(variant => (
                        <tr
                          style={{
                            color: variant.is_enabled ? 'blue' : 'grey',
                          }}
                          key={`${product.id}-${variant.id}`}
                        >
                          <td>{variant.title}</td>
                          <td>
                            <InputGroup
                              id="artist-last-name"
                              value={displayPriceWithoutCurrency(
                                variant.price / 100,
                                'en-US'
                              )}
                              onChange={handleChangeVariantRetailPrice}
                              data-variant-id={variant.id}
                              data-product-id={product.id}
                            />
                          </td>
                          <td>{`${displayPrice(
                            variant.cost / 100,
                            'GBP',
                            'en-US'
                          )}`}</td>
                          <td>{`${displayPrice(
                            (variant.price - variant.cost) / 100,
                            'GBP',
                            'en-US'
                          )}`}</td>
                          <td
                            onClick={handleClickPrintifyVariant}
                            data-variant-id={variant.id}
                            data-product-id={product.id}
                          >
                            {variant.is_enabled ? 'Yes' : 'No'}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
          <div>
            <h4>Unintegrated products</h4>
            <div>
              {unintegratedBlueprints.map(blueprint => (
                <div key={blueprint.id}>
                  <h3>{blueprint.title}</h3>
                  <div key={blueprint.images[0]} style={{ margin: 10 }}>
                    <img
                      src={blueprint.images[0]}
                      alt={blueprint.images[0]}
                      width={150}
                    />
                    <Checkbox
                      data-id={blueprint.id}
                      checked={blueprint.checked}
                      onChange={handleCheckGenericPrintifyBlueprint}
                      label="Use on printify"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    } else {
      return <Spinner />;
    }
  } else {
    return (
      <div>
        {genericPrintifyBlueprints.map(blueprint => (
          <div key={blueprint.id}>
            <h3>{blueprint.title}</h3>
            <div key={blueprint.images[0]} style={{ margin: 10 }}>
              <img
                src={blueprint.images[0]}
                alt={blueprint.images[0]}
                width={150}
              />
              <Checkbox
                data-id={blueprint.id}
                checked={blueprint.checked}
                onChange={handleCheckGenericPrintifyBlueprint}
                label="Use on printify"
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
};

export { PrintifyProductList };
