import React, { useContext, useEffect, useState } from 'react';
import { firebaseInstance } from '../../firebase-functions';
import { config } from '../../config';
import {
  Navbar,
  NavbarHeading,
  Button,
  Classes,
  Popover,
  Menu,
  Position,
  Tag,
} from '@blueprintjs/core';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { PurchaseContext } from '../../context/PurchaseContext';
import { UIContext } from '../../context/UIContext';
import { WindowContext } from '../../context/WindowContext';
import { useNavbarStyles } from '../../hooks/styleHooks';

const TopNavbar: React.FunctionComponent = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const purchaseContext = useContext(PurchaseContext);
  const uiContext = useContext(UIContext);
  const windowContext = useContext(WindowContext);
  const navbarStyles = useNavbarStyles();

  const db = firebaseInstance.firestore();

  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [imageToShow, setImageToShow] = useState(
    'https://storage.googleapis.com/picture-house-images/thumbnail/3okW54pXL0TZcO3WBTnv_7c22f98b-9f0a-47ec-a2df-0beee8bf3042.jpg'
  );

  const { cartItems } = purchaseContext;
  const { toggleMobileMenu } = uiContext!;

  // preload navbar images
  useEffect(() => {
    const img1 = new Image();
    const img2 = new Image();
    const img3 = new Image();
    const img4 = new Image();
    const img5 = new Image();
    img1.src =
      'https://storage.googleapis.com/picture-house-images/thumbnail/3okW54pXL0TZcO3WBTnv_7c22f98b-9f0a-47ec-a2df-0beee8bf3042.jpg';
    img2.src =
      'https://storage.googleapis.com/picture-house-images/thumbnail/VGFHRydq6CikNocA6nYP_15081b0e-4b72-40d3-9a96-bf819603c59a.jpg';
    img3.src =
      'https://storage.googleapis.com/picture-house-images/full-size/ctNRYdDovzYuqoEvFdG8_0974595d-4560-4a63-97f4-5409cf215b3b.jpg';
    img4.src =
      'https://storage.googleapis.com/artist-collective-images/full-size/Alexander%20Michael%20-%20IMG_2771.jpg';
    img5.src =
      'https://storage.googleapis.com/picture-house-images/full-size/VGFHRydq6CikNocA6nYP_Pergola.jpg';
  }, []);

  useEffect(() => {
    db.collection('users')
      .where('userUID', '==', authenticationContext.currentUid)
      .limit(1)
      .get()
      .then(snap => {
        // @TODO_LATER: find a better way to do it than the below
        snap.docs.forEach(doc => {
          setLoggedInUser(doc.data());
        });
      });
  }, [authenticationContext.currentUid]);

  const isAdmin = () => {
    if (loggedInUser?.roleId === 1) {
      return true;
    } else {
      return false;
    }
  };

  const firebaseSignOut = () => {
    firebaseInstance
      .auth()
      .signOut()
      .then(() => {
        window.location.href = '/';
      })
      .catch((error: Error) => {
        authenticationContext.setAuthenticationError(error);
      });
  };

  const goToWorks = () => {
    window.location.href = '/works';
  };

  const isSignedInAsAdmin = isAdmin();

  return (
    <Navbar className={navbarStyles.navbarItself}>
      <div className={navbarStyles.navbarLogoContainer}>
        <Link to="/" className={navbarStyles.navLink}>
          <NavbarHeading className={navbarStyles.logo}>
            <img
              src="/navlogo.png"
              width={100}
              height={100}
              style={{ position: 'relative', top: -30 }}
            />
            <div style={{ display: 'inline', position: 'relative', top: -25 }}>
              The Picture House{' '}
            </div>
          </NavbarHeading>
        </Link>
      </div>
      <div className={navbarStyles.navbarCentreLinksGroup}>
        <Link to="/artists">
          <Button
            className={`${Classes.MINIMAL} ${navbarStyles.navbarLink}`}
            text="Artists"
          />
        </Link>
        <Popover
          interactionKind="hover"
          content={
            <div className={navbarStyles.navbarFullWidthMenu}>
              <Menu
                className={navbarStyles.navbarMenu}
                style={{ width: '100%' }}
              >
                <div
                // onMouseEnter={() =>
                //   setImageToShow(
                //     'https://storage.googleapis.com/picture-house-images/thumbnail/3okW54pXL0TZcO3WBTnv_7c22f98b-9f0a-47ec-a2df-0beee8bf3042.jpg'
                //   )
                // }
                >
                  <Menu.Item
                    className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                    text="Paintings"
                    href="/paintings"
                  />
                </div>
                <div
                // onMouseEnter={() =>
                //   setImageToShow(
                //     'https://storage.googleapis.com/picture-house-images/full-size/ctNRYdDovzYuqoEvFdG8_0974595d-4560-4a63-97f4-5409cf215b3b.jpg'
                //   )
                // }
                >
                  <Menu.Item
                    className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                    text="Sculptures"
                    href="/sculptures"
                  />
                </div>
                <div
                // onMouseEnter={() =>
                //   setImageToShow(
                //     'https://storage.googleapis.com/artist-collective-images/full-size/Alexander%20Michael%20-%20IMG_2771.jpg'
                //   )
                // }
                >
                  <Menu.Item
                    className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                    text="Lightbox"
                    href="/lightbox"
                  />
                </div>
                <div
                // onMouseEnter={() =>
                //   setImageToShow(
                //     'https://storage.googleapis.com/picture-house-images/full-size/VGFHRydq6CikNocA6nYP_Pergola.jpg'
                //   )
                // }
                >
                  <Menu.Item
                    className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                    text="Photography"
                    href="/photography"
                  />
                </div>
              </Menu>
              {/* <div
                style={{
                  flex: 3,
                  backgroundColor: 'indianred',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${imageToShow})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '97%',
                    height: '90%',
                  }}
                ></div>
              </div> */}
            </div>
          }
          position={Position.BOTTOM}
        >
          <Button
            className={`${Classes.MINIMAL} ${navbarStyles.navbarLink}`}
            rightIcon="chevron-down"
            text="Works"
            onClick={goToWorks}
          />
        </Popover>
      </div>
      <div className={navbarStyles.navbarRightLinksGroup}>
        <div className={navbarStyles.navbarSearch}>
          <div className="bp3-input-group .modifier" style={{ marginTop: 3 }}>
            <span className="bp3-icon bp3-icon-search"></span>
            <input
              className="bp3-input"
              type="search"
              placeholder="Search"
              dir="auto"
              onKeyDown={event => {
                // @ts-ignore
                const searchTerm = event.target.value;
                if (event.keyCode === 13 && searchTerm) {
                  window.location.href = `/search?q=${searchTerm}`;
                }
              }}
            />
          </div>
        </div>
        <Link to="/cart" className={navbarStyles.navbarCart}>
          <Button
            className={`${Classes.MINIMAL} ${navbarStyles.navbarLink}`}
            large={true}
            icon="shopping-cart"
          />
          {cartItems?.length ? (
            <div style={{ position: 'relative', left: 15, top: -40 }}>
              <Tag
                round={true}
                style={{
                  backgroundColor: 'red',
                  paddingLeft: 4,
                  paddingRight: 4,
                }}
              >
                {cartItems.length}
              </Tag>
            </div>
          ) : null}
        </Link>
        <Popover
          interactionKind="hover"
          content={
            <Menu className={navbarStyles.navbarMenu}>
              {isSignedInAsAdmin ? (
                <Menu.Item
                  className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                  text="Admin page"
                  href="/admin"
                />
              ) : null}
              {authenticationContext?.signedIn ? (
                <Menu.Item
                  className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                  text="Sign out"
                  // icon="logout"
                  onClick={firebaseSignOut}
                />
              ) : (
                <Menu.Item
                  className={`${navbarStyles.navbarLink} ${navbarStyles.navbarMenuLink}`}
                  text="Sign in"
                  href="/signin"
                  // icon="login"
                />
              )}
            </Menu>
          }
          position={Position.BOTTOM}
        >
          <Button
            className={`${Classes.MINIMAL} ${navbarStyles.navbarLink}`}
            icon="user"
            large={true}
          />
        </Popover>
        <div className={navbarStyles.navMenuButtonWrapper}>
          <button
            className={`${navbarStyles.navMenuButton} bp3-button bp3-minimal bp3-icon-menu`}
            onClick={toggleMobileMenu}
          />
        </div>
      </div>
    </Navbar>
  );
};

export { TopNavbar };
