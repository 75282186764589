import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import { config } from './config';

const firebaseInstance = firebase.initializeApp(config.firebase);

export { firebaseInstance };
