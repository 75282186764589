import React from 'react';
import { Helmet } from 'react-helmet';
import { useMainStyles } from '../../hooks/styleHooks';

interface IProps {
  isVisible: boolean;
}

const Confirmation: React.FunctionComponent<IProps> = (props: IProps) => {
  const mainStyles = useMainStyles();
  return (
    <div
      style={{ display: props.isVisible ? 'block' : 'none', paddingTop: 30 }}
      className={mainStyles.regularTextStyle}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Purchase confirmation - The Picture House Gallery</title>
      </Helmet>
      <h1>You're all set.</h1>
      <p>
        You should receive an email within the next few minutes with
        confirmation of your order.
      </p>
      <p>Thank you for shopping with the Picture House Gallery.</p>
    </div>
  );
};

export { Confirmation };
