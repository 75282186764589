import React, { useContext, useEffect, useState } from 'react';
import { FilterContext } from '../context/FilterContext';
import { useFilteredArtworks } from '../hooks/artworkHooks';
import { IArtworkWithArtistName } from '../common-src/types/Artwork';
import { ArtworkPreview } from './common/ArtworkPreview';
import { FilterBox } from './common/FilterBox';
import { ArtworkGrid } from './common/ArtworkGrid';
import { WindowContext } from '../context/WindowContext';
import { IImageDataWithMetadata } from '../common-src/types/ImageData';
import { Helmet } from 'react-helmet';
import { useGenericGridStyles, useMainStyles } from '../hooks/styleHooks';

interface Props {
  medium: string;
  title: string;
}

const WorksOfMedium: React.FunctionComponent<Props> = (props: Props) => {
  const filterContext = useContext(FilterContext);
  const windowContext = useContext(WindowContext);
  const [dynamicPageTitle, setDynamicPageTitle] = useState(
    'Work by our artists - The Picture House Gallery'
  );
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  const { filteredArtworksWithArtistName } = useFilteredArtworks({
    mediums: [props.medium],
    priceRange: filterContext.priceRange,
  });

  useEffect(() => {
    setDynamicPageTitle(
      `Works in category: ${props.medium} - The Picture House Gallery`
    );
  }, [props.medium]);

  const artworksAsElements = filteredArtworksWithArtistName?.map(
    (artwork: IArtworkWithArtistName, index) => {
      const imageData: IImageDataWithMetadata = {
        previewUrl: artwork.previewUrl,
        previewUrlHeight400: artwork.previewUrlHeight400 ?? artwork.previewUrl,
        previewUrlWidth400: artwork.previewUrlWidth400 ?? artwork.previewUrl,
        destinationUrl: `/work/${artwork.id}`,
        title: artwork.title,
        artistName: `${artwork.artistFirstName} ${artwork.artistLastName}`,
      };
      return (
        <ArtworkPreview
          imageData={imageData}
          price={artwork.price}
          distributor={'PICTURE_HOUSE'}
          inImageStrip={false}
          artworkTitle={artwork.title}
          showArtistName={true}
          destinationUrl={`/work/${artwork.id}`}
          artistName={`${artwork.artistFirstName} ${artwork.artistLastName}`}
          alt={artwork.title}
          shouldShowCaption
          framed
          width="100%"
          key={index}
        />
      );
    }
  );

  return (
    <div className={`${mainStyles.genericPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{dynamicPageTitle}</title>
      </Helmet>
      <h1 className={mainStyles.pageHeading}>{props.title}</h1>
      <div style={{ paddingTop: 30 }} className={genericGridStyles.flexGrid}>
        <div className={genericGridStyles.flexGridCol1}>
          <FilterBox />
        </div>
        <div className={genericGridStyles.flexGridCol3}>
          <ArtworkGrid
            elements={artworksAsElements!}
            breakpointCols={windowContext?.isSmall ? 2 : 3}
          />
        </div>
      </div>
    </div>
  );
};

export { WorksOfMedium };
