import React, { useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter, Link } from 'react-router-dom';
import { firebaseInstance } from './firebase-functions';
import { AuthenticationContext } from './context/AuthenticationContext';
import './App.css';
import { TopNavbar } from './components/common/TopNavbar';
import { NavbarMobile } from './components/common/NavbarMobile';
import { UIContext } from './context/UIContext';
import { ScrollToTop } from './components/common/ScrollToTop';
import { config } from './config';
import { AppRouter } from './AppRouter';
import { useGenericGridStyles, useMainStyles } from './hooks/styleHooks';

function App() {
  const authenticationContext = useContext(AuthenticationContext);
  const uiContext = useContext(UIContext);
  const mainStyles = useMainStyles();
  const genericGridStyles = useGenericGridStyles();

  /**
   * Listens to change in auth state so it displays the correct UI for when
   * the user is signed in or not.
   */
  useEffect(() => {
    const cleanup = firebaseInstance.auth().onAuthStateChanged(user => {
      // The observer is also triggered when the user's token has expired and is
      // automatically refreshed. In that case, the user hasn't changed so we should
      // not update the UI.
      if (user && user.uid === authenticationContext.currentUid) {
        return;
      }
      if (user) {
        authenticationContext.setSignedIn(true);
        authenticationContext.setCurrentUid(user.uid);
      } else {
        authenticationContext.setSignedIn(false);
      }
    });

    // @TODO_LATER: make the below work...
    // window.onpopstate = (e: PopStateEvent) => {
    //   authenticationContext.setSignedIn(e.state.signedIn);
    //   authenticationContext.setCurrentUid(e.state.currentUid);
    //   authenticationContext.setAuthenticationError(e.state.authenticationError);
    // };

    return cleanup;
  });

  const stripePromise = loadStripe(config.stripe.publishableKey);

  return (
    <div className="App">
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <ScrollToTop />
          <TopNavbar />
          <NavbarMobile isOpen={uiContext!.isMobileMenuOpen} />
          <AppRouter />
          <div
            style={{
              backgroundColor: 'black',
              height: 200,
              padding: 20,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              className={`${mainStyles.leftAligned} ${genericGridStyles.flexGrid}`}
              style={{ minWidth: 500 }}
            >
              <div
                className={genericGridStyles.flexGridCol1}
                style={{ color: 'white' }}
              >
                <h4>About the Picture House Gallery</h4>
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  <li>
                    <Link to={`/about`}>
                      <span>About us</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={`/contact`}>
                      <span>Contact us</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className={genericGridStyles.flexGridCol1} style={{ color: 'white' }}>
            <h4>For artists</h4>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li>How to sell with us</li>
            </ul>
          </div> */}
            </div>
          </div>
        </Elements>
      </BrowserRouter>
    </div>
  );
}

export default App;
