import {
  ICreateSyncProductRequest,
  IUpdateSyncProductRequest,
} from '../common-src/types/Printful';
import { IShippingRatesRequest } from '../common-src/types/ShippingRates';
import { makeGetRequest, makePostRequest, makePutRequest } from '../utils';
import { IEstimateOrderCostsRequest } from '../common-src/types/Order';

async function getSyncProduct(artworkId: string) {
  return makeGetRequest(
    'getSyncProduct',
    'Could not get sync product',
    'json',
    {
      query: {
        artworkId,
      },
    }
  );
}

// TODO_LATER: get sync variant

async function createSyncProduct(
  createSyncProductRequest: ICreateSyncProductRequest
) {
  return makePostRequest(
    'createSyncProduct',
    'Could not create sync product',
    createSyncProductRequest,
    'application/json',
    'json'
  );
}

async function updateSyncProduct(
  updateSyncProductRequest: IUpdateSyncProductRequest
) {
  return makePutRequest(
    'updateSyncProduct',
    'Could not update sync product',
    updateSyncProductRequest,
    'application/json',
    'json'
  );
}

async function getShippingRatesForOrder(
  shippingRatesRequest: IShippingRatesRequest
) {
  return makePostRequest(
    'getShippingRateForOrder',
    'Could not get shipping rates',
    shippingRatesRequest,
    'application/json',
    'json'
  );
}

async function estimateOrderCosts(
  estimateOrderCostsRequest: IEstimateOrderCostsRequest
) {
  return makePostRequest(
    'estimateOrderCosts',
    'Could not get an estimate of order costs',
    estimateOrderCostsRequest,
    'application/json',
    'json'
  );
}

async function getTaxRate(getTaxRateRequest: any) {
  return makePostRequest(
    'getTaxRate',
    'Could not retrieve tax rate for this order',
    getTaxRateRequest,
    'application/json',
    'json'
  );
}

async function getVariantInformation(id: number) {
  return makeGetRequest(
    'getVariantInformation',
    `Unable to get information on printful item ${id}`,
    'json',
    { query: { id } }
  );
}

export {
  getSyncProduct,
  createSyncProduct,
  updateSyncProduct,
  getShippingRatesForOrder,
  estimateOrderCosts,
  getTaxRate,
  getVariantInformation,
};
