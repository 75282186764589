import { IPurchaseInfoItem } from '../common-src/types/PurchaseInfo';
import { makePostRequest } from '../utils';
import { IAddressAlt } from '../common-src/types/BillingAndShippingInfo';

async function createPaymentIntent(
  purchaseInfoArray: IPurchaseInfoItem[],
  name: string,
  shippingAddress: IAddressAlt
) {
  const paymentIntentRequest = {
    purchaseItems: purchaseInfoArray,
    name,
    shippingAddress,
  };
  return makePostRequest(
    'createPaymentIntent',
    'Could not create payment intent',
    paymentIntentRequest,
    'application/json',
    'json'
  );
}

export { createPaymentIntent };
