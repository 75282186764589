import {
  IPrintArea,
  IPrintifyPrintProvider,
  IPrintifyVariantForRequests,
} from '../types/Printify';

export const PRINTIFY_PRODUCTS = new Map<
  String,
  {
    blueprintId: number;
    userFriendlyName: string;
    associatedPrintProviderId: number;
    variants?: IPrintifyVariantForRequests[];
    printAreas?: IPrintArea[];
  }
>([
  [
    'PREMIUM_MATTE_HORIZONTAL_POSTER',
    {
      blueprintId: 284,
      userFriendlyName: 'Premium Matte horizontal poster',
      associatedPrintProviderId: 2,
      variants: [
        { id: 62100, price: 1, is_enabled: false },
        {
          id: 43163,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43166,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43169,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43172,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43175,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43178,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [62100, 43163, 43166, 43169, 43172, 43175, 43178],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'PREMIUM_MATTE_VERTICAL_POSTER',
    {
      blueprintId: 282,
      userFriendlyName: 'Premium Matte vertical poster',
      associatedPrintProviderId: 2,
      variants: [
        { id: 62103, price: 1, is_enabled: false },
        {
          id: 43135,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43138,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43141,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43144,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43147,
          price: 1,
          is_enabled: false,
        },
        {
          id: 43150,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [62103, 43135, 43138, 43141, 43144, 43147, 43150],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'WOOD_CANVAS',
    {
      blueprintId: 534,
      userFriendlyName: 'Wood canvas',
      associatedPrintProviderId: 60,
      variants: [{ id: 69367, price: 1, is_enabled: false }],
      printAreas: [
        {
          variant_ids: [69367],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'GICLEE_ART_PRINT',
    {
      blueprintId: 494,
      userFriendlyName: 'Giclee art print',
      associatedPrintProviderId: 36,
      variants: [
        { id: 66232, price: 1, is_enabled: false },
        {
          id: 66233,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66037,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66033,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66039,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66043,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66041,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66047,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66045,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66051,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66055,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66053,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66059,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66061,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            66232,
            66233,
            66037,
            66033,
            66039,
            66043,
            66041,
            66047,
            66045,
            66051,
            66055,
            66053,
            66059,
            66061,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'PREMIUM_FRAMED_HORIZONTAL_POSTER',
    {
      blueprintId: 493,
      userFriendlyName: 'Premium framed horizontal poster',
      associatedPrintProviderId: 36,
      variants: [
        { id: 66162, price: 1, is_enabled: false },
        {
          id: 66163,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65414,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65415,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65416,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65417,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66222,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66223,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65420,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65421,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66224,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66225,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65424,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65425,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            66162,
            66163,
            65414,
            65415,
            65416,
            65417,
            66222,
            66223,
            65420,
            65421,
            66224,
            66225,
            65424,
            65425,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'PREMIUM_FRAMED_VERTICAL_POSTER',
    {
      blueprintId: 492,
      userFriendlyName: 'Premium framed vertical poster',
      associatedPrintProviderId: 36,
      variants: [
        { id: 66226, price: 1, is_enabled: false },
        {
          id: 66228,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66227,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66229,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66164,
          price: 1,
          is_enabled: false,
        },
        {
          id: 66165,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65400,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65401,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65402,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65403,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65406,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65407,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65410,
          price: 1,
          is_enabled: false,
        },
        {
          id: 65411,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            66226,
            66228,
            66227,
            66229,
            66164,
            66165,
            65400,
            65401,
            65402,
            65403,
            65406,
            65407,
            65410,
            65411,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'POSTER_EU',
    {
      blueprintId: 443,
      userFriendlyName: 'Poster',
      associatedPrintProviderId: 30,
      variants: [
        { id: 62354, price: 1, is_enabled: false },
        {
          id: 62355,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62346,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62347,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62338,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62339,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62348,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62349,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62342,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62343,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62350,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62351,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62344,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62345,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62352,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62353,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            62354,
            62355,
            62346,
            62347,
            62338,
            62339,
            62348,
            62349,
            62342,
            62343,
            62350,
            62351,
            62344,
            62345,
            62352,
            62353,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'INDOOR_WALL_TAPESTRY',
    {
      blueprintId: 241,
      userFriendlyName: 'Indoor wall tapestry',
      associatedPrintProviderId: 10,
      variants: [
        { id: 46151, price: 1, is_enabled: false },
        {
          id: 41686,
          price: 1,
          is_enabled: false,
        },
        {
          id: 46152,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41687,
          price: 1,
          is_enabled: false,
        },
        {
          id: 46153,
          price: 1,
          is_enabled: false,
        },
        {
          id: 45130,
          price: 1,
          is_enabled: false,
        },
        {
          id: 46154,
          price: 1,
          is_enabled: false,
        },
        {
          id: 61899,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [46151, 41686, 46152, 41687, 46153, 45130, 46154, 61899],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'SQUARE_FRAMED_PREMIUM_GALLERY_WRAP_CANVAS',
    {
      blueprintId: 196,
      userFriendlyName: 'Square framed premium gallery wrap canvas',
      associatedPrintProviderId: 2,
      variants: [
        { id: 41338, price: 1, is_enabled: false },
        {
          id: 41356,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41365,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41374,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41383,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [41338, 41356, 41365, 41374, 41383],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'HORIZONTAL_FRAMED_PREMIUM_GALLERY_WRAP_CANVAS',
    {
      blueprintId: 193,
      userFriendlyName: 'Horizontal framed premium gallery wrap canvas',
      associatedPrintProviderId: 2,
      variants: [
        { id: 41212, price: 1, is_enabled: false },
        {
          id: 41221,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41230,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41239,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41248,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41266,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41257,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41275,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41284,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41293,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41302,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41311,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41320,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41329,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            41212,
            41221,
            41230,
            41239,
            41248,
            41266,
            41257,
            41275,
            41284,
            41293,
            41302,
            41311,
            41320,
            41329,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'VERTICAL_FRAMED_PREMIUM_GALLERY_WRAP_CANVAS',
    {
      blueprintId: 190,
      userFriendlyName: 'Vertical framed premium gallery wrap canvas',
      associatedPrintProviderId: 2,
      variants: [
        { id: 41095, price: 1, is_enabled: false },
        {
          id: 41104,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41113,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41122,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41131,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41140,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41149,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41158,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41167,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41185,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41176,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41194,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41203,
          price: 1,
          is_enabled: false,
        },
        {
          id: 41086,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            41095,
            41104,
            41113,
            41122,
            41131,
            41140,
            41149,
            41158,
            41167,
            41185,
            41176,
            41194,
            41203,
            41086,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'STRETCHED_CANVAS',
    {
      blueprintId: 169,
      userFriendlyName: 'Stretched canvas',
      associatedPrintProviderId: 7,
      variants: [
        { id: 38587, price: 1, is_enabled: false },
        {
          id: 38572,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38590,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38593,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38575,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38578,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38596,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38581,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38599,
          price: 1,
          is_enabled: false,
        },
        {
          id: 38584,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            38587,
            38572,
            38590,
            38593,
            38575,
            38578,
            38596,
            38581,
            38599,
            38584,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'WALL_DECAL',
    {
      blueprintId: 98,
      userFriendlyName: 'Wall decal',
      associatedPrintProviderId: 2,
      variants: [
        { id: 33758, price: 1, is_enabled: false },
        {
          id: 33755,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33759,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33756,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33760,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33757,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [33758, 33755, 33759, 33756, 33760, 33757],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'POSTER',
    {
      blueprintId: 97,
      userFriendlyName: 'Poster',
      associatedPrintProviderId: 2,
      variants: [
        { id: 33748, price: 1, is_enabled: false },
        {
          id: 33741,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33749,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33750,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33742,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33751,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33743,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33752,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33744,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33753,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33745,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33754,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33746,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33747,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            33748,
            33741,
            33749,
            33750,
            33742,
            33751,
            33743,
            33752,
            33744,
            33753,
            33745,
            33754,
            33746,
            33747,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
  [
    'CANVAS_GALLERY_WRAP',
    {
      blueprintId: 50,
      userFriendlyName: 'Canvas gallery wrap',
      associatedPrintProviderId: 2,
      variants: [
        { id: 33726, price: 1, is_enabled: false },
        {
          id: 33721,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33727,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33728,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33722,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33723,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33729,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33724,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33730,
          price: 1,
          is_enabled: false,
        },
        {
          id: 33725,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62258,
          price: 1,
          is_enabled: false,
        },
        {
          id: 62089,
          price: 1,
          is_enabled: false,
        },
      ],
      printAreas: [
        {
          variant_ids: [
            33726,
            33721,
            33727,
            33728,
            33722,
            33723,
            33729,
            33724,
            33730,
            33725,
            62258,
            62089,
          ],
          placeholders: [{ position: 'front', images: [] }],
        },
      ],
    },
  ],
]);

export const printifyPrintProviders = new Map<string, IPrintifyPrintProvider>([
  ['CIRCLE_GRAPHICS', { id: 2, title: 'Circle Graphics' }],
  ['AVMM', { id: 60, title: 'AVMM' }],
  ['PRINT_PIGEONS', { id: 36, title: 'Print Pigeons' }],
  ['OPT_ON_DEMAND', { id: 30, title: 'OPT On Demand' }],
  ['MWW_ON_DEMAND', { id: 10, title: 'MWW On Demand' }],
  ['PRODIGI', { id: 7, title: 'Prodigi' }],
]);
