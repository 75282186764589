import React from 'react';
import {
  useArtistPageStyles,
  useImagePreviewStyles,
} from '../../hooks/styleHooks';

interface IProps {
  backgroundImage?: string;
  overlayText: string;
}

const ArtistMasthead: React.FunctionComponent<IProps> = (props: IProps) => {
  const { backgroundImage, overlayText } = props;
  const imagePreviewStyles = useImagePreviewStyles();
  const artistPageStyles = useArtistPageStyles();

  return (
    <div className={artistPageStyles.artistMasthead}>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          filter: 'blur(3px)',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        }}
      />
      <div
        className={`${imagePreviewStyles.imagePreviewOverlay} ${imagePreviewStyles.imagePreviewOverlayText}`}
      >
        {overlayText}
      </div>
    </div>
  );
};

export { ArtistMasthead };
