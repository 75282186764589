import React, { useContext } from 'react';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { UIContext } from '../../context/UIContext';
import { NavLink, Link } from 'react-router-dom';
import { AuthenticationContext } from '../../context/AuthenticationContext';
import { firebaseInstance } from '../../firebase-functions';

interface IProps {
  isOpen: boolean;
}

const NavbarMobile: React.FunctionComponent<IProps> = (props: IProps) => {
  const uiContext = useContext(UIContext);
  const authenticationContext = useContext(AuthenticationContext);

  const { isOpen } = props;

  const firebaseSignOut = () => {
    firebaseInstance
      .auth()
      .signOut()
      .then(() => {
        window.location.href = '/';
      })
      .catch((error: Error) => {
        authenticationContext.setAuthenticationError(error);
      });
  };

  return (
    <div>
      <SwipeableDrawer
        open={isOpen}
        onOpen={() => uiContext!.toggleMobileMenu()}
        onClose={() => uiContext!.toggleMobileMenu()}
        anchor="right"
        classes={{ paper: 'gp-navbar-mobile' }}
      >
        <Menu style={{ height: '100%', backgroundColor: 'black' }}>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <NavLink to="/artists" onClick={uiContext!.toggleMobileMenu}>
              Artists
            </NavLink>
          </div>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <NavLink to="/works" onClick={uiContext!.toggleMobileMenu}>
              Works
            </NavLink>
          </div>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <div className="bp3-input-group .modifier" style={{ marginTop: 3 }}>
              <span className="bp3-icon bp3-icon-search"></span>
              <input
                className="bp3-input"
                type="search"
                placeholder="Search"
                dir="auto"
                onKeyDown={(event) => {
                  // @ts-ignore
                  const searchTerm = event.target.value;
                  if (event.keyCode === 13 && searchTerm) {
                    uiContext!.toggleMobileMenu();
                    window.location.href = `/search?q=${searchTerm}`;
                  }
                }}
              />
            </div>
          </div>
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <NavLink to="/cart" onClick={uiContext!.toggleMobileMenu}>
              Cart
            </NavLink>
          </div>
          {authenticationContext?.signedIn ? (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Link to="/works" onClick={firebaseSignOut}>
                Sign out
              </Link>
            </div>
          ) : (
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <NavLink to="/signin" onClick={uiContext!.toggleMobileMenu}>
                Sign in
              </NavLink>
            </div>
          )}
        </Menu>
      </SwipeableDrawer>
    </div>
  );
};

export { NavbarMobile };
