import React, { useState, useEffect, useContext } from 'react';
import { Spinner, Intent } from '@blueprintjs/core';
import { Helmet } from 'react-helmet';
import { IImageDataWithMetadata } from '../common-src/types/ImageData';
import { ImageStrip } from './common/ImageStrip';
import { Slideshow } from './common/Slideshow';
import { useArtworksOfType } from '../hooks/artworkHooks';
import { getFeaturedImages } from '../client/firestoreClient';
import { AlertsContext } from '../context/AlertsContext';
import { IFeaturedImage } from '../common-src/types/FeaturedImage';
import { Link } from 'react-router-dom';
import { useImageStripStyles, useMainStyles } from '../hooks/styleHooks';

const Main: React.FunctionComponent = () => {
  const alertsContext = useContext(AlertsContext);
  const [featuredImages, setFeaturedImages] = useState<IFeaturedImage[] | null>(
    null
  );
  const [isSlideshowLoading, setIsSlideshowLoading] = useState(false);
  const paintings = useArtworksOfType('painting', 6).artworksWithArtistName;
  const photographs = useArtworksOfType('photograph', 6).artworksWithArtistName;
  const mainStyles = useMainStyles();
  const imageStripStyles = useImageStripStyles();

  useEffect(() => {
    retrieveImages();
  }, []);

  const retrieveImages = async () => {
    setIsSlideshowLoading(true);
    try {
      const res = await getFeaturedImages();
      if (res) {
        setFeaturedImages(
          res
            .filter(img => img.isEnabled)
            .map(img => {
              return {
                id: img.id,
                fullSizeUrl: img.previewUrl,
                previewUrl: img.previewUrl,
                previewUrlWidth400: img.previewUrl,
                previewUrlHeight400: img.previewUrl,
                overlayText: 'The Picture House Gallery',
              };
            })
        );
      }
      setIsSlideshowLoading(false);
    } catch (err) {
      alertsContext.addAlert(
        'Unable to retrieve featured images',
        Intent.DANGER
      );
    }
  };

  const paintingImages: IImageDataWithMetadata[] = paintings?.map(painting => ({
    previewUrl: painting.previewUrl,
    previewUrlHeight400: painting.previewUrlHeight400!,
    previewUrlWidth400: painting.previewUrlWidth400!,
    previewImageSrc: painting.previewUrl,
    destinationUrl: `/work/${painting.id}`,
    artistName: `${painting.artistFirstName} ${painting.artistLastName}`,
    artworkName: painting.title,
    artworkPrice: painting.price,
    title: painting.title,
    alt: painting.title,
  }))!;

  const photographyImages: IImageDataWithMetadata[] = photographs?.map(
    photo => ({
      fullSizeUrl: photo.fullSizeUrl,
      previewUrl: photo.previewUrl,
      previewUrlHeight400: photo.previewUrlHeight400!,
      previewUrlWidth400: photo.previewUrlWidth400!,
      previewImageSrc: photo.fullSizeUrl,
      destinationUrl: `/work/${photo.id}`,
      alt: photo.title,
      artistName: `${photo.artistFirstName} ${photo.artistLastName}`,
      artworkName: photo.title,
      artworkPrice: photo.price,
      title: photo.title,
    })
  )!;

  return (
    <div className={`${mainStyles.mainPage} ${mainStyles.regularTextStyle}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Welcome to the Picture House Gallery</title>
      </Helmet>
      {isSlideshowLoading && (
        <div
          style={{
            height: 700,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
      {!!featuredImages && (
        <div style={{ height: 700 }}>
          <Slideshow
            images={featuredImages}
            slideDuration={4000}
            showOverlayFrame={true}
            imgHeight={700}
            containerHeight={700}
            overlayText={'The Picture House'}
            useTitleAsOverlayText={false}
          />
        </div>
      )}
      <div
        style={{
          height: 675,
          padding: '110px 20px 110px 20px',
        }}
      >
        {!!paintingImages && (
          <>
            <Link to="/paintings">
              <h3
                className={mainStyles.leftAligned}
                style={{ marginLeft: 135, marginBottom: 45 }}
              >
                Our paintings
              </h3>
            </Link>
            <div className={imageStripStyles.imageStripWrapper}>
              <ImageStrip images={paintingImages} showArtistName={true} />
            </div>
          </>
        )}
      </div>
      <div>
        {!!photographyImages && (
          <div style={{ height: 575 }}>
            <div>
              <Link to="/photography">
                <h3
                  className={mainStyles.leftAligned}
                  style={{ marginLeft: 155, marginBottom: 45 }}
                >
                  Our photography
                </h3>
              </Link>
            </div>
            <Slideshow
              images={photographyImages}
              slideDuration={4000}
              showOverlayFrame={false}
              imgHeight={500}
              containerHeight={500}
              useTitleAsOverlayText={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { Main };
