import React, { ReactNode, useState, useEffect } from 'react';
import { ICartItem } from '../common-src/types/CartItem';
import { getCorrespondingArtworks } from '../client/firestoreClient';
import { IBillingAndShippingInfo } from '../common-src/types/BillingAndShippingInfo';
import { getSyncProduct } from '../client/printfulClient';
import { ISyncProductResponse } from '../common-src/types/Printful';
import { infoToVariantIdMap } from '../common-src/constants/printful';
import { IShippingRatesResultItem } from '../common-src/types/ShippingRates';
import { IEstimateOrderCostsResponseData } from '../common-src/types/Order';
import { IDistributor } from '../common-src/constants/constants';
import {
  getPrintifyVariantPrice,
  getPrintifyVariantPriceFromCartItem,
} from '../util/printify-utils';
import { getProduct } from '../client/printifyClient';

const LOCAL_STORAGE_PREFIX = 'artwork-';

interface IPurchaseContext {
  cartItems: ICartItem[];
  subtotal: number;
  addToCart: (artwork: ICartItem) => void;
  removeFromCart: (
    artworkId: string,
    distributor: IDistributor,
    productId: string | number,
    variantId: string | number
  ) => ICartItem | null;
  clearCart: () => void;
  /** 0 = cart, 1 = collect payment and shipping details, 2 = review, 3 = confirmation */
  stage: number;
  setStage: (stage: number) => void;
  clientSecret: string;
  setClientSecret: (clientSecret: string) => void;
  billingAndShippingInfo: IBillingAndShippingInfo | null;
  setBillingAndShippingInfo: (
    billingAndShippingInfo: IBillingAndShippingInfo | null
  ) => void;
  shippingRate: IShippingRatesResultItem | null;
  setShippingRate: (shippingRate: IShippingRatesResultItem | null) => void;
  taxRate: number | null;
  setTaxRate: (taxRate: number) => void;
  orderCostsEstimate: IEstimateOrderCostsResponseData | null;
  setOrderCostsEstimate: (estimate: IEstimateOrderCostsResponseData) => void;
  total: number;
  setTotal: (total: number) => void;
  transactionId: string;
  setTransactionId: (transactionId: string) => void;
}

export const PurchaseContext = React.createContext<IPurchaseContext>({
  cartItems: [],
  subtotal: 0,
  addToCart: (artwork: ICartItem) => {},
  removeFromCart: (
    artworkId: string,
    distributor: IDistributor,
    productId: string | number,
    variantId: string | number
  ) => null,
  clearCart: () => null,
  stage: 0,
  setStage: (stage: number) => {},
  clientSecret: '',
  setClientSecret: (clientSecret: string) => {},
  billingAndShippingInfo: null,
  setBillingAndShippingInfo: (
    billingAndShippingInfo: IBillingAndShippingInfo | null
  ) => {},
  shippingRate: null,
  setShippingRate: (shippingRate: IShippingRatesResultItem | null) => {},
  taxRate: null,
  setTaxRate: (taxRate: number) => {},
  orderCostsEstimate: null,
  setOrderCostsEstimate: (estimate: IEstimateOrderCostsResponseData) => {},
  total: 0,
  setTotal: (total: number) => {},
  transactionId: '',
  setTransactionId: (transactionId: string) => {},
});

interface Props {
  children?: ReactNode;
}

const PurchaseContextProvider: React.FunctionComponent = (props: Props) => {
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [stage, setStage] = useState(0);
  const [clientSecret, setClientSecret] = useState('');
  const [
    billingAndShippingInfo,
    setBillingAndShippingInfo,
  ] = useState<IBillingAndShippingInfo | null>(null);
  const [
    shippingRate,
    setShippingRate,
  ] = useState<IShippingRatesResultItem | null>(null);
  const [taxRate, setTaxRate] = useState<number | null>(null);
  const [
    orderCostsEstimate,
    setOrderCostsEstimate,
  ] = useState<IEstimateOrderCostsResponseData | null>(null);
  const [transactionId, setTransactionId] = useState<string>('');

  useEffect(() => {
    // IMPORTANT NOTE: once the information has been retrieved from local storage, PurchaseContext becomes the source of truth
    // for cart information
    populateArtworksFromLocalStorage();
  }, []);

  useEffect(() => {
    if (cartItems?.length) {
      const allPrices = cartItems.map(artwork => artwork.purchasePrice);
      const sum = allPrices.reduce(
        (accumulator, currentValue) => accumulator + currentValue
      );
      setSubtotal(sum);
      if (orderCostsEstimate) {
        const sumWithShipping =
          sum +
          +orderCostsEstimate.costs.shipping +
          +orderCostsEstimate.costs.tax +
          +orderCostsEstimate.costs.vat;
        setTotal(sumWithShipping);
      }
    }
  }, [cartItems, orderCostsEstimate]);

  // TODO: test this!!!
  const populateArtworksFromLocalStorage = async () => {
    const localStorageKeys = Object.keys(localStorage)
      .filter(key => key.startsWith(LOCAL_STORAGE_PREFIX))
      .map(key => key.substring(LOCAL_STORAGE_PREFIX.length));

    const itemIds = localStorageKeys.map(key => {
      const keyParts = key.split('-');
      return keyParts[0];
    });

    try {
      const correspondingArtworks = await getCorrespondingArtworks(itemIds);
      let items: ICartItem[] = [];
      for (const artwork of correspondingArtworks) {
        const index = correspondingArtworks.indexOf(artwork);
        const cartItem = JSON.parse(
          localStorage.getItem(
            `${LOCAL_STORAGE_PREFIX}${localStorageKeys[index]}`
          )!
        ) as ICartItem;
        let purchasePrice: number = 0;
        let imageUrl = '';
        if (cartItem.distributor === 'PRINTFUL') {
          // get relevant price info from printful (i.e. override the stored value as it may be outdated)
          try {
            const sp: ISyncProductResponse = await getSyncProduct(
              `@${artwork.id}`
            );
            const matchingPrintfulVariant = sp.result.sync_variants?.find(
              variant =>
                variant.product.variant_id ===
                cartItem.externalIdentifiers?.variantId
            );
            purchasePrice = +matchingPrintfulVariant?.retail_price!;
            imageUrl =
              matchingPrintfulVariant?.files[1].preview_url ??
              artwork.fullSizeUrl;
          } catch (err) {
            throw new Error(err);
          }
        } else if (cartItem.distributor === 'PRINTIFY') {
          try {
            const blerg = await getProduct(
              cartItem.externalIdentifiers?.productId.toString()!
            );
          } catch (err) {}

          purchasePrice = getPrintifyVariantPriceFromCartItem(cartItem);
          imageUrl = cartItem.imageUrl!;
          // TODO
        } else if (cartItem.distributor === 'PICTURE_HOUSE') {
          purchasePrice = artwork.price!;
          // TODO
        }

        items.push(
          Object.assign(artwork, cartItem, {
            purchasePrice,
            quantity: 1,
            imageUrl,
          })
        );
      }
      setCartItems(items);
    } catch (err) {
      throw new Error('Unable to retrieve artworks');
    }
  };

  // TODO: test this!!!
  const addToCart = (cartItem: ICartItem) => {
    let currentCart = Array.from(cartItems);
    currentCart.push(cartItem);
    setCartItems(currentCart);
    localStorage.setItem(
      `${LOCAL_STORAGE_PREFIX}${cartItem.artwork.id}-${cartItem.distributor}-${cartItem.externalIdentifiers?.productId}-${cartItem.externalIdentifiers?.variantId}`,
      JSON.stringify(cartItem)
    );
  };

  // TODO: test this!!!
  const removeFromCart = (
    artworkId: string,
    distributor: IDistributor,
    productId: string | number,
    variantId: string | number
  ): ICartItem => {
    const updatedCart = cartItems.filter(
      cartItem =>
        !(
          cartItem.artwork.id === artworkId &&
          cartItem.distributor === distributor &&
          cartItem.externalIdentifiers?.productId === productId &&
          cartItem.externalIdentifiers?.variantId === variantId
        )
    );
    setCartItems(updatedCart);
    localStorage.removeItem(
      `${LOCAL_STORAGE_PREFIX}${artworkId}-${distributor}-${productId}-${variantId}`
    );
    const correspondingArtwork = cartItems.find(
      cartItem => cartItem.artwork.id === artworkId
    );

    return correspondingArtwork!;
  };

  // TODO: test this!!!
  const clearCart = () => {
    setCartItems([]);
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i)?.startsWith(LOCAL_STORAGE_PREFIX)) {
        localStorage.removeItem(localStorage.key(i)!);
      }
    }
  };

  return (
    <PurchaseContext.Provider
      value={{
        cartItems,
        subtotal,
        addToCart,
        removeFromCart,
        clearCart,
        stage,
        setStage,
        clientSecret,
        setClientSecret,
        billingAndShippingInfo,
        setBillingAndShippingInfo,
        shippingRate,
        setShippingRate,
        taxRate,
        setTaxRate,
        orderCostsEstimate,
        setOrderCostsEstimate,
        total,
        setTotal,
        transactionId,
        setTransactionId,
      }}
    >
      {props.children}
    </PurchaseContext.Provider>
  );
};

export { PurchaseContextProvider };
