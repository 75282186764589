import React from 'react';
import { ArtworkPreview } from './ArtworkPreview';
import { IImageDataWithMetadata } from '../../common-src/types/ImageData';
import {
  useImagePreviewStyles,
  useImageStripStyles,
} from '../../hooks/styleHooks';

interface IProps {
  images: IImageDataWithMetadata[];
  showArtistName: boolean;
  makeImagesLinkable?: boolean;
  destinationUrl?: string;
}

// @TODO_LATER: images should "fade" as they get close to the edge of the ImageStrip to indicate that there is more to see.

const ImageStrip: React.FunctionComponent<IProps> = (props: IProps) => {
  const { images, showArtistName, makeImagesLinkable = true } = props;
  const imageStripStyles = useImageStripStyles();
  const imagePreviewStyles = useImagePreviewStyles();
  return (
    <div className={imageStripStyles.imageStrip}>
      {images.map((image: IImageDataWithMetadata, index: number) => (
        <div
          className={imagePreviewStyles.imagePreviewWrapper}
          key={`${image.previewUrlHeight400 || image.previewUrl}-${index}`}
        >
          <ArtworkPreview
            distributor={'PICTURE_HOUSE'}
            imageData={image}
            inImageStrip={true}
            destinationUrl={
              makeImagesLinkable ? image.destinationUrl : undefined
            }
            artworkTitle={image.title}
            showArtistName={showArtistName}
            artistName={
              showArtistName && image.artistName ? image.artistName : ''
            }
            priceAlign="left"
            shouldShowCaption={true}
            alt={image.title!}
            height={270}
            framed={true}
            price={image.artworkPrice}
          />
        </div>
      ))}
    </div>
  );
};

export { ImageStrip };
