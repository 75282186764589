import React, { ReactNode, useEffect, useState } from 'react';

interface WindowContextType {
  isMobile: boolean;
  isSmall: boolean;
  screenWidth: number;
}

export const WindowContext = React.createContext<WindowContextType | null>(
  null
);

interface Props {
  children?: ReactNode;
}

const WindowContextProvider: React.FunctionComponent = (props: Props) => {
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    const shouldBeMobile = determineIfMobile();
    setIsMobile(shouldBeMobile);
    const shouldBeSmall = determineIfSmall();
    setIsSmall(shouldBeSmall);
    setScreenWidth(window.innerWidth);
  };

  const determineIfMobile = () => {
    const width = window.innerWidth;
    if (width <= 480) {
      return true;
    } else {
      return false;
    }
  };

  const determineIfSmall = () => {
    const width = window.innerWidth;
    if (width <= 700) {
      return true;
    } else {
      return false;
    }
  };

  const [isMobile, setIsMobile] = useState(determineIfMobile());
  const [isSmall, setIsSmall] = useState(determineIfSmall());
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <WindowContext.Provider
      value={{
        isMobile,
        isSmall,
        screenWidth,
      }}
    >
      {props.children}
    </WindowContext.Provider>
  );
};

export { WindowContextProvider };
