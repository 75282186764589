import React from 'react';
import {
  IItemRendererProps,
  ItemPredicate,
  Suggest,
} from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';
import {
  IArtwork,
  IArtworkWithArtistName,
} from '../../common-src/types/Artwork';

interface IProps {
  handleValueChange: (artwork: IArtwork | IArtworkWithArtistName) => void;
  artworks: IArtwork[];
}

const ArtworkSelect: React.FunctionComponent<IProps> = (props: IProps) => {
  const { artworks, handleValueChange } = props;

  const renderArtworkListItem = (
    item: IArtwork,
    itemProps: IItemRendererProps
  ) => {
    if (!itemProps.modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={item.id}
        text={`${item.title}`}
        active={itemProps.modifiers.active}
        disabled={itemProps.modifiers.disabled}
        onClick={itemProps.handleClick}
      />
    );
  };

  const inputValueRenderer = (artwork: IArtwork) => `${artwork.title}`;

  const filterArtwork: ItemPredicate<IArtwork> = (
    query,
    artwork,
    _index,
    exactMatch
  ) => {
    const normalisedArtwork = `${artwork.title}`.toLowerCase();
    const normalisedQuery = query.toLowerCase();

    if (exactMatch) {
      return normalisedArtwork === normalisedQuery;
    } else {
      return normalisedArtwork.indexOf(normalisedQuery) >= 0;
    }
  };

  if (!artworks) {
    return null;
  }

  return (
    <Suggest
      // @ts-ignore
      id="artwork-name"
      items={artworks}
      itemRenderer={renderArtworkListItem}
      inputValueRenderer={inputValueRenderer}
      itemPredicate={filterArtwork}
      onItemSelect={handleValueChange}
      fill
    />
  );
};

export { ArtworkSelect };
