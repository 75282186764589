import React, { ReactNode, useEffect } from 'react';
import { Toaster, Position, Intent } from '@blueprintjs/core';

export const AlertsContext = React.createContext({
  addAlert: (message: string, intent: Intent) => {},
});

interface Props {
  children?: ReactNode;
}

export const AlertToaster = Toaster.create({
  className: 'alert-toaster',
  position: Position.BOTTOM_RIGHT,
});

const AlertsContextProvider: React.FunctionComponent = (props: Props) => {
  const addAlert = (message: string, intent: Intent) => {
    AlertToaster.show({ message, intent });
  };

  useEffect(() => {
    const handleUnidentifiedError = (event: {
      promise: Promise<any>;
      reason: string;
    }) => {
      if (event.reason) {
        addAlert(event.reason.toString(), Intent.DANGER);
      } else {
        addAlert('An identified error has occurred', Intent.DANGER);
      }
    };
    window.addEventListener('unhandledrejection', handleUnidentifiedError);
    return () =>
      window.removeEventListener('unhandledrejection', handleUnidentifiedError);
  }, []);

  return (
    <AlertsContext.Provider
      value={{
        addAlert,
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};

export { AlertsContextProvider };
