import React from 'react';
import { Link } from 'react-router-dom';
import { IDistributor } from '../../common-src/constants/constants';
import { IImageData } from '../../common-src/types/ImageData';
import { useImagePreviewStyles } from '../../hooks/styleHooks';

interface IProps {
  imageData: IImageData;
  distributor: IDistributor;
  price?: number;
  inImageStrip: boolean;
  artworkTitle?: string;
  showArtistName?: boolean;
  destinationUrl?: string;
  height?: number | string;
  width?: number | string;
  artistName?: string;
  priceAlign?: 'left' | 'right';
  shouldShowCaption?: boolean;
  overlayText?: string;
  alt: string;
  framed?: boolean;
}

const ArtworkPreview: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    imageData,
    distributor,
    price,
    inImageStrip,
    artworkTitle,
    showArtistName,
    destinationUrl,
    height,
    width,
    shouldShowCaption,
    overlayText,
    artistName,
    priceAlign,
    alt,
    framed,
  } = props;

  const imagePreviewStyles = useImagePreviewStyles();

  const className = framed ? imagePreviewStyles.imagePreviewFrame : '';
  let theImageItself;

  if (distributor === 'PICTURE_HOUSE') {
    theImageItself = (
      <div
        className={className}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <img
          src={
            inImageStrip
              ? imageData.previewUrlHeight400 || imageData.previewUrl
              : imageData.previewUrlWidth400 || imageData.previewUrl
          }
          height={height}
          width={width}
          alt={alt}
        />
        {overlayText && (
          <div
            className={`${imagePreviewStyles.imagePreviewOverlay} ${imagePreviewStyles.imagePreviewOverlayText}`}
          >
            {overlayText}
          </div>
        )}
      </div>
    );
  } else if (distributor === 'PRINTFUL' || distributor === 'PRINTIFY') {
    theImageItself = (
      <div
        className={className}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
      >
        <img
          src={imageData.printfulUrl}
          height={height}
          width={width}
          alt={alt}
        />
        {overlayText && (
          <div
            className={`${imagePreviewStyles.imagePreviewOverlay} ${imagePreviewStyles.imagePreviewOverlayText}`}
          >
            {overlayText}
          </div>
        )}
      </div>
    );
  }

  return (
    <div style={{ display: 'inline-block' }}>
      {destinationUrl ? (
        <Link to={destinationUrl}>{theImageItself}</Link>
      ) : (
        <>{theImageItself}</>
      )}
      {shouldShowCaption && (
        <>
          <div style={{ marginTop: 10, textAlign: 'left' }}>
            <span>
              <em>{artworkTitle}</em>
            </span>
          </div>
          {showArtistName && artistName && price ? (
            <div
              style={{
                fontSize: 12,
                textAlign: 'left',
              }}
            >
              {artistName && (
                <>
                  <div>
                    <span style={{ color: 'darkgrey' }}>{artistName}</span>
                  </div>
                  <div>
                    <span>
                      {' '}
                      £
                      {`${price.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`}
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : null}
          {!showArtistName && price ? (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  flex: 1,
                  fontSize: 12,
                  color: 'darkgrey',
                  textAlign: priceAlign || 'right',
                }}
              >
                <span>
                  £
                  {price.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export { ArtworkPreview };
