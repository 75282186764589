import { IArtworkProductVariant } from '../common-src/types/ArtworkProduct';
import { ICartItem } from '../common-src/types/CartItem';

function getPrintifyVariantPrice(
  printifyArtworkProductVariant: IArtworkProductVariant
): number {
  return printifyArtworkProductVariant.printifyVariant?.price ?? 0;
}

function getPrintifyVariantPriceFromCartItem(cartItem: ICartItem): number {
  return cartItem.purchasePrice;
}

export { getPrintifyVariantPrice, getPrintifyVariantPriceFromCartItem };
