import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { logGAEvent } from '../client/gaClient';
import { GA_EVENT_VIEW_ABOUT_PAGE } from '../ga';
import { useMainStyles, useTextStyles } from '../hooks/styleHooks';

const About: React.FunctionComponent = () => {
  useEffect(() => {}, []);
  logGAEvent(GA_EVENT_VIEW_ABOUT_PAGE);
  const mainStyles = useMainStyles();
  const textStyles = useTextStyles();

  return (
    <div className={mainStyles.genericPage}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About - The Picture House Gallery</title>
      </Helmet>
      <h1 className={mainStyles.regularTextStyle}>
        About the Picture House Gallery
      </h1>
      <div className={textStyles.galleryCardWrapper}>
        <div
          className={`${textStyles.galleryCard} ${textStyles.info} ${textStyles.regularText}`}
        >
          <p>
            The Picture House Gallery is an artist community aimed at organising
            group exhibitions and promoting some of the finest, most unusual
            artworks available today.
          </p>
          <p>
            Our artists cover a wide variety of styles, from the surreal to the
            grotesque, but what unites them is an eye to the strangeness of the
            contemporary moment. All of them have a distinctly unique
            perspective that challenges as much as it delights.
          </p>
          <p></p>
          <h3>For artists</h3>
          <p>
            At the Picture House Gallery, we believe that putting artists first
            is better for everyone — artist and art-lover alike. That's why,
            while most galleries charge as much as 50% commission on a sale of
            an original artwork, we take only 25%.
          </p>
          <p>
            Please <Link to="/contact">contact us</Link> to inquire about
            featuring your work.
          </p>
        </div>
      </div>
    </div>
  );
};

export { About };
