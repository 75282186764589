import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { WorksOfMedium } from './components/WorksOfMedium';
import { RouteComponents } from './routes-to-component-map';

const AppRouter: React.FunctionComponent = () => {
  const typedRoutes = RouteComponents;
  return (
    <Switch>
      {typedRoutes.map(route => {
        return (
          <Route
            key={route.path}
            exact={route.isExact}
            path={route.path}
            component={route.component ? route.component.component : undefined}
            render={
              route.useRender
                ? () => (
                    <WorksOfMedium
                      medium={route.data.medium}
                      title={route.data.title}
                    />
                  )
                : undefined
            }
          />
        );
      })}
    </Switch>
  );
};

export { AppRouter };
