const RouteNames = {
  MAIN: 'MAIN',
  ARTISTS: 'ARTISTS',
  WORKS: 'WORKS',
  PAINTINGS: 'PAINTINGS',
  PRINTS: 'PRINTS',
  SCULPTURES: 'SCULPTURES',
  PHOTOGRAPHY: 'PHOTOGRAPHY',
  LIGHTBOX: 'LIGHTBOX',
  HELP: 'HELP',
  ARTWORK: 'ARTWORK',
  ARTIST: 'ARTIST',
  USER: 'USER',
  CART: 'CART',
  ADMIN: 'ADMIN',
  ARTISTADMIN: 'ARTISTADMIN',
  SIGNIN: 'SIGNIN',
  SIGNUP: 'SIGNUP',
  SEARCH: 'SEARCH',
  CONTACT: 'CONTACT',
  ABOUT: 'ABOUT',
};

const routes = [
  {
    path: '/',
    name: RouteNames.MAIN,
  },
  {
    path: '/artists',
    name: RouteNames.ARTISTS,
  },
  {
    path: '/works',
    name: RouteNames.WORKS,
  },
  {
    path: '/paintings',
    name: RouteNames.PAINTINGS,
    data: { medium: 'painting', title: 'Paintings' },
    useRender: true,
  },
  {
    path: '/prints',
    name: RouteNames.PRINTS,
    data: { medium: 'print', title: 'Prints' },
    useRender: true,
  },
  {
    path: '/sculptures',
    name: RouteNames.SCULPTURES,
    data: { medium: 'sculpture', title: 'Sculptures' },
    useRender: true,
  },
  {
    path: '/photography',
    name: RouteNames.PHOTOGRAPHY,
    data: { medium: 'photograph', title: 'Photography' },
    useRender: true,
  },
  {
    path: '/lightbox',
    name: RouteNames.LIGHTBOX,
    data: { medium: 'lightbox', title: 'Lightbox' },
    useRender: true,
  },
  {
    path: '/help',
    name: RouteNames.HELP,
  },
  {
    path: '/work/:artworkId',
    name: RouteNames.ARTWORK,
  },
  {
    path: '/artist/:artistId',
    name: RouteNames.ARTIST,
  },
  {
    path: '/user',
    name: RouteNames.USER,
  },
  {
    path: '/cart',
    name: RouteNames.CART,
  },
  {
    path: '/admin',
    name: RouteNames.ADMIN,
  },
  {
    path: '/artist-admin',
    name: RouteNames.ARTISTADMIN,
  },
  {
    path: '/signin',
    name: RouteNames.SIGNIN,
  },
  {
    path: '/signup',
    name: RouteNames.SIGNUP,
  },
  {
    path: '/search',
    name: RouteNames.SEARCH,
  },
  {
    path: '/contact',
    name: RouteNames.CONTACT,
  },
  {
    path: '/about',
    name: RouteNames.ABOUT,
  },
];

module.exports = { RouteNames, routes };
