import React, { useContext, useState, useEffect } from 'react';
import { PurchaseContext } from '../../context/PurchaseContext';
import { useStripe } from '@stripe/react-stripe-js';
import { AlertsContext } from '../../context/AlertsContext';
import { Intent, Button } from '@blueprintjs/core';
import { CartItem } from '../../components/common/CartItem';
import { displayPrice } from '../../utils';
import { estimateOrderCosts } from '../../client/printfulClient';
import {
  IEstimateOrderCostsRequest,
  IEstimateOrderCostsResponse,
} from '../../common-src/types/Order';
import {
  shippingTypes,
  infoToVariantIdMap,
  PRINTFUL_PRODUCTS,
} from '../../common-src/constants/printful';
import { Helmet } from 'react-helmet';
import {
  useCartStyles,
  useGenericGridStyles,
  useMainStyles,
} from '../../hooks/styleHooks';
import { IAddress } from '../../common-src/types/BillingAndShippingInfo';

interface IProps {
  isVisible: boolean;
}

const Review: React.FunctionComponent<IProps> = (props: IProps) => {
  const purchaseContext = useContext(PurchaseContext);
  const alertContext = useContext(AlertsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mainStyles = useMainStyles();
  const cartStyles = useCartStyles();
  const genericGridStyles = useGenericGridStyles();

  const {
    clientSecret,
    billingAndShippingInfo,
    shippingRate,
    cartItems,
    transactionId,
    orderCostsEstimate,
    subtotal,
  } = purchaseContext;
  const stripe = useStripe();

  useEffect(() => {
    if (billingAndShippingInfo && transactionId) {
      fetchPrintfulOrderCosts();
    }
  }, [billingAndShippingInfo, transactionId]);

  // TODO: test the below!!!
  const fetchPrintfulOrderCosts = async () => {
    if (billingAndShippingInfo?.shipping) {
      const estimateOrderCostsRequest: IEstimateOrderCostsRequest = {
        external_id: transactionId,
        shipping: shippingTypes.standard,
        recipient: {
          address1: (billingAndShippingInfo?.shipping.address as IAddress)
            .line1,
          address2: (billingAndShippingInfo?.shipping.address as IAddress)
            .line2,
          country_code: (billingAndShippingInfo?.shipping.address as IAddress)
            .country,
          city: (billingAndShippingInfo?.shipping.address as IAddress).city,
          state_code: (billingAndShippingInfo?.shipping.address as IAddress)
            .state,
          zip: (billingAndShippingInfo?.shipping.address as IAddress)
            .postalCode,
        },
        items: cartItems.map(cartItem => ({
          external_id: cartItem.artwork.id,
          variant_id: +cartItem.externalIdentifiers?.variantId!, // TODO: check
          quantity: cartItem.quantity,
          files: [{ url: cartItem.artwork.fullSizeUrl }],
        })),
      };
      try {
        const res = await estimateOrderCosts(estimateOrderCostsRequest);
        purchaseContext.setOrderCostsEstimate(
          (res as IEstimateOrderCostsResponse).result
        );
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handlePayment = async () => {
    if (!stripe || !billingAndShippingInfo || !shippingRate) {
      return;
    }
    setIsSubmitting(true);
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: billingAndShippingInfo.paymentMethod.card,
          billing_details: {
            name: billingAndShippingInfo.paymentMethod.billingDetails.name,
          },
        },
        receipt_email: billingAndShippingInfo.receiptEmail,
        shipping: {
          address: {
            line1: (billingAndShippingInfo.shipping.address as IAddress).line1!,
            line2: (billingAndShippingInfo.shipping.address as IAddress).line2,
            country: (billingAndShippingInfo.shipping.address as IAddress)
              .country,
            city: (billingAndShippingInfo.shipping.address as IAddress).city,
            state: (billingAndShippingInfo.shipping.address as IAddress).state,
            postal_code: (billingAndShippingInfo.shipping.address as IAddress)
              .postalCode,
          },
          name: billingAndShippingInfo.shipping.name!,
        },
      });

      if (result.error) {
        alertContext.addAlert(result.error.message!, Intent.DANGER);
      } else {
        if (result.paymentIntent!.status === 'succeeded') {
          alertContext.addAlert('Payment successful!', Intent.SUCCESS);
          purchaseContext.setStage(3);
          purchaseContext.clearCart();
          window.scrollTo(0, 0);
        }
      }

      setIsSubmitting(false);
    } catch (err) {
      alertContext.addAlert(
        'There was an error processing your payment. Please contact support or try again later.',
        Intent.DANGER
      );
    }
  };

  return (
    <div
      style={{ display: props.isVisible ? 'block' : 'none' }}
      className={mainStyles.regularTextStyle}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Review your order details - The Picture House Gallery</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          textAlign: 'left',
        }}
      >
        <div style={{ padding: '20px 0 20px 0', width: 1300 }}>
          <div>
            <p style={{ marginTop: 30, marginBottom: 30 }}>
              You're almost there. Please confirm your details below and your
              order will be on its way.
            </p>
          </div>
          <div className={genericGridStyles.flexGrid}>
            <div
              className={genericGridStyles.flexGridCol1}
              style={{ paddingRight: 20 }}
            >
              <h3 className={cartStyles.cartHeading}>Your order</h3>
              <div>
                {cartItems.map((cartItem, index) => (
                  <CartItem
                    cartItem={cartItem}
                    key={`${index}-${cartItem.artwork.id}`}
                  />
                ))}
              </div>
            </div>
            <div
              className={genericGridStyles.flexGridCol1}
              style={{
                borderLeftColor: 'lightgrey',
                borderLeftStyle: 'solid',
                borderLeftWidth: 1,
                paddingLeft: 20,
              }}
            >
              <div>
                <h3 className={cartStyles.cartHeading}>
                  Your billing information
                </h3>
                <p>
                  Name:{' '}
                  {billingAndShippingInfo?.paymentMethod.billingDetails.name}
                </p>
                <p>Email address: {billingAndShippingInfo?.receiptEmail}</p>
                {/* TODO_LATER: add last 4 digits of card? */}
              </div>
              <div>
                <h3 className={cartStyles.cartHeading}>
                  Your shipping information
                </h3>
                <p>{`Recipient: ${billingAndShippingInfo?.shipping.name}`}</p>
                <h3 className={cartStyles.cartHeading}>Address</h3>
                <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                  <li>
                    {
                      (billingAndShippingInfo?.shipping?.address as IAddress)
                        ?.line1
                    }
                  </li>
                  <li>
                    {(billingAndShippingInfo?.shipping.address as IAddress)
                      ?.line2
                      ? (billingAndShippingInfo?.shipping.address as IAddress)
                          ?.line2
                      : ''}
                  </li>
                  <li>
                    {
                      (billingAndShippingInfo?.shipping.address as IAddress)
                        ?.city
                    }
                  </li>
                  <li>
                    {
                      (billingAndShippingInfo?.shipping.address as IAddress)
                        ?.state
                    }
                  </li>
                  <li>
                    {
                      (billingAndShippingInfo?.shipping.address as IAddress)
                        ?.country
                    }
                  </li>
                  <li>
                    {
                      (billingAndShippingInfo?.shipping.address as IAddress)
                        ?.postalCode
                    }
                  </li>
                </ul>
              </div>
              {!!purchaseContext.taxRate && (
                <div>{`tax rate is ${purchaseContext.taxRate}`}</div>
              )}
            </div>
          </div>
          <div
            style={{
              borderTopColor: 'lightgrey',
              borderTopStyle: 'solid',
              borderTopWidth: 1,
              marginTop: 20,
              paddingTop: 20,
            }}
          >
            <div>
              <h3 className={cartStyles.cartHeading}>Subtotal</h3>
              <p>{displayPrice(subtotal, 'gbp', 'en-US')}</p>
            </div>
            {!!orderCostsEstimate && (
              <>
                <h3 className={cartStyles.cartHeading}>Tax</h3>
                <p>
                  {displayPrice(
                    +orderCostsEstimate.costs.tax +
                      +orderCostsEstimate.costs.vat,
                    'gbp',
                    'en-US'
                  )}
                </p>
              </>
            )}
            <h3 className={cartStyles.cartHeading}>Delivery</h3>
            {!!shippingRate && (
              <>
                <p>
                  Cost:{' '}
                  {displayPrice(
                    +shippingRate?.rate!,
                    shippingRate?.currency!,
                    'en-US'
                  )}
                </p>
                <p>
                  Estimated delivery time: {shippingRate?.minDeliveryDays} -
                  {shippingRate?.maxDeliveryDays} days
                </p>
              </>
            )}
            <div>
              <h3 className={cartStyles.cartHeading}>Total</h3>
              <p>{displayPrice(purchaseContext.total, 'GBP', 'en-US')}</p>
            </div>
            <div>
              <Button
                disabled={!stripe || isSubmitting}
                loading={isSubmitting}
                intent={Intent.SUCCESS}
                onClick={handlePayment}
              >
                Confirm and pay
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Review };
