import React, { ReactNode, useState } from 'react';

interface UIContextType {
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
}

export const UIContext = React.createContext<UIContextType | null>(null);

interface Props {
  children?: ReactNode;
}

const UIContextProvider: React.FunctionComponent = (props: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    const currentlyOpen = isMobileMenuOpen;
    setIsMobileMenuOpen(!currentlyOpen);
  };

  return (
    <UIContext.Provider
      value={{
        isMobileMenuOpen,
        toggleMobileMenu,
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export { UIContextProvider };
