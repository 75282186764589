import { createUseStyles } from 'react-jss';
import { ITheme } from '../common-src/types/Theme';
import { defaultTheme } from '../style/themes';

const theme: ITheme = defaultTheme;

export const useMainStyles = createUseStyles({
  genericPage: {
    padding: '50px 10px 10px 10px',
    backgroundColor: theme.main.backgroundColour,
    minHeight: '100vh',
  },

  mainPage: {
    padding: 0,
    backgroundColor: theme.main.backgroundColour,
    minHeight: '100vh',
  },

  '@media only screen and (max-width: 700px)': {
    genericPage: {
      padding: '50px 5px 10px 5px',
    },
    mainPage: {
      padding: '50px 5px 10px 5px',
    },
  },

  regularTextStyle: {
    fontFamily: `'B612', 'sans-serif'`,
  },

  pageHeading: {
    fontFamily: `'Spectral', 'serif'`,
    fontSize: '36pt',
    fontWeight: 400,
    marginBottom: 20,
    fontStyle: 'italic',
  },

  leftAligned: {
    textAlign: 'left',
  },

  // currently unused
  smallHeading: {
    fontFamily: `'B612', sans-serif'`,
    fontVariant: 'all-small-caps',
  },

  centreChildren: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const useCartStyles = createUseStyles({
  cartHeading: {
    fontSize: '15pt',
    fontWeight: 600,
    marginBottom: 20,
  },
});

export const useGenericGridStyles = createUseStyles({
  flexGrid: {
    display: 'flex',
  },

  '@media (max-width: 700px)': {
    flexGrid: {
      display: 'block',
    },
  },

  flexGridLarge: {
    display: 'flex',
  },

  '@media (max-width: 1200px)': {
    flexGridLarge: {
      display: 'block',
    },
  },

  flexGridCol1: {
    flex: 1,
  },

  flexGridCol2: {
    flex: 2,
  },

  flexGridCol3: {
    flex: 3,
  },

  flexGridCol4: {
    flex: 4,
  },
});

export const useArtworkGridStyles = createUseStyles({
  artworkGrid: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
  },

  '@media (max-width: 400px)': {
    artworkGrid: {
      display: 'block',
    },
  },
});

export const useNavbarStyles = createUseStyles({
  navLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },

  logo: {
    fontFamily: `'Spectral', 'serif'`,
    fontSize: '26pt',
    color: theme.logo.colour,
  },

  navbarLogoContainer: {
    flex: 2,
    padding: '15px 10px 10px 10px',
  },

  navbarCentreLinksGroup: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding-left: 3%;
    // width: 80%;
  },

  navbarRightLinksGroup: {
    flex: 2,
    padding: '15px 10px 10px 10px',
    display: 'flex',
  },

  navbarItself: {
    display: 'flex',
    height: 80,
    backgroundColor: theme.navbar.backgroundColour,
  },

  navbarFullWidthMenu: {
    width: '80vw',
    display: 'flex',
  },

  navbarMenu: {
    backgroundColor: theme.navbar.backgroundColour,
    display: 'block',
  },

  navbarLink: {
    fontSize: '16pt',
    color: 'white !important',
    textDecoration: 'none !important',
    display: 'block',
  },

  navbarMenuLink: {
    paddingTop: 10,
    paddingBottom: 10,

    '& div': {
      padding: 5,
    },
  },

  navMenuButton: {
    display: 'none',
  },

  navMenuButtonWrapper: {
    display: 'none',
  },

  navbarSearch: {
    display: 'block',
  },

  navbarCart: {
    display: 'block',
  },

  '@media (max-width: 1450px)': {
    logo: {
      fontFamily: `'Spectral', 'serif'`,
      fontSize: '20pt',
      color: theme.logo.colour,
    },
  },

  '@media (max-width: 1200px)': {
    navbarLogoContainer: {
      flex: 4,
    },
  },

  '@media (max-width: 920px)': {
    navbarCentreLinksGroup: {
      display: 'none',
    },
    navbarRightLinksGroup: {
      padding: '15px 10px 10px 10px',
      justifyContent: 'right',
    },
  },

  '@media (max-width: 600px)': {
    logo: {
      paddingTop: 5,
      fontFamily: `'Spectral', serif'`,
      fontSize: '16pt',
      color: theme.logo.colour,
    },
    navbarLogoContainer: {
      flex: 6,
    },
  },

  '@media only screen and (max-width: 480px)': {
    navbarSearch: {
      display: 'none',
    },

    navbarCart: {
      display: 'none',
    },

    navbarMenu: {
      display: 'none',
    },

    navMenuButtonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    navMenuButton: {
      display: 'block',
    },

    navbarLink: {
      display: 'none',
    },
  },
});

export const useImageStripStyles = createUseStyles({
  imageStripWrapper: {
    margin: '0 130px 0 130px',
  },

  imageStrip: {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },

  '@media only screen and (max-width: 1250px)': {
    imageStripWrapper: {
      margin: '0 10px 0 10px',
    },
  },
});

export const useImagePreviewStyles = createUseStyles({
  imagePreviewFrame: {
    borderStyle: 'solid',
    borderColor: 'black',
    borderWidth: 4,
    padding: 10,
  },

  imagePreviewWrapper: {
    padding: '0 5px 0 5px',
    display: 'inline-flex',
  },

  imagePreviewOverlay: {
    position: 'absolute',
    // backgroundColor: 'white',
    backgroundColor: theme.imagePreviewOverlay.backgroundColour,
    color: theme.imagePreviewOverlay.colour,
    padding: 24,
    fontFamily: `'Spectral', 'serif'`,
    fontSize: '26pt',
  },

  imagePreviewOverlayText: {
    fontFamily: `'Spectral', 'serif'`,
    fontSize: '26pt',
  },
});

export const useAdminStyles = createUseStyles({
  'admin-navbar-wrapper': {
    'background-color': theme.adminNavbarBackground,
    paddingTop: 80,
    paddingBottom: 100,
    height: '100%',
    top: 0,
  },

  'admin-navbar': {
    'background-color': theme.adminNavbarBackground,
    height: '100%',
    'overflow-y': 'auto',
  },

  'admin-navbar-item-active': {
    'background-color': theme.adminNavbarItemActiveBackground,
  },

  'admin-form-container': {
    width: '50%',
  },

  '@media (max-width: 700px)': {
    'admin-form-container': {
      width: '100%',
    },
  },

  'admin-form-box': {
    padding: '10px 10px 10px 10px',
  },

  adminFormSection: {
    padding: '30px 500px 10px 10px',
    'margin-bottom': '10px',
  },

  adminSection: {
    padding: '30px 10px 10px 10px',
    'margin-bottom': '10px',
  },
});

export const usePurchaseStyles = createUseStyles({
  purchaseSteps: {
    width: '60%',
  },

  '@media only screen and (max-width: 1230px)': {
    purchaseSteps: {
      width: '100%',
    },
  },
});

export const usePurchaseSidebarStyles = createUseStyles({
  'artwork-sidebar': {
    // background: 'rgb(235, 235, 235)',
    background: theme.purchaseSidebar.background,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  // new
  tabs: {},
  tabList: {
    paddingLeft: 0,
    margin: 0,
  },
  tabListItem: {
    display: 'inline-block',
    listStyle: 'none',
    marginBottom: -1,
    padding: '0.5rem 0.75rem',
    backgroundColor: theme.tabs.tabBackgroundColour,
    cursor: 'pointer',
  },
  tabListActive: {
    backgroundColor: theme.tabs.activeTabBackgroundColour,
    border: 'solid #ccc',
    borderWidth: '1px 1px 0 1px',
    // borderRadius: 3,
  },
  tabContent: {
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 3,
    backgroundColor: theme.tabs.activeTabBackgroundColour,
  },
});

export const useArtworkInfoStyles = createUseStyles({
  artworkTitleWrapper: {
    paddingLeft: 50,
    marginBottom: 10,
    borderBottom: theme.accentColour,
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
  },

  artworkTitle: {
    textAlign: 'left',
  },

  artworkTitleArtistName: {
    fontSize: '14pt',
    fontStyle: 'italic',
    color: theme.artistNameText,
  },

  artworkPrice: {
    fontSize: '24pt',
    marginTop: 10,
    marginBottom: 10,
    color: theme.purchaseSidebar.artworkPriceColour,
  },

  // currently unused
  artworkLink: {
    padding: '10px 0',
  },

  // unused
  artworkInfo: {
    textAlign: 'left',
    marginTop: 30,
    padding: '50px 250px 50px 250px',
  },

  artworkDescription: {
    whiteSpace: 'pre-wrap',
    fontFamily: "'Spectral', serif",
    fontStyle: 'italic',
    fontSize: '14pt',
    lineHeight: '25pt',
    backgroundColor: theme.description.backgroundColour,
    color: theme.text.lightTextColour,
    padding: 50,
    borderRadius: 4,
    boxShadow: theme.description.boxShadow,
  },

  artworkMore: {
    textAlign: 'left',
    marginTop: 30,
    padding: '30px 100px 30px 100px',
  },
});

export const useArtistLinkStyles = createUseStyles({
  artistLink: {
    fontSize: '16pt',
    color: theme.artistLink.colour,
    '&:hover': {
      textDecoration: 'none',
    },
  },
});

export const useFilterBoxStyles = createUseStyles({
  heading: {
    fontFamily: `'Spectral', 'serif'`,
    fontSize: '20pt',
    fontWeight: 600,
    marginBottom: 20,
    fontVariant: 'all-small-caps',
  },
  filterSection: {
    // border-top-color: lightgrey;
    // border-top-width: 1px;
    // border-top-style: solid;
    // border-bottom-color: lightgrey;
    // border-bottom-width: 1px;
    // border-bottom-style: solid;
    padding: '10px 0 10px 0',
    marginBottom: 7,
  },
});

export const useArtistPageStyles = createUseStyles({
  artistMasthead: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    height: 450,
    margin: '0 0 10px 0',
  },
});

export const useFormStyles = createUseStyles({
  componentWrapper: {
    marginTop: 10,
  },
  labelWrapper: {
    marginBottom: 5,
  },
  warningText: {
    textAlign: 'left',
    fontSize: 12,
    paddingTop: 10,
    color: theme.forms.warningColour,
    fontFamily: `'-apple-system sytem-ui' 'Helvetica Neue'`,
  },
});

export const useTextStyles = createUseStyles({
  info: {
    textAlign: 'left',
    marginTop: 30,
    padding: '50px 250px 50px 250px',
  },
  regularText: {
    fontFamily: '"B612", sans-serif',
  },
  galleryCard: {
    whiteSpace: 'pre-wrap',
    // font-family: 'Spectral', serif,
    fontStyle: 'italic',
    fontSize: 14,
    lineHeight: '30pt',
    backgroundColor: 'white',
    color: theme.text.lightTextColour,
    padding: 50,
    borderRadius: 4,
    boxShadow: theme.description.boxShadow,
  },
  galleryCardWrapper: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 200,
    marginRight: 200,
  },
  '@media (max-width: 1000px)': {
    galleryCardWrapper: {
      marginTop: 30,
      marginBottom: 30,
      marginLeft: 20,
      marginRight: 20,
    },
  },
});

export const useMasonryGridStyles = createUseStyles({
  masonryGrid: {
    // display: '-webkit-box', /* Not needed if autoprefixing */
    // display: '-ms-flexbox', /* Not needed if autoprefixing */
    display: 'flex',
    marginLeft: -30 /* gutter size offset */,
    width: 'auto',
  },
  masonryGrid_column: {
    paddingLeft: 30 /* gutter size */,
    backgroundClip: 'padding-box',

    '&>div': {
      /* change div to reference your elements you put in <Masonry> */
      // background: white,
      marginBottom: 30,
    },
  },
});
